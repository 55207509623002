import { createSlice } from "@reduxjs/toolkit";
import {
  changePasswordAction,
  createUserAction,
  deleteUserAction,
  getUsersAction,
  resetPasswordAction,
  updateUserAction,
  updateUserIsActiveStatusAction,
} from "../../actions/UserAction/usersAction";
import { message } from "antd";
import { animateCss } from "../../../pages/TosterAlert";

const userSliceIntial = {
  list: [],
  userId: [],
  listLoading: false,
  createUser: {
    loading: false,
    error: "",
  },
};

const usersSlice = createSlice({
  name: "users",
  initialState: userSliceIntial,
  reducers: {
    resetCreateUser: (state, { payload }) => {
      state = { ...state, ...userSliceIntial.createUser };
      return state;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(getUsersAction.pending, (state, { payload }) => {
        state.loading = true;
      })
      .addCase(getUsersAction.fulfilled, (state, action) => {
        state.loading = false;
        const value = [];

        action.payload?.forEach(({ userLogID }) => {
          value?.push(userLogID.toLowerCase().trim());
        });
        state.list = action.payload;
        state.userId = value;
        return state;
      })
      .addCase(getUsersAction.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(createUserAction.pending, (state, { payload }) => {
        state.loading = true;
      })
      .addCase(createUserAction.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.status = payload;
      })
      .addCase(createUserAction.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(updateUserAction.pending, (state, { payload }) => {
        state.loading = true;
      })
      .addCase(updateUserAction.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.status = payload;
        return state;
      })
      .addCase(updateUserAction.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(changePasswordAction.pending, (state, { payload }) => {
        state.loading = true;
      })
      .addCase(changePasswordAction.fulfilled, (state, { payload }) => {
        if (payload.data) {
          animateCss("success", "Password Updated Successfully");
          state.loading = false;
          state.status = payload;
          return state;
        } else if (!payload.data) {
          animateCss("error", "Invalid Old password ");
        }
      })
      .addCase(changePasswordAction.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(updateUserIsActiveStatusAction.pending, (state, { payload }) => {
        state.loading = true;
      })
      .addCase(
        updateUserIsActiveStatusAction.fulfilled,
        (state, { payload }) => {
          state.loading = false;
          state.status = payload;
          return state;
        }
      )
      .addCase(updateUserIsActiveStatusAction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(deleteUserAction.pending, (state, action) => {
        state.loading = true;
        return state;
      })
      .addCase(deleteUserAction.fulfilled, (state, { payload }) => {
        if (payload.message) {
          animateCss("warn", "Deleted Successfully");
          state.loading = false;
          state.status = payload;
          return state;
        } else if (!payload?.message) {
          animateCss(
            "error",
            "User did few actions so unable not deleted",
            2500
          );
        }
      })
      .addCase(deleteUserAction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(resetPasswordAction.pending, (state, action) => {
        state.loading = true;
        return state;
      })
      .addCase(resetPasswordAction.fulfilled, (state, action) => {
        return state;
      })
      .addCase(resetPasswordAction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      }),
});

export const { resetCreateUser } = usersSlice.actions;
export default usersSlice.reducer;
