import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Grid,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Stack,
  styled,
  SvgIcon,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { IoMdAttach } from "react-icons/io";
import * as Yup from "yup";
import { IoDocumentAttach } from "react-icons/io5";

import { deepOrange } from "@mui/material/colors";

import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Formik } from "formik";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import DatePicker from "react-datepicker";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  allAgreementDocumentListAction,
  singleCaseDetailsAction,
  updateAppointmentOfArbitratorSec11and12Action,
  updateArbitrationClausAndAgreementAction,
  updateArbitrationEnforcementAndAppealsAction,
  updateArbitrationFeePaymentStatusAction,
  updateArbitrationHoldWithdrawalAndSettlementAction,
  updateArbitrationInterimOrdersReliefsAwardsAction,
  updateArbitrationWitnessEvidenceAndExpertReportAction,
  updateInitiationOfArbitrationAction,
} from "../../../redux/actions/Case/caseAction";
import { useState } from "react";
import { animateCss } from "../../TosterAlert";
import ArbitrationDocumentList from "./ArbitrationDocumentList";
import { closeSnackbar, enqueueSnackbar } from "notistack";

const VisuallyHiddenInput = styled("input")`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  white-space: nowrap;
  width: 1px;
`;
const MAX_COUNT = 50;
const ArbitrationProceedings = () => {
  const [open, setOpen] = useState(false);
  const [editInitiationOfArbitration, setEditInitiationOfArbitration] =
    useState(false);
  const [editfArbitrationProceedings, setEditfArbitrationProceedings] =
    useState(false);
  const [
    editArbitrationInterimOrdersReliefsAwards,
    setEditArbitrationInterimOrdersReliefsAwards,
  ] = useState(false);
  const [
    editArbitrationWitnessEvidenceAndExpertReport,
    setEditArbitrationWitnessEvidenceAndExpertReport,
  ] = useState(false);
  const [
    editArbitrationEnforcementAndAppeals,
    setEditArbitrationEnforcementAndAppeals,
  ] = useState(false);

  const [
    editArbitrationHoldWithdrawalAndSettlement,
    setEditArbitrationHoldWithdrawalAndSettlement,
  ] = useState(false);
  const [editArbitrationFeesAndExpenses, setEditArbitrationFeesAndExpenses] =
    useState(false);
  const [arbitrationClauseAgreement, setArbitrationClauseAgreement] =
    useState(false);
  // const rowData = {};
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [fileLimit, setFileLimit] = useState();

  const handleUploadFiles = (files) => {
    const uploaded = [...uploadedFiles];
    let limitExceeded = false;
    files.some((file) => {
      if (file.type == "application/pdf") {
        if (uploaded.findIndex((f) => f.name === file.name) === -1) {
          uploaded.push(file);
          if (uploaded?.length === MAX_COUNT) setFileLimit(true);
          if (uploaded?.length > MAX_COUNT) {
            alert(`You can only add a maximum of ${MAX_COUNT} files`);
            setFileLimit(false);
            limitExceeded = true;
            return true;
          }
        }
      } else if (file.type !== "application/pdf") {
        animateCss("error", "Please Upload PDF Document Only");
      }
    });
    if (!limitExceeded) setUploadedFiles(uploaded);
  };

  const handleFileEvent = (e) => {
    const chosenFiles = Array.prototype.slice.call(e.target.files);
    handleUploadFiles(chosenFiles);
  };

  const handleDelete = (chipToDelete) => () => {
    setUploadedFiles((chips) =>
      chips.filter((chip) => chip.name !== chipToDelete.name)
    );
  };

  const useStyles = makeStyles((theme) => ({
    textarea: {
      width: "100%",
      padding: theme.spacing(2),
      marginTop: "1%",
      fontSize: "1rem",
      borderRadius: "4px",
      border: "1px solid #ccc",
      resize: "vertical",
      minHeight: "100px",
    },
  }));

  const classes = useStyles();
  const dispatch = useDispatch();

  function trackerFormatDate(date) {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return date.toLocaleDateString("en-US", options);
  }

  const { id } = useParams();
  const params = { caseID: id };
  const user = useSelector((state) => state.login.data);
  const settings = useSelector((state) => state?.ChecklistDocument?.settings);
  const rowData = useSelector((state) => state?.case?.editCaseDetails);

  const defaultCategory = settings
    ?.find((item) => item?.categoryName === "Mode of Proceedings")
    ?.Children?.find(
      (data) => data?.categoryName.toLowerCase() === "physical"
    )?.generalSettingsID;

  const StyledButton = styled(Button)(({ theme }) => ({
    color: "white",
    marginLeft: theme.spacing(1),
  }));

  return (
    <>
      <ArbitrationDocumentList
        open={open}
        setOpen={setOpen}
        checklistDocuments={{}}
      />
      <Card
        variant="outlined"
        className=" mt-3 md-6 xl-6 xxl-6"
        sx={{ maxWidth: "100%" }}
        xs={12}
        sm={12}
        md={6}
      >
        <Typography
          gutterBottom
          variant="h5"
          component="div"
          className="m-0 px-2  text-secondary"
        >
          Case Details / Arbitration Proceedings
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6}>
            {editInitiationOfArbitration ? (
              <Formik
                initialValues={{
                  existenceOfWrittenArbitrationAgreement: !rowData?.caseID
                    ? null
                    : rowData?.ExistenceOfWrittenArbitrationAgreement,
                  seatOfArbitration: !rowData?.caseID
                    ? null
                    : rowData?.SeatOfArbitration,
                  placeOfArbitration: !rowData?.caseID
                    ? null
                    : rowData?.PlaceOfArbitration,
                  subjectMatterInDispute: !rowData?.caseID
                    ? null
                    : rowData?.SubjectMatterInDispute,
                  arbitrationInitiatedBy: !rowData?.caseID
                    ? null
                    : rowData?.arbitrationInitiatedBy?.generalSettingsID,
                  disputeReferred: !rowData?.caseID
                    ? null
                    : rowData?.disputeReferred?.generalSettingsID,
                  refNoticeDate: !rowData?.caseID
                    ? null
                    : rowData?.refNoticeDate,
                  arbitrationProceedingsType: !rowData?.caseID
                    ? null
                    : rowData?.arbitrationProceedingsType?.generalSettingsID,
                  arbitrationGoverningRules: !rowData?.caseID
                    ? null
                    : rowData?.arbitrationGoverningRules?.generalSettingsID,
                  arbitrationConductedAt: !rowData?.caseID
                    ? null
                    : rowData?.ArbitrationConductedAt,
                  modeOfProceedings: !rowData?.caseID
                    ? null
                    : rowData?.modeOfProceedings?.generalSettingsID,
                  pre_ArbitrationConsultationRequested: !rowData?.caseID
                    ? null
                    : rowData?.Pre_ArbitrationConsultationRequested,
                  pre_ArbitrationConsultationOn: !rowData?.caseID
                    ? null
                    : rowData?.Pre_ArbitrationConsultationOn,
                  pre_ArbitrationConsultationNotes: !rowData?.caseID
                    ? null
                    : rowData?.Pre_ArbitrationConsultationNotes,
                  soaDate: !rowData?.caseID ? null : rowData?.soaDate,
                  lrnNoticeDate: !rowData?.caseID
                    ? null
                    : rowData?.lrnNoticeDate,
                  trnDate: !rowData?.caseID ? null : rowData?.trnDate,
                  noticeOfLossOnSaleSentOn: !rowData?.caseID
                    ? null
                    : rowData?.NoticeOfLossOnSaleSentOn,
                  reliefSoughtByClaimant: !rowData?.caseID
                    ? null
                    : rowData?.ReliefSoughtByClaimant,
                  reliefSoughtRespondent: !rowData?.caseID
                    ? null
                    : rowData?.ReliefSoughtRespondent,
                  action: !rowData?.caseID
                    ? null
                    : rowData?.ArbitrationProceedingAction,
                  params,
                  user,
                }}
                validationSchema={Yup.object().shape({
                  action: Yup.string()
                    .nullable()
                    .transform((value) =>
                      typeof value === "string" ? value.trim() : value
                    )
                    .max(499, "Action must be 499 characters or less"),
                })}
                onSubmit={async (values, actions) => {
                  try {
                    dispatch(updateInitiationOfArbitrationAction(values));
                    setEditInitiationOfArbitration(false);
                    setTimeout(() => {
                      dispatch(singleCaseDetailsAction(params));
                    }, 1000);
                  } catch (error) {}
                }}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  touched,
                  values,
                  setFieldValue,
                  resetForm,
                }) => {
                  return (
                    <div className="d-flex  col-12">
                      <form
                        onSubmit={handleSubmit}
                        style={{ boxShadow: "none" }}
                        xs={12}
                        sm={12}
                        md={12}
                      >
                        <Accordion defaultExpanded>
                          <AccordionSummary
                            onClick={(event) => {
                              event.stopPropagation();
                            }}
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                          >
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "end",
                                width: "100%",
                                backgroundColor: "white",
                              }}
                              xs={12}
                              sm={12}
                              md={12}
                            >
                              <Typography
                                gutterBottom
                                variant="h5"
                                component="div"
                                className="m-0 "
                              >
                                Initiation of Arbitration
                              </Typography>

                              <div>
                                <Button
                                  type="button"
                                  color="primary"
                                  onClick={(event) => {
                                    event.stopPropagation();
                                    setEditInitiationOfArbitration(false);
                                  }}
                                >
                                  Cancel
                                </Button>
                                <Button
                                  color="primary"
                                  onClick={async () => {}}
                                  type="submit"
                                >
                                  Submit
                                </Button>
                              </div>
                            </Box>
                          </AccordionSummary>
                          <AccordionDetails>
                            <>
                              <Box
                                xs={12}
                                sm={12}
                                md={12}
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "end",
                                  backgroundColor: "white",
                                  flexWrap: "wrap",
                                  alignItems: "stretch",
                                }}
                              >
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <Box
                                    sx={{
                                      display: "grid",
                                      alignItems: "end",
                                      px: 2,
                                      gridTemplateColumns: {
                                        xs: "1fr",
                                        sm: "1fr",
                                        md: "1fr",
                                      },
                                      width: {
                                        xs: "100%",
                                        sm: "50%",
                                        md: "50%",
                                      },
                                    }}
                                    xs={12}
                                    sm={6}
                                    md={6}
                                  >
                                    <InputLabel
                                      htmlFor="existenceOfWrittenArbitrationAgreement"
                                      sx={{ textWrap: "balance" }}
                                    >
                                      Existence of Written Arbitration Agreement
                                    </InputLabel>

                                    <Select
                                      labelId="demo-multiple-name-label"
                                      id="existenceOfWrittenArbitrationAgreement"
                                      name="existenceOfWrittenArbitrationAgreement"
                                      value={
                                        values?.existenceOfWrittenArbitrationAgreement
                                      }
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                      fullWidth
                                    >
                                      <MenuItem value={"Yes"} defaultValue>
                                        Yes
                                      </MenuItem>
                                      <MenuItem value={"No"} defaultValue>
                                        No
                                      </MenuItem>
                                    </Select>
                                  </Box>

                                  <Box
                                    sx={{
                                      display: "grid",
                                      alignItems: "end",
                                      px: 2,
                                      gridTemplateColumns: {
                                        xs: "1fr",
                                        sm: "1fr",
                                        md: "1fr",
                                      }, // Responsive layout
                                      width: {
                                        xs: "100%",
                                        sm: "50%",
                                        md: "50%",
                                      }, // Adjust width based on screen size
                                    }}
                                    xs={12}
                                    sm={6}
                                    md={6}
                                  >
                                    <Typography
                                      variant="body1"
                                      component="label"
                                      sx={{ textWrap: "balance" }}
                                    >
                                      Seat of Arbitration
                                    </Typography>

                                    <OutlinedInput
                                      id="outlined-adornment-amount"
                                      name="seatOfArbitration"
                                      sx={{
                                        gridTemplateColumns: {
                                          xs: "1fr",
                                          sm: "1fr",
                                        },
                                      }}
                                      value={values?.seatOfArbitration}
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                    />
                                  </Box>

                                  <Box
                                    sx={{
                                      display: "grid",
                                      alignItems: "end",
                                      px: 2,
                                      gridTemplateColumns: {
                                        xs: "1fr",
                                        sm: "1fr",
                                        md: "1fr",
                                      }, // Responsive layout
                                      width: {
                                        xs: "100%",
                                        sm: "50%",
                                        md: "50%",
                                      }, // Adjust width based on screen size
                                    }}
                                    xs={12}
                                    sm={6}
                                    md={6}
                                  >
                                    <Typography
                                      variant="body1"
                                      component="label"
                                      sx={{ textWrap: "balance" }}
                                    >
                                      Place of Arbitration
                                    </Typography>

                                    <OutlinedInput
                                      id="placeOfArbitration"
                                      name="placeOfArbitration"
                                      sx={{
                                        gridTemplateColumns: {
                                          xs: "1fr",
                                          sm: "1fr",
                                        },
                                      }}
                                      value={values?.placeOfArbitration}
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                    />
                                  </Box>

                                  <Box
                                    sx={{
                                      display: "grid",
                                      alignItems: "end",
                                      px: 2,
                                      gridTemplateColumns: {
                                        xs: "1fr",
                                        sm: "1fr",
                                        md: "1fr",
                                      }, // Responsive layout
                                      width: {
                                        xs: "100%",
                                        sm: "50%",
                                        md: "50%",
                                      }, // Adjust width based on screen size
                                    }}
                                    xs={12}
                                    sm={6}
                                    md={6}
                                  >
                                    <Typography
                                      variant="body1"
                                      component="label"
                                      sx={{ textWrap: "balance" }}
                                    >
                                      Subject Matter in Dispute
                                    </Typography>

                                    <OutlinedInput
                                      id="subjectMatterInDispute"
                                      name="subjectMatterInDispute"
                                      sx={{
                                        gridTemplateColumns: {
                                          xs: "1fr",
                                          sm: "1fr",
                                        },
                                      }}
                                      value={values?.subjectMatterInDispute}
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                    />
                                  </Box>

                                  <Box
                                    sx={{
                                      display: "grid",
                                      alignItems: "end",
                                      px: 2,
                                      gridTemplateColumns: {
                                        xs: "1fr",
                                        sm: "1fr",
                                        md: "1fr",
                                      }, // Responsive layout
                                      width: {
                                        xs: "100%",
                                        sm: "50%",
                                        md: "50%",
                                      }, // Adjust width based on screen size
                                    }}
                                    xs={12}
                                    sm={6}
                                    md={6}
                                  >
                                    <InputLabel
                                      htmlFor="existenceOfWrittenArbitrationAgreement"
                                      sx={{ textWrap: "balance" }}
                                    >
                                      Arbitration Initiated By
                                    </InputLabel>

                                    <Select
                                      labelId="demo-multiple-name-label"
                                      id="arbitrationInitiatedBy"
                                      name="arbitrationInitiatedBy"
                                      value={values?.arbitrationInitiatedBy}
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                      fullWidth // Ensures the Select is 100% width
                                    >
                                      {settings
                                        ?.find(
                                          (item) =>
                                            item?.categoryName ==
                                            "Arbitration Initiated By"
                                        )
                                        ?.Children?.map((data, index) => {
                                          return (
                                            <MenuItem
                                              key={index}
                                              value={data?.generalSettingsID}
                                            >
                                              {data?.categoryName}
                                            </MenuItem>
                                          );
                                        })}
                                    </Select>
                                  </Box>

                                  <Box
                                    sx={{
                                      display: "grid",
                                      alignItems: "end",
                                      px: 2,
                                      gridTemplateColumns: {
                                        xs: "1fr",
                                        sm: "1fr",
                                        md: "1fr",
                                      }, // Responsive layout
                                      width: {
                                        xs: "100%",
                                        sm: "50%",
                                        md: "50%",
                                      }, // Adjust width based on screen size
                                    }}
                                    xs={12}
                                    sm={6}
                                    md={6}
                                  >
                                    <InputLabel
                                      htmlFor="disputeReferred"
                                      sx={{ textWrap: "balance" }}
                                    >
                                      Dispute Referred (for Arbitration) To
                                    </InputLabel>

                                    <Select
                                      labelId="demo-multiple-name-label"
                                      id="disputeReferred"
                                      name="disputeReferred"
                                      value={values?.disputeReferred}
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                      fullWidth // Ensures the Select is 100% width
                                    >
                                      {settings
                                        ?.find(
                                          (item) =>
                                            item?.categoryName ==
                                            "Dispute Referred (for Arbitration) To"
                                        )
                                        ?.Children?.map((data, index) => {
                                          return (
                                            <MenuItem
                                              key={index}
                                              value={data?.generalSettingsID}
                                            >
                                              {data?.categoryName}
                                            </MenuItem>
                                          );
                                        })}
                                    </Select>
                                  </Box>

                                  <Box
                                    sx={{
                                      display: "grid",
                                      alignItems: "end",
                                      px: 2,
                                      gridTemplateColumns: {
                                        xs: "1fr",
                                        sm: "1fr",
                                        md: "1fr",
                                      },
                                      width: {
                                        xs: "100%",
                                        sm: "50%",
                                        md: "50%",
                                      },
                                    }}
                                    xs={12}
                                    sm={6}
                                    md={6}
                                  >
                                    <Typography
                                      variant="body1"
                                      component="label"
                                      sx={{
                                        marginRight: 2,
                                        textWrap: "balance",
                                      }}
                                    >
                                      Notice of Dispute Reference to Arbitration
                                      Sent On
                                    </Typography>

                                    <DatePicker
                                      id="refNoticeDate"
                                      selected={values?.refNoticeDate}
                                      onChange={(date) => {
                                        setFieldValue("refNoticeDate", date);
                                      }}
                                      sx={{
                                        width: "100%",
                                        gridTemplateColumns: {
                                          xs: "1fr",
                                          sm: "1fr",
                                        },
                                      }}
                                      onBlur={handleBlur}
                                      dateFormat="dd-MM-yyyy"
                                      placeholderText="DD-MM-YYYY"
                                      className="form-control"
                                    />
                                  </Box>
                                  <Box
                                    sx={{
                                      display: "grid",
                                      alignItems: "end",
                                      px: 2,
                                      gridTemplateColumns: {
                                        xs: "1fr",
                                        sm: "1fr",
                                        md: "1fr",
                                      }, // Responsive layout
                                      width: {
                                        xs: "100%",
                                        sm: "50%",
                                        md: "50%",
                                      }, // Adjust width based on screen size
                                    }}
                                    xs={12}
                                    sm={6}
                                    md={6}
                                  >
                                    <InputLabel
                                      htmlFor="arbitrationProceedingsType"
                                      sx={{ textWrap: "balance" }}
                                    >
                                      Arbitration Proceedings Type
                                    </InputLabel>

                                    <Select
                                      labelId="demo-multiple-name-label"
                                      id="arbitrationProceedingsType"
                                      name="arbitrationProceedingsType"
                                      value={values?.arbitrationProceedingsType}
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                      fullWidth // Ensures the Select is 100% width
                                    >
                                      {settings
                                        ?.find(
                                          (item) =>
                                            item?.categoryName ==
                                            "Arbitration Proceedings Type"
                                        )
                                        ?.Children?.map((data, index) => {
                                          return (
                                            <MenuItem
                                              key={index}
                                              value={data?.generalSettingsID}
                                            >
                                              {data?.categoryName}
                                            </MenuItem>
                                          );
                                        })}
                                    </Select>
                                  </Box>
                                  <Box
                                    sx={{
                                      display: "grid",
                                      alignItems: "end",
                                      px: 2,
                                      gridTemplateColumns: {
                                        xs: "1fr",
                                        sm: "1fr",
                                        md: "1fr",
                                      }, // Responsive layout
                                      width: {
                                        xs: "100%",
                                        sm: "50%",
                                        md: "50%",
                                      }, // Adjust width based on screen size
                                    }}
                                    xs={12}
                                    sm={6}
                                    md={6}
                                  >
                                    <InputLabel
                                      htmlFor="arbitrationGoverningRules"
                                      sx={{ textWrap: "balance" }}
                                    >
                                      Arbitration Governing Rules
                                    </InputLabel>

                                    <Select
                                      labelId="demo-multiple-name-label"
                                      id="arbitrationGoverningRules"
                                      name="arbitrationGoverningRules"
                                      value={values?.arbitrationGoverningRules}
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                      fullWidth // Ensures the Select is 100% width
                                    >
                                      {settings
                                        ?.find(
                                          (item) =>
                                            item?.categoryName ==
                                            "Arbitration Governing Rules"
                                        )
                                        ?.Children?.map((data, index) => {
                                          return (
                                            <MenuItem
                                              key={index}
                                              value={data?.generalSettingsID}
                                            >
                                              {data?.categoryName}
                                            </MenuItem>
                                          );
                                        })}
                                    </Select>
                                  </Box>
                                  <Box
                                    sx={{
                                      display: "grid",
                                      alignItems: "end",
                                      px: 2,
                                      gridTemplateColumns: {
                                        xs: "1fr",
                                        sm: "1fr",
                                        md: "1fr",
                                      },
                                      width: {
                                        xs: "100%",
                                        sm: "50%",
                                        md: "50%",
                                      },
                                    }}
                                    xs={12}
                                    sm={6}
                                    md={6}
                                  >
                                    <InputLabel
                                      htmlFor="arbitrationConductedAt"
                                      sx={{ textWrap: "balance" }}
                                    >
                                      Arbitration Conducted At
                                    </InputLabel>
                                    <OutlinedInput
                                      id="oarbitrationConductedAt"
                                      name="arbitrationConductedAt"
                                      sx={{
                                        gridTemplateColumns: {
                                          xs: "1fr",
                                          sm: "1fr",
                                        },
                                      }}
                                      value={values?.arbitrationConductedAt}
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                    />
                                  </Box>

                                  <Box
                                    sx={{
                                      display: "grid",
                                      alignItems: "end",
                                      px: 2,
                                      gridTemplateColumns: {
                                        xs: "1fr",
                                        sm: "1fr",
                                        md: "1fr",
                                      }, // Responsive layout
                                      width: {
                                        xs: "100%",
                                        sm: "50%",
                                        md: "50%",
                                      }, // Adjust width based on screen size
                                    }}
                                    xs={12}
                                    sm={6}
                                    md={6}
                                  >
                                    <InputLabel
                                      htmlFor="modeOfProceedings"
                                      sx={{ textWrap: "balance" }}
                                    >
                                      Mode of Proceedings
                                    </InputLabel>

                                    <Select
                                      labelId="demo-multiple-name-label"
                                      id="modeOfProceedings"
                                      name="modeOfProceedings"
                                      value={
                                        values?.modeOfProceedings ??
                                        defaultCategory
                                      }
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                      fullWidth // Ensures the Select is 100% width
                                    >
                                      {settings
                                        ?.find(
                                          (item) =>
                                            item?.categoryName ==
                                            "Mode of Proceedings"
                                        )
                                        ?.Children?.map((data, index) => {
                                          return (
                                            <MenuItem
                                              key={index}
                                              value={data?.generalSettingsID}
                                            >
                                              {data?.categoryName}
                                            </MenuItem>
                                          );
                                        })}
                                    </Select>
                                  </Box>

                                  <Box
                                    sx={{
                                      display: "grid",
                                      alignItems: "end",
                                      px: 2,
                                      gridTemplateColumns: {
                                        xs: "1fr",
                                        sm: "1fr",
                                        md: "1fr",
                                      }, // Responsive layout
                                      width: {
                                        xs: "100%",
                                        sm: "50%",
                                        md: "50%",
                                      }, // Adjust width based on screen size
                                    }}
                                    xs={12}
                                    sm={6}
                                    md={6}
                                  >
                                    <InputLabel
                                      htmlFor="pre_ArbitrationConsultationRequested"
                                      sx={{ textWrap: "balance" }}
                                    >
                                      Pre-Arbitration Consultation Requested
                                    </InputLabel>

                                    <Select
                                      labelId="demo-multiple-name-label"
                                      id="pre_ArbitrationConsultationRequested"
                                      name="pre_ArbitrationConsultationRequested"
                                      value={
                                        values?.pre_ArbitrationConsultationRequested
                                      }
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                      fullWidth
                                    >
                                      <MenuItem value={"Yes"} defaultValue>
                                        Yes
                                      </MenuItem>
                                      <MenuItem value={"No"} defaultValue>
                                        No
                                      </MenuItem>
                                    </Select>
                                  </Box>

                                  {values?.pre_ArbitrationConsultationRequested ==
                                    "Yes" && (
                                    <Box
                                      sx={{
                                        display: "grid",
                                        alignItems: "end",
                                        px: 2,
                                        gridTemplateColumns: {
                                          xs: "1fr",
                                          sm: "1fr",
                                          md: "1fr",
                                        },
                                        width: {
                                          xs: "100%",
                                          sm: "50%",
                                          md: "50%",
                                        },
                                      }}
                                      xs={12}
                                      sm={6}
                                      md={6}
                                    >
                                      <Typography
                                        variant="body1"
                                        component="label"
                                        sx={{
                                          marginRight: 2,
                                          textWrap: "balance",
                                        }}
                                      >
                                        Pre-Arbitration Consultation On
                                      </Typography>

                                      <DatePicker
                                        id="pre_ArbitrationConsultationOn"
                                        selected={
                                          values?.pre_ArbitrationConsultationOn
                                        }
                                        onChange={(date) => {
                                          setFieldValue(
                                            "pre_ArbitrationConsultationOn",
                                            date
                                          );
                                        }}
                                        sx={{
                                          width: "100%",
                                          gridTemplateColumns: {
                                            xs: "1fr",
                                            sm: "1fr",
                                          },
                                        }}
                                        onBlur={handleBlur}
                                        dateFormat="dd-MM-yyyy"
                                        placeholderText="DD-MM-YYYY"
                                        className="form-control"
                                      />
                                    </Box>
                                  )}

                                  {values?.pre_ArbitrationConsultationRequested ==
                                    "Yes" && (
                                    <Box
                                      sx={{
                                        display: "grid",
                                        alignItems: "end",
                                        px: 2,
                                        gridTemplateColumns: {
                                          xs: "1fr",
                                          sm: "1fr",
                                          md: "1fr",
                                        },
                                        width: {
                                          xs: "100%",
                                          sm: "50%",
                                          md: "50%",
                                        },
                                      }}
                                      xs={12}
                                      sm={6}
                                      md={6}
                                    >
                                      <InputLabel
                                        htmlFor="pre_ArbitrationConsultationNotes"
                                        sx={{ textWrap: "balance" }}
                                      >
                                        Pre-Arbitration Consultation Notes
                                      </InputLabel>
                                      <OutlinedInput
                                        id="pre_ArbitrationConsultationNotes"
                                        name="pre_ArbitrationConsultationNotes"
                                        sx={{
                                          gridTemplateColumns: {
                                            xs: "1fr",
                                            sm: "1fr",
                                          },
                                          textWrap: "balance",
                                        }}
                                        value={
                                          values?.pre_ArbitrationConsultationNotes
                                        }
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                      />
                                    </Box>
                                  )}

                                  <Box
                                    sx={{
                                      display: "grid",
                                      alignItems: "end",
                                      px: 2,
                                      gridTemplateColumns: {
                                        xs: "1fr",
                                        sm: "1fr",
                                        md: "1fr",
                                      },
                                      width: {
                                        xs: "100%",
                                        sm: "50%",
                                        md: "50%",
                                      },
                                    }}
                                    xs={12}
                                    sm={6}
                                    md={6}
                                  >
                                    <Typography
                                      variant="body1"
                                      component="label"
                                      sx={{
                                        marginRight: 2,
                                        textWrap: "balance",
                                      }}
                                    >
                                      Statement of Account Generated On
                                    </Typography>

                                    <DatePicker
                                      id="soaDate"
                                      selected={values?.soaDate}
                                      onChange={(date) => {
                                        setFieldValue("soaDate", date);
                                      }}
                                      sx={{
                                        width: "100%",
                                        gridTemplateColumns: {
                                          xs: "1fr",
                                          sm: "1fr",
                                        },
                                      }}
                                      onBlur={handleBlur}
                                      dateFormat="dd-MM-yyyy"
                                      placeholderText="DD-MM-YYYY"
                                      className="form-control"
                                    />
                                  </Box>

                                  <Box
                                    sx={{
                                      display: "grid",
                                      alignItems: "end",
                                      px: 2,
                                      gridTemplateColumns: {
                                        xs: "1fr",
                                        sm: "1fr",
                                        md: "1fr",
                                      },
                                      width: {
                                        xs: "100%",
                                        sm: "50%",
                                        md: "50%",
                                      },
                                    }}
                                    xs={12}
                                    sm={6}
                                    md={6}
                                  >
                                    <Typography
                                      variant="body1"
                                      component="label"
                                      sx={{
                                        marginRight: 2,
                                        textWrap: "balance",
                                      }}
                                    >
                                      Loan Recall Notice (LRN) Sent On
                                    </Typography>

                                    <DatePicker
                                      id="lrnNoticeDate"
                                      selected={values?.lrnNoticeDate}
                                      onChange={(date) => {
                                        setFieldValue("lrnNoticeDate", date);
                                      }}
                                      sx={{
                                        width: "100%",
                                        gridTemplateColumns: {
                                          xs: "1fr",
                                          sm: "1fr",
                                        },
                                      }}
                                      onBlur={handleBlur}
                                      dateFormat="dd-MM-yyyy"
                                      placeholderText="DD-MM-YYYY"
                                      className="form-control"
                                    />
                                  </Box>
                                  <Box
                                    sx={{
                                      display: "grid",
                                      alignItems: "end",
                                      px: 2,
                                      gridTemplateColumns: {
                                        xs: "1fr",
                                        sm: "1fr",
                                        md: "1fr",
                                      },
                                      width: {
                                        xs: "100%",
                                        sm: "50%",
                                        md: "50%",
                                      },
                                    }}
                                    xs={12}
                                    sm={6}
                                    md={6}
                                  >
                                    <Typography
                                      variant="body1"
                                      component="label"
                                      sx={{
                                        marginRight: 2,
                                        textWrap: "balance",
                                      }}
                                    >
                                      Notice of Termination of Agreement (TRN)
                                      Sent On
                                    </Typography>

                                    <DatePicker
                                      id="trnDate"
                                      selected={values?.trnDate}
                                      onChange={(date) => {
                                        setFieldValue("trnDate", date);
                                      }}
                                      sx={{
                                        width: "100%",
                                        gridTemplateColumns: {
                                          xs: "1fr",
                                          sm: "1fr",
                                        },
                                      }}
                                      onBlur={handleBlur}
                                      dateFormat="dd-MM-yyyy"
                                      placeholderText="DD-MM-YYYY"
                                      className="form-control"
                                    />
                                  </Box>
                                  <Box
                                    sx={{
                                      display: "grid",
                                      alignItems: "end",
                                      px: 2,
                                      gridTemplateColumns: {
                                        xs: "1fr",
                                        sm: "1fr",
                                        md: "1fr",
                                      },
                                      width: {
                                        xs: "100%",
                                        sm: "50%",
                                        md: "50%",
                                      },
                                    }}
                                    xs={12}
                                    sm={6}
                                    md={6}
                                  >
                                    <InputLabel
                                      htmlFor="noticeOfLossOnSaleSentOn"
                                      sx={{ textWrap: "balance" }}
                                    >
                                      Notice of Loss on Sale (Short Fall) Sent
                                      On
                                    </InputLabel>
                                    <OutlinedInput
                                      id="noticeOfLossOnSaleSentOn"
                                      name="noticeOfLossOnSaleSentOn"
                                      sx={{
                                        gridTemplateColumns: {
                                          xs: "1fr",
                                          sm: "1fr",
                                        },
                                        textWrap: "balance",
                                      }}
                                      value={values?.noticeOfLossOnSaleSentOn}
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                    />
                                  </Box>

                                  <Box
                                    sx={{
                                      display: "grid",
                                      alignItems: "end",
                                      px: 2,
                                      gridTemplateColumns: {
                                        xs: "1fr",
                                        sm: "1fr",
                                        md: "1fr",
                                      },
                                      width: {
                                        xs: "100%",
                                        sm: "50%",
                                        md: "50%",
                                      },
                                    }}
                                    xs={12}
                                    sm={6}
                                    md={6}
                                  >
                                    <InputLabel
                                      htmlFor="reliefSoughtByClaimant"
                                      sx={{ textWrap: "balance" }}
                                    >
                                      Relief Sought by Claimant
                                    </InputLabel>
                                    <OutlinedInput
                                      id="reliefSoughtByClaimant"
                                      name="reliefSoughtByClaimant"
                                      sx={{
                                        gridTemplateColumns: {
                                          xs: "1fr",
                                          sm: "1fr",
                                        },
                                        textWrap: "balance",
                                      }}
                                      value={values?.reliefSoughtByClaimant}
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                    />
                                  </Box>

                                  <Box
                                    sx={{
                                      display: "grid",
                                      alignItems: "end",
                                      px: 2,
                                      gridTemplateColumns: {
                                        xs: "1fr",
                                        sm: "1fr",
                                        md: "1fr",
                                      },
                                      width: {
                                        xs: "100%",
                                        sm: "50%",
                                        md: "50%",
                                      },
                                    }}
                                    xs={12}
                                    sm={6}
                                    md={6}
                                  >
                                    <InputLabel
                                      htmlFor="reliefSoughtRespondent"
                                      sx={{ textWrap: "balance" }}
                                    >
                                      Relief Sought Respondent
                                    </InputLabel>
                                    <OutlinedInput
                                      id="reliefSoughtRespondent"
                                      name="reliefSoughtRespondent"
                                      sx={{
                                        gridTemplateColumns: {
                                          xs: "1fr",
                                          sm: "1fr",
                                        },
                                        textWrap: "balance",
                                      }}
                                      value={values?.reliefSoughtRespondent}
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                    />
                                  </Box>
                                  {values?.existenceOfWrittenArbitrationAgreement ==
                                    "Yes" && (
                                    <Box width="100%">
                                      <Box
                                        sx={{
                                          display: "grid",
                                          alignItems: "center",
                                          px: 2,
                                          gridTemplateColumns: {
                                            xs: "1fr",
                                            sm: "1fr",
                                            md: "1fr",
                                          },
                                          width: {
                                            xs: "100%",
                                            sm: "100%",
                                            md: "100%",
                                          },
                                        }}
                                        xs={12}
                                        sm={12}
                                        md={12}
                                      >
                                        <Box>
                                          <span>Attention</span>
                                        </Box>
                                        <Box>
                                          <TextareaAutosize
                                            className={classes.textarea}
                                            name="action"
                                            aria-label="maximum height"
                                            value={values?.action}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                          />
                                        </Box>
                                        {touched.action && errors.action && (
                                          <Box color="red" mt={1}>
                                            {errors.action}
                                          </Box>
                                        )}
                                      </Box>
                                    </Box>
                                  )}
                                </LocalizationProvider>
                              </Box>
                            </>
                          </AccordionDetails>
                        </Accordion>
                      </form>
                    </div>
                  );
                }}
              </Formik>
            ) : (
              <>
                <Accordion>
                  <AccordionSummary
                    onClick={(event) => {
                      event.stopPropagation();
                    }}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "end",
                        width: "100%",
                        backgroundColor: "white",
                      }}
                    >
                      <Typography
                        gutterBottom
                        variant="h5"
                        component="div"
                        className="m-0 "
                      >
                        Initiation of Arbitration
                      </Typography>

                      <div>
                        <Button
                          type="button"
                          color="primary"
                          onClick={(event) => {
                            event.stopPropagation();
                            if (window.edit) {
                              event.stopPropagation();
                              setEditInitiationOfArbitration(true);
                            } else {
                              animateCss(
                                "info",
                                "You are not authorized to update"
                              );
                            }
                          }}
                        >
                          Edit
                        </Button>
                      </div>
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={0}>
                      <Grid item xs={12} sm={6} md={4}>
                        <List sx={{ bgcolor: "background.paper" }}>
                          <ListItemText
                            primary="Existence of Written Arbitration Agreement"
                            secondary={
                              rowData?.ExistenceOfWrittenArbitrationAgreement
                            }
                          />
                        </List>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <List sx={{ bgcolor: "background.paper" }}>
                          <ListItemText
                            primary="Seat of Arbitration"
                            secondary={rowData?.SeatOfArbitration}
                          />
                        </List>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <List sx={{ bgcolor: "background.paper" }}>
                          <ListItemText
                            primary="Place of Arbitration"
                            secondary={rowData?.PlaceOfArbitration}
                          />
                        </List>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <List sx={{ bgcolor: "background.paper" }}>
                          <ListItemText
                            primary="Subject Matter in Dispute"
                            secondary={rowData?.SubjectMatterInDispute}
                          />
                        </List>
                      </Grid>

                      <Grid item xs={12} sm={6} md={4}>
                        <List sx={{ bgcolor: "background.paper" }}>
                          <ListItemText
                            primary="Arbitration Initiated By"
                            secondary={
                              rowData?.arbitrationInitiatedBy?.categoryName
                            }
                          />
                        </List>
                      </Grid>

                      <Grid item xs={12} sm={6} md={4}>
                        <List sx={{ bgcolor: "background.paper" }}>
                          <ListItemText
                            primary="Dispute Referred (for Arbitration) To"
                            secondary={rowData?.disputeReferred?.categoryName}
                          />
                        </List>
                      </Grid>

                      <Grid item xs={12} sm={6} md={4}>
                        <List sx={{ bgcolor: "background.paper" }}>
                          <ListItemText
                            primary="Notice of Dispute Reference to Arbitration Sent On"
                            secondary={
                              rowData?.refNoticeDate
                                ? trackerFormatDate(
                                    new Date(rowData?.refNoticeDate)
                                  )
                                : "DD-MM-YYYY"
                            }
                          />
                        </List>
                      </Grid>

                      <Grid item xs={12} sm={6} md={4}>
                        <List sx={{ bgcolor: "background.paper" }}>
                          <ListItemText
                            primary="Arbitration Proceedings Type"
                            secondary={
                              rowData?.arbitrationProceedingsType?.categoryName
                            }
                          />
                        </List>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <List sx={{ bgcolor: "background.paper" }}>
                          <ListItemText
                            primary="Arbitration Governing Rules"
                            secondary={
                              rowData?.arbitrationGoverningRules?.categoryName
                            }
                          />
                        </List>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <List sx={{ bgcolor: "background.paper" }}>
                          <ListItemText
                            primary="Arbitration Conducted At (Full Address)"
                            secondary={rowData?.ArbitrationConductedAt}
                          />
                        </List>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <List sx={{ bgcolor: "background.paper" }}>
                          <ListItemText
                            primary="Mode of Proceedings"
                            secondary={rowData?.modeOfProceedings?.categoryName}
                          />
                        </List>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <List sx={{ bgcolor: "background.paper" }}>
                          <ListItemText
                            primary="Pre-Arbitration Consultation Requested"
                            secondary={
                              rowData?.Pre_ArbitrationConsultationRequested
                            }
                          />
                        </List>
                      </Grid>

                      {rowData?.Pre_ArbitrationConsultationRequested ==
                        "Yes" && (
                        <Grid item xs={12} sm={6} md={4}>
                          <List sx={{ bgcolor: "background.paper" }}>
                            <ListItemText
                              primary="Pre-Arbitration Consultation On"
                              secondary={
                                rowData?.Pre_ArbitrationConsultationOn
                                  ? trackerFormatDate(
                                      new Date(
                                        rowData?.Pre_ArbitrationConsultationOn
                                      )
                                    )
                                  : "DD-MM-YYYY"
                              }
                            />
                          </List>
                        </Grid>
                      )}

                      {rowData?.Pre_ArbitrationConsultationRequested ==
                        "Yes" && (
                        <Grid item xs={12} sm={6} md={4}>
                          <List sx={{ bgcolor: "background.paper" }}>
                            <ListItemText
                              primary="Pre-Arbitration Consultation Notes"
                              secondary={
                                rowData?.Pre_ArbitrationConsultationNotes
                              }
                            />
                          </List>
                        </Grid>
                      )}

                      <Grid item xs={12} sm={6} md={4}>
                        <List sx={{ bgcolor: "background.paper" }}>
                          <ListItemText
                            primary="Statement of Account Generated On"
                            secondary={
                              rowData?.soaDate
                                ? trackerFormatDate(new Date(rowData?.soaDate))
                                : "DD-MM-YYYY"
                            }
                          />
                        </List>
                      </Grid>

                      <Grid item xs={12} sm={6} md={4}>
                        <List sx={{ bgcolor: "background.paper" }}>
                          <ListItemText
                            primary="Loan Recall Notice (LRN) Sent On"
                            secondary={
                              rowData?.lrnNoticeDate
                                ? trackerFormatDate(
                                    new Date(rowData?.lrnNoticeDate)
                                  )
                                : "DD-MM-YYYY"
                            }
                          />
                        </List>
                      </Grid>

                      <Grid item xs={12} sm={6} md={4}>
                        <List sx={{ bgcolor: "background.paper" }}>
                          <ListItemText
                            primary="Notice of Termination of Agreement (TRN) Sent On"
                            secondary={
                              rowData?.trnDate
                                ? trackerFormatDate(new Date(rowData?.trnDate))
                                : "DD-MM-YYYY"
                            }
                          />
                        </List>
                      </Grid>

                      <Grid item xs={12} sm={6} md={4}>
                        <List sx={{ bgcolor: "background.paper" }}>
                          <ListItemText
                            primary="Notice of Loss on Sale (Short Fall) Sent On"
                            secondary={rowData?.NoticeOfLossOnSaleSentOn}
                          />
                        </List>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <List sx={{ bgcolor: "background.paper" }}>
                          <ListItemText
                            primary="Relief Sought by Claimant"
                            secondary={rowData?.ReliefSoughtByClaimant}
                          />
                        </List>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <List sx={{ bgcolor: "background.paper" }}>
                          <ListItemText
                            primary="Relief Sought Respondent"
                            secondary={rowData?.ReliefSoughtRespondent}
                          />
                        </List>
                      </Grid>

                      {rowData.ExistenceOfWrittenArbitrationAgreement ==
                        "Yes" && (
                        <Grid item xs={12} sm={12} md={12}>
                          <List sx={{ bgcolor: "background.paper" }}>
                            <ListItemText
                              primary="Attention"
                              secondary={rowData?.ArbitrationProceedingAction}
                            />
                          </List>
                        </Grid>
                      )}
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </>
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            {editfArbitrationProceedings ? (
              <Formik
                initialValues={{
                  nameOfTheArbitrators: !rowData?.caseID
                    ? null
                    : rowData?.NameOfTheArbitrators,
                  arbitratorsAppointedOn: !rowData?.caseID
                    ? null
                    : rowData?.ArbitratorsAppointedOn,
                  arbitratorsQualifications: !rowData?.caseID
                    ? null
                    : rowData?.ArbitratorsQualifications,
                  conflictsOfInterestCheckRunCompleted: !rowData?.caseID
                    ? null
                    : rowData?.ConflictsOfInterestCheckRunCompleted,
                  methodOfConflictsOfInterestCheckRunBy: !rowData?.caseID
                    ? null
                    : rowData?.MethodOfConflictsOfInterestCheckRunBy,
                  resultsOfConflictsOfInterestCheck: !rowData?.caseID
                    ? null
                    : rowData?.ResultsOfConflictsOfInterestCheck,
                  appointingAuthorityName: !rowData?.caseID
                    ? null
                    : rowData?.appointingAuthorityName,
                  appAppointmentOfArbitratorBeforeHighCourtUnderSection11FiledOn:
                    !rowData?.caseID
                      ? null
                      : rowData?.AppAppointmentOfArbitratorBeforeHighCourtUnderSection11FiledOn,
                  arbitralTribunal: !rowData?.caseID
                    ? null
                    : rowData?.arbitralTribunal,
                  numberOfArbitrators: !rowData?.caseID
                    ? null
                    : rowData?.numberOfArbitrators,
                  noticeForArbitratorConsentDate: !rowData?.caseID
                    ? null
                    : rowData?.noticeForArbitratorConsentDate,
                  arbitratorConsentLetterDate: !rowData?.caseID
                    ? null
                    : rowData?.arbitratorConsentLetterDate,
                  noticeOfArbitratorDisclosureDate: !rowData?.caseID
                    ? null
                    : rowData?.noticeOfArbitratorDisclosureDate,
                  noticeOfAppointmentOfArbitratorDate: !rowData?.caseID
                    ? null
                    : rowData?.noticeOfAppointmentOfArbitratorDate,
                  consentOfPartiesToAppointmentOfArbitratorReceived:
                    !rowData?.caseID
                      ? null
                      : rowData?.ConsentOfPartiesToAppointmentOfArbitratorReceived,
                  consentOfPartiesToAppointmentOfArbitratorReceivedOn:
                    !rowData?.caseID
                      ? null
                      : rowData?.ConsentOfPartiesToAppointmentOfArbitratorReceivedOn,
                  appointmentOfArbitratorChallenged: !rowData?.caseID
                    ? null
                    : rowData?.AppointmentOfArbitratorChallenged,
                  appointmentOfArbitratorChallengeFiledOn: !rowData?.caseID
                    ? null
                    : rowData?.AppointmentOfArbitratorChallengeFiledOn,
                  appointmentOfArbitratorChallengeOutcome: !rowData?.caseID
                    ? null
                    : rowData?.AppointmentOfArbitratorChallengeOutcome,
                  appointmentOfArbitratorChallengeOutcomeNotes: !rowData?.caseID
                    ? null
                    : rowData?.AppointmentOfArbitratorChallengeOutcomeNotes,
                  params,
                  user,
                }}
                onSubmit={async (values, actions) => {
                  try {
                    dispatch(
                      updateAppointmentOfArbitratorSec11and12Action(values)
                    );
                    setEditfArbitrationProceedings(false);
                    setTimeout(() => {
                      dispatch(singleCaseDetailsAction(params));
                    }, 1000);
                  } catch (error) {}
                }}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  touched,
                  values,
                  setFieldValue,
                  resetForm,
                }) => {
                  return (
                    <div className="d-flex  col-12">
                      <form onSubmit={handleSubmit} xs={12} sm={12} md={12}>
                        <Accordion defaultExpanded>
                          <AccordionSummary
                            onClick={(event) => {
                              event.stopPropagation();
                            }}
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                          >
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "end",
                                width: "100%",
                                backgroundColor: "white",
                              }}
                              xs={12}
                              sm={12}
                              md={12}
                            >
                              <Typography
                                gutterBottom
                                variant="h5"
                                component="div"
                                className="m-0 "
                              >
                                Appointment of Arbitrator (Section 11 & 12)
                              </Typography>

                              <div>
                                <Button
                                  type="button"
                                  color="primary"
                                  onClick={(event) => {
                                    // event.stopPropagation();
                                    setEditfArbitrationProceedings(false);
                                  }}
                                >
                                  Cancel
                                </Button>
                                <Button
                                  color="primary"
                                  onClick={async () => {}}
                                  type="submit"
                                >
                                  Submit
                                </Button>
                              </div>
                            </Box>
                          </AccordionSummary>
                          <AccordionDetails>
                            <>
                              <Box
                                xs={12}
                                sm={12}
                                md={12}
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "end",
                                  backgroundColor: "white",
                                  flexWrap: "wrap",
                                  alignItems: "stretch",
                                }}
                              >
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <Box
                                    sx={{
                                      display: "grid",
                                      alignItems: "end",
                                      px: 2,
                                      gridTemplateColumns: {
                                        xs: "1fr",
                                        sm: "1fr",
                                        md: "1fr",
                                      }, // Responsive layout
                                      width: {
                                        xs: "100%",
                                        sm: "50%",
                                        md: "50%",
                                      }, // Adjust width based on screen size
                                    }}
                                    xs={12}
                                    sm={6}
                                    md={6}
                                  >
                                    <Typography
                                      variant="body1"
                                      component="label"
                                      sx={{ textWrap: "balance" }}
                                    >
                                      Name of the Arbitrators
                                    </Typography>

                                    <OutlinedInput
                                      id="nameOfTheArbitrators"
                                      name="nameOfTheArbitrators"
                                      sx={{
                                        gridTemplateColumns: {
                                          xs: "1fr",
                                          sm: "1fr",
                                        },
                                      }}
                                      value={values?.nameOfTheArbitrators}
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                    />
                                  </Box>

                                  <Box
                                    sx={{
                                      display: "grid",
                                      alignItems: "end",
                                      px: 2,
                                      gridTemplateColumns: {
                                        xs: "1fr",
                                        sm: "1fr",
                                        md: "1fr",
                                      },
                                      width: {
                                        xs: "100%",
                                        sm: "50%",
                                        md: "50%",
                                      },
                                    }}
                                    xs={12}
                                    sm={6}
                                    md={6}
                                  >
                                    <Typography
                                      variant="body1"
                                      component="label"
                                      sx={{
                                        marginRight: 2,
                                        textWrap: "balance",
                                      }}
                                    >
                                      Arbitrators Appointed On
                                    </Typography>

                                    <DatePicker
                                      id="arbitratorsAppointedOn"
                                      selected={values?.arbitratorsAppointedOn}
                                      onChange={(date) => {
                                        setFieldValue(
                                          "arbitratorsAppointedOn",
                                          date
                                        );
                                      }}
                                      sx={{
                                        width: "100%",
                                        gridTemplateColumns: {
                                          xs: "1fr",
                                          sm: "1fr",
                                        },
                                      }}
                                      onBlur={handleBlur}
                                      dateFormat="dd-MM-yyyy"
                                      placeholderText="DD-MM-YYYY"
                                      className="form-control"
                                    />
                                  </Box>

                                  <Box
                                    sx={{
                                      display: "grid",
                                      alignItems: "end",
                                      px: 2,
                                      gridTemplateColumns: {
                                        xs: "1fr",
                                        sm: "1fr",
                                        md: "1fr",
                                      }, // Responsive layout
                                      width: {
                                        xs: "100%",
                                        sm: "50%",
                                        md: "50%",
                                      }, // Adjust width based on screen size
                                    }}
                                    xs={12}
                                    sm={6}
                                    md={6}
                                  >
                                    <Typography
                                      variant="body1"
                                      component="label"
                                      sx={{ textWrap: "balance" }}
                                    >
                                      Arbitrator's Qualifications
                                    </Typography>

                                    <OutlinedInput
                                      id="arbitratorsQualifications"
                                      name="arbitratorsQualifications"
                                      sx={{
                                        gridTemplateColumns: {
                                          xs: "1fr",
                                          sm: "1fr",
                                        },
                                      }}
                                      value={values?.arbitratorsQualifications}
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                    />
                                  </Box>

                                  <Box
                                    sx={{
                                      display: "grid",
                                      alignItems: "end",
                                      px: 2,
                                      gridTemplateColumns: {
                                        xs: "1fr",
                                        sm: "1fr",
                                        md: "1fr",
                                      },
                                      width: {
                                        xs: "100%",
                                        sm: "50%",
                                        md: "50%",
                                      },
                                    }}
                                    xs={12}
                                    sm={6}
                                    md={6}
                                  >
                                    <InputLabel
                                      htmlFor="caseStatus"
                                      sx={{ textWrap: "balance" }}
                                    >
                                      Conflicts of Interest Check Run Completed
                                    </InputLabel>

                                    <Select
                                      labelId="conflictsOfInterestCheckRunCompleted"
                                      id="conflictsOfInterestCheckRunCompleted"
                                      name="conflictsOfInterestCheckRunCompleted"
                                      value={
                                        values?.conflictsOfInterestCheckRunCompleted
                                      }
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                      fullWidth
                                    >
                                      <MenuItem value={"Yes"} defaultValue>
                                        Yes
                                      </MenuItem>
                                      <MenuItem value={"No"} defaultValue>
                                        No
                                      </MenuItem>
                                    </Select>
                                  </Box>

                                  {values?.conflictsOfInterestCheckRunCompleted ==
                                    "Yes" && (
                                    <Box
                                      sx={{
                                        display: "grid",
                                        alignItems: "end",
                                        px: 2,
                                        gridTemplateColumns: {
                                          xs: "1fr",
                                          sm: "1fr",
                                          md: "1fr",
                                        },
                                        width: {
                                          xs: "100%",
                                          sm: "50%",
                                          md: "50%",
                                        },
                                      }}
                                      xs={12}
                                      sm={6}
                                      md={6}
                                    >
                                      <InputLabel
                                        htmlFor="caseStatus"
                                        sx={{ textWrap: "balance" }}
                                      >
                                        Method of Conflicts of Interest Check
                                        Run by
                                      </InputLabel>

                                      <Select
                                        labelId="methodOfConflictsOfInterestCheckRunBy"
                                        id="methodOfConflictsOfInterestCheckRunBy"
                                        name="methodOfConflictsOfInterestCheckRunBy"
                                        value={
                                          values?.methodOfConflictsOfInterestCheckRunBy
                                        }
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        fullWidth
                                      >
                                        <MenuItem
                                          value={"CaseNect"}
                                          defaultValue
                                        >
                                          CaseNect
                                        </MenuItem>
                                        <MenuItem value={"Manual"} defaultValue>
                                          Manual
                                        </MenuItem>
                                      </Select>
                                    </Box>
                                  )}

                                  {values?.conflictsOfInterestCheckRunCompleted ==
                                    "Yes" && (
                                    <Box
                                      sx={{
                                        display: "grid",
                                        alignItems: "end",
                                        px: 2,
                                        gridTemplateColumns: {
                                          xs: "1fr",
                                          sm: "1fr",
                                          md: "1fr",
                                        },
                                        width: {
                                          xs: "100%",
                                          sm: "50%",
                                          md: "50%",
                                        },
                                      }}
                                      xs={12}
                                      sm={6}
                                      md={6}
                                    >
                                      <InputLabel
                                        htmlFor="caseStatus"
                                        sx={{ textWrap: "balance" }}
                                      >
                                        Results of Conflicts of Interest Check
                                      </InputLabel>

                                      <Select
                                        labelId="resultsOfConflictsOfInterestCheck"
                                        id="resultsOfConflictsOfInterestCheck"
                                        name="resultsOfConflictsOfInterestCheck"
                                        value={
                                          values?.resultsOfConflictsOfInterestCheck
                                        }
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        fullWidth
                                      >
                                        {settings
                                          ?.find(
                                            (item) =>
                                              item?.categoryName ==
                                              "Results of Conflicts of Interest Check"
                                          )
                                          ?.Children?.map((data, index) => {
                                            return (
                                              <MenuItem
                                                key={index}
                                                value={data?.generalSettingsID}
                                              >
                                                {data?.categoryName}
                                              </MenuItem>
                                            );
                                          })}
                                      </Select>
                                    </Box>
                                  )}

                                  <Box
                                    sx={{
                                      display: "grid",
                                      alignItems: "end",
                                      px: 2,
                                      gridTemplateColumns: {
                                        xs: "1fr",
                                        sm: "1fr",
                                        md: "1fr",
                                      }, // Responsive layout
                                      width: {
                                        xs: "100%",
                                        sm: "50%",
                                        md: "50%",
                                      }, // Adjust width based on screen size
                                    }}
                                    xs={12}
                                    sm={6}
                                    md={6}
                                  >
                                    <Typography
                                      variant="body1"
                                      component="label"
                                      sx={{ textWrap: "balance" }}
                                    >
                                      Name of Appointing Authority
                                    </Typography>

                                    <OutlinedInput
                                      id="appointingAuthorityName"
                                      name="appointingAuthorityName"
                                      sx={{
                                        gridTemplateColumns: {
                                          xs: "1fr",
                                          sm: "1fr",
                                        },
                                      }}
                                      value={values?.appointingAuthorityName}
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                    />
                                  </Box>

                                  <Box
                                    sx={{
                                      display: "grid",
                                      alignItems: "end",
                                      px: 2,
                                      gridTemplateColumns: {
                                        xs: "1fr",
                                        sm: "1fr",
                                        md: "1fr",
                                      },
                                      width: {
                                        xs: "100%",
                                        sm: "50%",
                                        md: "50%",
                                      },
                                    }}
                                    xs={12}
                                    sm={6}
                                    md={6}
                                  >
                                    <Typography
                                      variant="body1"
                                      component="label"
                                      sx={{
                                        marginRight: 2,
                                        textWrap: "balance",
                                      }}
                                    >
                                      Application for Appointment of Arbitrator
                                      before High Court under Section 11 Filed
                                      On
                                    </Typography>

                                    <DatePicker
                                      id="appAppointmentOfArbitratorBeforeHighCourtUnderSection11FiledOn"
                                      selected={
                                        values?.appAppointmentOfArbitratorBeforeHighCourtUnderSection11FiledOn
                                      }
                                      onChange={(date) => {
                                        setFieldValue(
                                          "appAppointmentOfArbitratorBeforeHighCourtUnderSection11FiledOn",
                                          date
                                        );
                                      }}
                                      sx={{
                                        width: "100%",
                                        gridTemplateColumns: {
                                          xs: "1fr",
                                          sm: "1fr",
                                        },
                                      }}
                                      onBlur={handleBlur}
                                      dateFormat="dd-MM-yyyy"
                                      placeholderText="DD-MM-YYYY"
                                      className="form-control"
                                    />
                                  </Box>

                                  <Box
                                    sx={{
                                      display: "grid",
                                      alignItems: "end",
                                      px: 2,
                                      gridTemplateColumns: {
                                        xs: "1fr",
                                        sm: "1fr",
                                        md: "1fr",
                                      }, // Responsive layout
                                      width: {
                                        xs: "100%",
                                        sm: "50%",
                                        md: "50%",
                                      }, // Adjust width based on screen size
                                    }}
                                    xs={12}
                                    sm={6}
                                    md={6}
                                  >
                                    <Typography
                                      variant="body1"
                                      component="label"
                                      sx={{ textWrap: "balance" }}
                                    >
                                      Arbitral Tribunal
                                    </Typography>

                                    <OutlinedInput
                                      id="arbitralTribunal"
                                      name="arbitralTribunal"
                                      sx={{
                                        gridTemplateColumns: {
                                          xs: "1fr",
                                          sm: "1fr",
                                        },
                                      }}
                                      value={values?.arbitralTribunal}
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                    />
                                  </Box>
                                  <Box
                                    sx={{
                                      display: "grid",
                                      alignItems: "end",
                                      px: 2,
                                      gridTemplateColumns: {
                                        xs: "1fr",
                                        sm: "1fr",
                                        md: "1fr",
                                      }, // Responsive layout
                                      width: {
                                        xs: "100%",
                                        sm: "50%",
                                        md: "50%",
                                      }, // Adjust width based on screen size
                                    }}
                                    xs={12}
                                    sm={6}
                                    md={6}
                                  >
                                    <Typography
                                      variant="body1"
                                      component="label"
                                      sx={{ textWrap: "balance" }}
                                    >
                                      Number of Arbitrators
                                    </Typography>

                                    <OutlinedInput
                                      id="numberOfArbitrators"
                                      name="numberOfArbitrators"
                                      sx={{
                                        gridTemplateColumns: {
                                          xs: "1fr",
                                          sm: "1fr",
                                        },
                                      }}
                                      value={values?.numberOfArbitrators}
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                    />
                                  </Box>
                                  <Box
                                    sx={{
                                      display: "grid",
                                      alignItems: "end",
                                      px: 2,
                                      gridTemplateColumns: {
                                        xs: "1fr",
                                        sm: "1fr",
                                        md: "1fr",
                                      },
                                      width: {
                                        xs: "100%",
                                        sm: "50%",
                                        md: "50%",
                                      },
                                    }}
                                    xs={12}
                                    sm={6}
                                    md={6}
                                  >
                                    <Typography
                                      variant="body1"
                                      component="label"
                                      sx={{
                                        marginRight: 2,
                                        textWrap: "balance",
                                      }}
                                    >
                                      Letter of Nomination of Arbitrator Sent On
                                    </Typography>

                                    <DatePicker
                                      id="noticeForArbitratorConsentDate"
                                      selected={
                                        values?.noticeForArbitratorConsentDate
                                      }
                                      onChange={(date) => {
                                        setFieldValue(
                                          "noticeForArbitratorConsentDate",
                                          date
                                        );
                                      }}
                                      sx={{
                                        width: "100%",
                                        gridTemplateColumns: {
                                          xs: "1fr",
                                          sm: "1fr",
                                        },
                                      }}
                                      onBlur={handleBlur}
                                      dateFormat="dd-MM-yyyy"
                                      placeholderText="DD-MM-YYYY"
                                      className="form-control"
                                    />
                                  </Box>

                                  <Box
                                    sx={{
                                      display: "grid",
                                      alignItems: "end",
                                      px: 2,
                                      gridTemplateColumns: {
                                        xs: "1fr",
                                        sm: "1fr",
                                        md: "1fr",
                                      },
                                      width: {
                                        xs: "100%",
                                        sm: "50%",
                                        md: "50%",
                                      },
                                    }}
                                    xs={12}
                                    sm={6}
                                    md={6}
                                  >
                                    <Typography
                                      variant="body1"
                                      component="label"
                                      sx={{
                                        marginRight: 2,
                                        textWrap: "balance",
                                      }}
                                    >
                                      Arbitrator provided Consent On
                                    </Typography>

                                    <DatePicker
                                      id="arbitratorConsentLetterDate"
                                      selected={
                                        values?.arbitratorConsentLetterDate
                                      }
                                      onChange={(date) => {
                                        setFieldValue(
                                          "arbitratorConsentLetterDate",
                                          date
                                        );
                                      }}
                                      sx={{
                                        width: "100%",
                                        gridTemplateColumns: {
                                          xs: "1fr",
                                          sm: "1fr",
                                        },
                                      }}
                                      onBlur={handleBlur}
                                      dateFormat="dd-MM-yyyy"
                                      placeholderText="DD-MM-YYYY"
                                      className="form-control"
                                    />
                                  </Box>

                                  <Box
                                    sx={{
                                      display: "grid",
                                      alignItems: "end",
                                      px: 2,
                                      gridTemplateColumns: {
                                        xs: "1fr",
                                        sm: "1fr",
                                        md: "1fr",
                                      },
                                      width: {
                                        xs: "100%",
                                        sm: "50%",
                                        md: "50%",
                                      },
                                    }}
                                    xs={12}
                                    sm={6}
                                    md={6}
                                  >
                                    <Typography
                                      variant="body1"
                                      component="label"
                                      sx={{
                                        marginRight: 2,
                                        textWrap: "balance",
                                      }}
                                    >
                                      Disclosure of Arbitrator Under Section 12
                                      (1) Received On
                                    </Typography>

                                    <DatePicker
                                      id="noticeOfArbitratorDisclosureDate"
                                      selected={
                                        values?.noticeOfArbitratorDisclosureDate
                                      }
                                      onChange={(date) => {
                                        setFieldValue(
                                          "noticeOfArbitratorDisclosureDate",
                                          date
                                        );
                                      }}
                                      sx={{
                                        width: "100%",
                                        gridTemplateColumns: {
                                          xs: "1fr",
                                          sm: "1fr",
                                        },
                                      }}
                                      onBlur={handleBlur}
                                      dateFormat="dd-MM-yyyy"
                                      placeholderText="DD-MM-YYYY"
                                      className="form-control"
                                    />
                                  </Box>

                                  <Box
                                    sx={{
                                      display: "grid",
                                      alignItems: "end",
                                      px: 2,
                                      gridTemplateColumns: {
                                        xs: "1fr",
                                        sm: "1fr",
                                        md: "1fr",
                                      },
                                      width: {
                                        xs: "100%",
                                        sm: "50%",
                                        md: "50%",
                                      },
                                    }}
                                    xs={12}
                                    sm={6}
                                    md={6}
                                  >
                                    <Typography
                                      variant="body1"
                                      component="label"
                                      sx={{
                                        marginRight: 2,
                                        textWrap: "balance",
                                      }}
                                    >
                                      Notice of Appointment of Arbitrators to
                                      Parties Sent On
                                    </Typography>

                                    <DatePicker
                                      id="noticeOfAppointmentOfArbitratorDate"
                                      selected={
                                        values?.noticeOfAppointmentOfArbitratorDate
                                      }
                                      onChange={(date) => {
                                        setFieldValue(
                                          "noticeOfAppointmentOfArbitratorDate",
                                          date
                                        );
                                      }}
                                      sx={{
                                        width: "100%",
                                        gridTemplateColumns: {
                                          xs: "1fr",
                                          sm: "1fr",
                                        },
                                      }}
                                      onBlur={handleBlur}
                                      dateFormat="dd-MM-yyyy"
                                      placeholderText="DD-MM-YYYY"
                                      className="form-control"
                                    />
                                  </Box>

                                  <Box
                                    sx={{
                                      display: "grid",
                                      alignItems: "end",
                                      px: 2,
                                      gridTemplateColumns: {
                                        xs: "1fr",
                                        sm: "1fr",
                                        md: "1fr",
                                      }, // Responsive layout
                                      width: {
                                        xs: "100%",
                                        sm: "50%",
                                        md: "50%",
                                      }, // Adjust width based on screen size
                                    }}
                                    xs={12}
                                    sm={6}
                                    md={6}
                                  >
                                    <InputLabel
                                      htmlFor="consentOfPartiesToAppointmentOfArbitratorReceived"
                                      sx={{ textWrap: "balance" }}
                                    >
                                      Consent of Parties to Appointment of
                                      Arbitrator Received
                                    </InputLabel>

                                    <Select
                                      labelId="consentOfPartiesToAppointmentOfArbitratorReceived"
                                      id="consentOfPartiesToAppointmentOfArbitratorReceived"
                                      name="consentOfPartiesToAppointmentOfArbitratorReceived"
                                      value={
                                        values?.consentOfPartiesToAppointmentOfArbitratorReceived
                                      }
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                      fullWidth // Ensures the Select is 100% width
                                    >
                                      <MenuItem value={"Yes"} defaultValue>
                                        Yes
                                      </MenuItem>
                                      <MenuItem value={"No"} defaultValue>
                                        No
                                      </MenuItem>
                                    </Select>
                                  </Box>

                                  {values?.consentOfPartiesToAppointmentOfArbitratorReceived ==
                                    "Yes" && (
                                    <Box
                                      sx={{
                                        display: "grid",
                                        alignItems: "end",
                                        px: 2,
                                        gridTemplateColumns: {
                                          xs: "1fr",
                                          sm: "1fr",
                                          md: "1fr",
                                        }, // Responsive layout
                                        width: {
                                          xs: "100%",
                                          sm: "50%",
                                          md: "50%",
                                        }, // Adjust width based on screen size
                                      }}
                                      xs={12}
                                      sm={6}
                                      md={6}
                                    >
                                      <Typography
                                        variant="body1"
                                        component="label"
                                        sx={{ textWrap: "balance" }}
                                      >
                                        Consent of Parties to Appointment of
                                        Arbitrator Received On
                                      </Typography>

                                      <OutlinedInput
                                        id="consentOfPartiesToAppointmentOfArbitratorReceivedOn"
                                        name="consentOfPartiesToAppointmentOfArbitratorReceivedOn"
                                        sx={{
                                          gridTemplateColumns: {
                                            xs: "1fr",
                                            sm: "1fr",
                                          },
                                        }}
                                        value={
                                          values?.consentOfPartiesToAppointmentOfArbitratorReceivedOn
                                        }
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                      />
                                    </Box>
                                  )}

                                  <Box
                                    sx={{
                                      display: "grid",
                                      alignItems: "end",
                                      px: 2,
                                      gridTemplateColumns: {
                                        xs: "1fr",
                                        sm: "1fr",
                                        md: "1fr",
                                      }, // Responsive layout
                                      width: {
                                        xs: "100%",
                                        sm: "50%",
                                        md: "50%",
                                      }, // Adjust width based on screen size
                                    }}
                                    xs={12}
                                    sm={6}
                                    md={6}
                                  >
                                    <InputLabel
                                      htmlFor="appointmentOfArbitratorChallenged"
                                      sx={{ textWrap: "balance" }}
                                    >
                                      Appointment of Arbitrator Challenged
                                    </InputLabel>

                                    <Select
                                      labelId="appointmentOfArbitratorChallenged"
                                      id="appointmentOfArbitratorChallenged"
                                      name="appointmentOfArbitratorChallenged"
                                      value={
                                        values?.appointmentOfArbitratorChallenged
                                      }
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                      fullWidth // Ensures the Select is 100% width
                                    >
                                      <MenuItem value={"Yes"} defaultValue>
                                        Yes
                                      </MenuItem>
                                      <MenuItem value={"No"} defaultValue>
                                        No
                                      </MenuItem>
                                    </Select>
                                  </Box>

                                  {values?.appointmentOfArbitratorChallenged ==
                                    "Yes" && (
                                    <Box
                                      sx={{
                                        display: "grid",
                                        alignItems: "end",
                                        px: 2,
                                        gridTemplateColumns: {
                                          xs: "1fr",
                                          sm: "1fr",
                                          md: "1fr",
                                        },
                                        width: {
                                          xs: "100%",
                                          sm: "50%",
                                          md: "50%",
                                        },
                                      }}
                                      xs={12}
                                      sm={6}
                                      md={6}
                                    >
                                      <Typography
                                        variant="body1"
                                        component="label"
                                        sx={{
                                          marginRight: 2,
                                          textWrap: "balance",
                                        }}
                                      >
                                        Appointment of Arbitrator Challenge
                                        Filed On
                                      </Typography>

                                      <DatePicker
                                        id="appointmentOfArbitratorChallengeFiledOn"
                                        selected={
                                          values?.appointmentOfArbitratorChallengeFiledOn
                                        }
                                        onChange={(date) => {
                                          setFieldValue(
                                            "appointmentOfArbitratorChallengeFiledOn",
                                            date
                                          );
                                        }}
                                        sx={{
                                          width: "100%",
                                          gridTemplateColumns: {
                                            xs: "1fr",
                                            sm: "1fr",
                                          },
                                        }}
                                        onBlur={handleBlur}
                                        dateFormat="dd-MM-yyyy"
                                        placeholderText="DD-MM-YYYY"
                                        className="form-control"
                                      />
                                    </Box>
                                  )}
                                  {values?.appointmentOfArbitratorChallenged ==
                                    "Yes" && (
                                    <Box
                                      sx={{
                                        display: "grid",
                                        alignItems: "end",
                                        px: 2,
                                        gridTemplateColumns: {
                                          xs: "1fr",
                                          sm: "1fr",
                                          md: "1fr",
                                        }, // Responsive layout
                                        width: {
                                          xs: "100%",
                                          sm: "50%",
                                          md: "50%",
                                        }, // Adjust width based on screen size
                                      }}
                                      xs={12}
                                      sm={6}
                                      md={6}
                                    >
                                      <InputLabel
                                        htmlFor="appointmentOfArbitratorChallengeOutcome"
                                        sx={{ textWrap: "balance" }}
                                      >
                                        Appointment of Arbitrator Challenge
                                        Outcome
                                      </InputLabel>

                                      <Select
                                        labelId="appointmentOfArbitratorChallengeOutcome"
                                        id="appointmentOfArbitratorChallengeOutcome"
                                        name="appointmentOfArbitratorChallengeOutcome"
                                        value={
                                          values?.appointmentOfArbitratorChallengeOutcome
                                        }
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        fullWidth // Ensures the Select is 100% width
                                      >
                                        {settings
                                          ?.find(
                                            (item) =>
                                              item?.categoryName ==
                                              "Status of Application"
                                          )
                                          ?.Children?.map((data, index) => {
                                            return (
                                              <MenuItem
                                                key={index}
                                                value={data?.generalSettingsID}
                                              >
                                                {data?.categoryName}
                                              </MenuItem>
                                            );
                                          })}
                                      </Select>
                                    </Box>
                                  )}

                                  {values?.appointmentOfArbitratorChallenged ==
                                    "Yes" && (
                                    <Box
                                      sx={{
                                        display: "grid",
                                        alignItems: "end",
                                        px: 2,
                                        gridTemplateColumns: {
                                          xs: "1fr",
                                          sm: "1fr",
                                          md: "1fr",
                                        }, // Responsive layout
                                        width: {
                                          xs: "100%",
                                          sm: "50%",
                                          md: "50%",
                                        }, // Adjust width based on screen size
                                      }}
                                      xs={12}
                                      sm={6}
                                      md={6}
                                    >
                                      <Typography
                                        variant="body1"
                                        component="label"
                                        sx={{ textWrap: "balance" }}
                                      >
                                        Appointment of Arbitrator Challenge
                                        Outcome Notes
                                      </Typography>

                                      <OutlinedInput
                                        id="appointmentOfArbitratorChallengeOutcomeNotes"
                                        name="appointmentOfArbitratorChallengeOutcomeNotes"
                                        sx={{
                                          gridTemplateColumns: {
                                            xs: "1fr",
                                            sm: "1fr",
                                          },
                                        }}
                                        value={
                                          values?.appointmentOfArbitratorChallengeOutcomeNotes
                                        }
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                      />
                                    </Box>
                                  )}
                                </LocalizationProvider>
                              </Box>
                            </>
                          </AccordionDetails>
                        </Accordion>
                      </form>
                    </div>
                  );
                }}
              </Formik>
            ) : (
              <>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "end",
                        width: "100%",
                        backgroundColor: "white",
                      }}
                    >
                      <Typography
                        gutterBottom
                        variant="h5"
                        component="div"
                        className="m-0 "
                      >
                        Appointment of Arbitrator (Section 11 & 12)
                      </Typography>

                      <div>
                        <Button
                          type="button"
                          color="primary"
                          onClick={(event) => {
                            event.stopPropagation();
                            if (window.edit) {
                              event.stopPropagation();
                              // resetForm();
                              setEditfArbitrationProceedings(true);
                            } else {
                              animateCss(
                                "info",
                                "You are not authorized to update"
                              );
                            }
                          }}
                        >
                          Edit
                        </Button>
                      </div>
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={0}>
                      <Grid item xs={12} sm={6} md={4}>
                        <List sx={{ bgcolor: "background.paper" }}>
                          <ListItemText
                            primary="Name of the Arbitrators"
                            secondary={rowData?.NameOfTheArbitrators}
                          />
                        </List>
                      </Grid>

                      <Grid item xs={12} sm={6} md={4}>
                        <List sx={{ bgcolor: "background.paper" }}>
                          <ListItemText
                            primary="Arbitrators Appointed On"
                            secondary={
                              rowData?.ArbitratorsAppointedOn
                                ? trackerFormatDate(
                                    new Date(rowData?.ArbitratorsAppointedOn)
                                  )
                                : "DD-MM-YYYY"
                            }
                          />
                        </List>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <List sx={{ bgcolor: "background.paper" }}>
                          <ListItemText
                            primary="Arbitrator's Qualifications"
                            secondary={rowData?.ArbitratorsQualifications}
                          />
                        </List>
                      </Grid>

                      <Grid item xs={12} sm={6} md={4}>
                        <List sx={{ bgcolor: "background.paper" }}>
                          <ListItemText
                            primary="Conflicts of Interest Check Run Completed"
                            secondary={
                              rowData?.ConflictsOfInterestCheckRunCompleted
                            }
                          />
                        </List>
                      </Grid>

                      {rowData?.ConflictsOfInterestCheckRunCompleted ==
                        "Yes" && (
                        <Grid item xs={12} sm={6} md={4}>
                          <List sx={{ bgcolor: "background.paper" }}>
                            <ListItemText
                              primary="Method of Conflicts of Interest Check Run by"
                              secondary={
                                rowData?.MethodOfConflictsOfInterestCheckRunBy
                              }
                            />
                          </List>
                        </Grid>
                      )}

                      {rowData?.ConflictsOfInterestCheckRunCompleted ==
                        "Yes" && (
                        <Grid item xs={12} sm={6} md={4}>
                          <List sx={{ bgcolor: "background.paper" }}>
                            <ListItemText
                              primary="Results of Conflicts of Interest Check"
                              secondary={
                                rowData?.resultsOfConflictsOfInterestCheck
                                  ?.categoryName
                              }
                            />
                          </List>
                        </Grid>
                      )}
                      <Grid item xs={12} sm={6} md={4}>
                        <List sx={{ bgcolor: "background.paper" }}>
                          <ListItemText
                            primary="Name of Appointing Authority"
                            secondary={rowData?.appointingAuthorityName}
                          />
                        </List>
                      </Grid>

                      <Grid item xs={12} sm={6} md={4}>
                        <List sx={{ bgcolor: "background.paper" }}>
                          <ListItemText
                            primary="Application for Appointment of Arbitrator before High Court under Section 11 Filed On"
                            secondary={
                              rowData?.AppAppointmentOfArbitratorBeforeHighCourtUnderSection11FiledOn
                                ? trackerFormatDate(
                                    new Date(
                                      rowData?.AppAppointmentOfArbitratorBeforeHighCourtUnderSection11FiledOn
                                    )
                                  )
                                : "DD-MM-YYYY"
                            }
                          />
                        </List>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <List sx={{ bgcolor: "background.paper" }}>
                          <ListItemText
                            primary="Arbitral Tribunal"
                            secondary={rowData?.arbitralTribunal}
                          />
                        </List>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <List sx={{ bgcolor: "background.paper" }}>
                          <ListItemText
                            primary="Number of Arbitrators"
                            secondary={rowData?.numberOfArbitrators}
                          />
                        </List>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <List sx={{ bgcolor: "background.paper" }}>
                          <ListItemText
                            primary="Letter of Nomination of Arbitrator Sent On"
                            secondary={
                              rowData?.noticeForArbitratorConsentDate
                                ? trackerFormatDate(
                                    new Date(
                                      rowData?.noticeForArbitratorConsentDate
                                    )
                                  )
                                : "DD-MM-YYYY"
                            }
                          />
                        </List>
                      </Grid>

                      <Grid item xs={12} sm={6} md={4}>
                        <List sx={{ bgcolor: "background.paper" }}>
                          <ListItemText
                            primary="Arbitrator provided Consent On"
                            secondary={
                              rowData?.arbitratorConsentLetterDate
                                ? trackerFormatDate(
                                    new Date(
                                      rowData?.arbitratorConsentLetterDate
                                    )
                                  )
                                : "DD-MM-YYYY"
                            }
                          />
                        </List>
                      </Grid>

                      <Grid item xs={12} sm={6} md={4}>
                        <List sx={{ bgcolor: "background.paper" }}>
                          <ListItemText
                            primary="Disclosure of Arbitrator Under Section 12 (1) Received On"
                            secondary={
                              rowData?.noticeOfArbitratorDisclosureDate
                                ? trackerFormatDate(
                                    new Date(
                                      rowData?.noticeOfArbitratorDisclosureDate
                                    )
                                  )
                                : "DD-MM-YYYY"
                            }
                          />
                        </List>
                      </Grid>

                      <Grid item xs={12} sm={6} md={4}>
                        <List sx={{ bgcolor: "background.paper" }}>
                          <ListItemText
                            primary="Notice of Appointment of Arbitrators to Parties Sent On"
                            secondary={
                              rowData?.noticeOfAppointmentOfArbitratorDate
                                ? trackerFormatDate(
                                    new Date(
                                      rowData?.noticeOfAppointmentOfArbitratorDate
                                    )
                                  )
                                : "DD-MM-YYYY"
                            }
                          />
                        </List>
                      </Grid>

                      <Grid item xs={12} sm={6} md={4}>
                        <List sx={{ bgcolor: "background.paper" }}>
                          <ListItemText
                            primary="Consent of Parties to Appointment of Arbitrator Received"
                            secondary={
                              rowData?.ConsentOfPartiesToAppointmentOfArbitratorReceived
                            }
                          />
                        </List>
                      </Grid>
                      {rowData?.ConsentOfPartiesToAppointmentOfArbitratorReceived ==
                        "Yes" && (
                        <Grid item xs={12} sm={6} md={4}>
                          <List sx={{ bgcolor: "background.paper" }}>
                            <ListItemText
                              primary="Consent of Parties to Appointment of Arbitrator Received On"
                              secondary={
                                rowData?.ConsentOfPartiesToAppointmentOfArbitratorReceivedOn
                              }
                            />
                          </List>
                        </Grid>
                      )}

                      <Grid item xs={12} sm={6} md={4}>
                        <List sx={{ bgcolor: "background.paper" }}>
                          <ListItemText
                            primary="Appointment of Arbitrator Challenged"
                            secondary={
                              rowData?.AppointmentOfArbitratorChallenged
                            }
                          />
                        </List>
                      </Grid>

                      {rowData?.AppointmentOfArbitratorChallenged == "Yes" && (
                        <Grid item xs={12} sm={6} md={4}>
                          <List sx={{ bgcolor: "background.paper" }}>
                            <ListItemText
                              primary="Appointment of Arbitrator Challenge Filed On"
                              secondary={
                                rowData?.AppointmentOfArbitratorChallengeFiledOn
                                  ? trackerFormatDate(
                                      new Date(
                                        rowData?.AppointmentOfArbitratorChallengeFiledOn
                                      )
                                    )
                                  : "DD-MM-YYYY"
                              }
                            />
                          </List>
                        </Grid>
                      )}

                      {rowData?.AppointmentOfArbitratorChallenged == "Yes" && (
                        <Grid item xs={12} sm={6} md={4}>
                          <List sx={{ bgcolor: "background.paper" }}>
                            <ListItemText
                              primary="Appointment of Arbitrator Challenge Outcome"
                              secondary={
                                rowData?.appointmentOfArbitratorChallengeOutcome
                                  ?.categoryName
                              }
                            />
                          </List>
                        </Grid>
                      )}
                      {rowData?.AppointmentOfArbitratorChallenged == "Yes" && (
                        <Grid item xs={12} sm={6} md={4}>
                          <List sx={{ bgcolor: "background.paper" }}>
                            <ListItemText
                              primary=" Appointment of Arbitrator Challenge Outcome Notes"
                              secondary={
                                rowData?.AppointmentOfArbitratorChallengeOutcomeNotes
                              }
                            />
                          </List>
                        </Grid>
                      )}
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </>
            )}
          </Grid>

          <Grid item xs={12} sm={12} md={6}>
            {editArbitrationInterimOrdersReliefsAwards ? (
              <Formik
                initialValues={{
                  appForInterimRelief_orderByArbitratorUnderSection17Filed:
                    !rowData?.caseID
                      ? null
                      : rowData.AppForInterimRelief_orderByArbitratorUnderSection17Filed,
                  iaApplicationDate: !rowData?.caseID
                    ? null
                    : rowData.iaApplicationDate,
                  iaOrderIssued: !rowData?.caseID
                    ? null
                    : rowData.iaOrderIssued,
                  iaOrderDate: !rowData?.caseID ? null : rowData.iaOrderDate,
                  interimRelief_OrderByArbitratorUnderSection17Notes:
                    !rowData?.caseID
                      ? null
                      : rowData.InterimRelief_OrderByArbitratorUnderSection17Notes,
                  appForInterimRelief_OrderBeforeHighCourtUnderSection9Filed:
                    !rowData?.caseID
                      ? null
                      : rowData.AppForInterimRelief_OrderBeforeHighCourtUnderSection9Filed,
                  appForInterimRelief_OrderBeforeHighCourtUnderSection9FiledOn:
                    !rowData?.caseID
                      ? null
                      : rowData.AppForInterimRelief_OrderBeforeHighCourtUnderSection9FiledOn,
                  statusOfAppForInterimRelief_OrderBeforeHighCourtUnderSection9:
                    !rowData?.caseID
                      ? null
                      : rowData.StatusOfAppForInterimRelief_OrderBeforeHighCourtUnderSection9,
                  interimRelief_OrderByHighCourtUnderSection9PassedOn:
                    !rowData?.caseID
                      ? null
                      : rowData.InterimRelief_OrderByHighCourtUnderSection9PassedOn,
                  interimRelief_OrderByHighCourtUnderSection9Notes:
                    !rowData?.caseID
                      ? null
                      : rowData.InterimRelief_OrderByHighCourtUnderSection9Notes,
                  appForInterimAwardFiled: !rowData?.caseID
                    ? null
                    : rowData.AppForInterimAwardFiled,
                  appForInterimAwardFieldOn: !rowData?.caseID
                    ? null
                    : rowData.AppForInterimAwardFieldOn,
                  statusOfAppForInterimAwardField: !rowData?.caseID
                    ? null
                    : rowData.StatusOfAppForInterimAwardField,
                  interimAwardPassed: !rowData?.caseID
                    ? null
                    : rowData.InterimAwardPassed,
                  interimAwardPassedOn: !rowData?.caseID
                    ? null
                    : rowData.InterimAwardPassedOn,
                  interimAwardNotes: !rowData?.caseID
                    ? null
                    : rowData.InterimAwardNotes,
                  params,
                  user,
                }}
                onSubmit={async (values, actions) => {
                  try {
                    dispatch(
                      updateArbitrationInterimOrdersReliefsAwardsAction(values)
                    );

                    setEditArbitrationInterimOrdersReliefsAwards(false);

                    setTimeout(() => {
                      dispatch(singleCaseDetailsAction(params));
                    }, 1000);
                  } catch (error) {}
                }}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  touched,
                  values,
                  setFieldValue,
                  resetForm,
                }) => {
                  return (
                    <div className="d-flex  col-12">
                      <form
                        onSubmit={handleSubmit}
                        style={{ boxShadow: "none" }}
                        xs={12}
                        sm={12}
                        md={12}
                      >
                        <Accordion defaultExpanded>
                          <AccordionSummary
                            onClick={(event) => {
                              event.stopPropagation();
                            }}
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                          >
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "end",
                                width: "100%",
                                backgroundColor: "white",
                              }}
                              xs={12}
                              sm={12}
                              md={12}
                            >
                              <Typography
                                gutterBottom
                                variant="h5"
                                component="div"
                                className="m-0 "
                              >
                                Arbitration Interim Orders/Reliefs/Awards
                              </Typography>

                              <div>
                                <Button
                                  type="button"
                                  color="primary"
                                  onClick={(event) => {
                                    event.stopPropagation();
                                    setEditArbitrationInterimOrdersReliefsAwards(
                                      false
                                    );
                                  }}
                                >
                                  Cancel
                                </Button>
                                <Button
                                  color="primary"
                                  onClick={async () => {}}
                                  type="submit"
                                >
                                  Submit
                                </Button>
                              </div>
                            </Box>
                          </AccordionSummary>
                          <AccordionDetails>
                            <>
                              <Box
                                xs={12}
                                sm={12}
                                md={12}
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "end",
                                  backgroundColor: "white",
                                  flexWrap: "wrap",
                                  alignItems: "stretch",
                                }}
                              >
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <Box
                                    sx={{
                                      display: "grid",
                                      alignItems: "end",
                                      px: 2,
                                      gridTemplateColumns: {
                                        xs: "1fr",
                                        sm: "1fr",
                                        md: "1fr",
                                      }, // Responsive layout
                                      width: {
                                        xs: "100%",
                                        sm: "50%",
                                        md: "50%",
                                      }, // Adjust width based on screen size
                                    }}
                                    xs={12}
                                    sm={6}
                                    md={6}
                                  >
                                    <InputLabel
                                      htmlFor="appForInterimRelief_orderByArbitratorUnderSection17Filed"
                                      sx={{ textWrap: "balance" }}
                                    >
                                      Application for Interim Relief/Order by
                                      Arbitrator under Section 17 Filed
                                    </InputLabel>

                                    <Select
                                      labelId="appForInterimRelief_orderByArbitratorUnderSection17Filed"
                                      id="appForInterimRelief_orderByArbitratorUnderSection17Filed"
                                      name="appForInterimRelief_orderByArbitratorUnderSection17Filed"
                                      value={
                                        values?.appForInterimRelief_orderByArbitratorUnderSection17Filed
                                      }
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                      fullWidth // Ensures the Select is 100% width
                                    >
                                      <MenuItem value={"Yes"} defaultValue>
                                        Yes
                                      </MenuItem>
                                      <MenuItem value={"No"} defaultValue>
                                        No
                                      </MenuItem>
                                    </Select>
                                  </Box>

                                  {values?.appForInterimRelief_orderByArbitratorUnderSection17Filed ==
                                    "Yes" && (
                                    <Box
                                      sx={{
                                        display: "grid",
                                        alignItems: "end",
                                        px: 2,
                                        gridTemplateColumns: {
                                          xs: "1fr",
                                          sm: "1fr",
                                          md: "1fr",
                                        },
                                        width: {
                                          xs: "100%",
                                          sm: "50%",
                                          md: "50%",
                                        },
                                      }}
                                      xs={12}
                                      sm={6}
                                      md={6}
                                    >
                                      <Typography
                                        variant="body1"
                                        component="label"
                                        sx={{
                                          marginRight: 2,
                                          textWrap: "balance",
                                        }}
                                      >
                                        Application for Interim Relief/Order by
                                        Arbitrator under Section 17 Filed On
                                      </Typography>

                                      <DatePicker
                                        id="iaApplicationDate"
                                        selected={values?.iaApplicationDate}
                                        onChange={(date) => {
                                          setFieldValue(
                                            "iaApplicationDate",
                                            date
                                          );
                                        }}
                                        sx={{
                                          width: "100%",
                                          gridTemplateColumns: {
                                            xs: "1fr",
                                            sm: "1fr",
                                          },
                                        }}
                                        onBlur={handleBlur}
                                        dateFormat="dd-MM-yyyy"
                                        placeholderText="DD-MM-YYYY"
                                        className="form-control"
                                      />
                                    </Box>
                                  )}

                                  {values?.appForInterimRelief_orderByArbitratorUnderSection17Filed ==
                                    "Yes" && (
                                    <Box
                                      sx={{
                                        display: "grid",
                                        alignItems: "end",
                                        px: 2,
                                        gridTemplateColumns: {
                                          xs: "1fr",
                                          sm: "1fr",
                                          md: "1fr",
                                        }, // Responsive layout
                                        width: {
                                          xs: "100%",
                                          sm: "50%",
                                          md: "50%",
                                        }, // Adjust width based on screen size
                                      }}
                                      xs={12}
                                      sm={6}
                                      md={6}
                                    >
                                      <Typography
                                        variant="body1"
                                        component="label"
                                        sx={{ textWrap: "balance" }}
                                      >
                                        Status of Application for Interim
                                        Relief/Order by Arbitrator Section 17
                                      </Typography>

                                      <OutlinedInput
                                        id="iaOrderIssued"
                                        name="iaOrderIssued"
                                        sx={{
                                          gridTemplateColumns: {
                                            xs: "1fr",
                                            sm: "1fr",
                                          },
                                        }}
                                        value={values?.iaOrderIssued}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                      />
                                    </Box>
                                  )}

                                  {values?.appForInterimRelief_orderByArbitratorUnderSection17Filed ==
                                    "Yes" && (
                                    <Box
                                      sx={{
                                        display: "grid",
                                        alignItems: "end",
                                        px: 2,
                                        gridTemplateColumns: {
                                          xs: "1fr",
                                          sm: "1fr",
                                          md: "1fr",
                                        },
                                        width: {
                                          xs: "100%",
                                          sm: "50%",
                                          md: "50%",
                                        },
                                      }}
                                      xs={12}
                                      sm={6}
                                      md={6}
                                    >
                                      <Typography
                                        variant="body1"
                                        component="label"
                                        sx={{
                                          marginRight: 2,
                                          textWrap: "balance",
                                        }}
                                      >
                                        Interim Relief/Order by Arbitrator under
                                        Section 17 Passed On
                                      </Typography>

                                      <DatePicker
                                        id="iaOrderDate"
                                        selected={values?.iaOrderDate}
                                        onChange={(date) => {
                                          setFieldValue("iaOrderDate", date);
                                        }}
                                        sx={{
                                          width: "100%",
                                          gridTemplateColumns: {
                                            xs: "1fr",
                                            sm: "1fr",
                                          },
                                        }}
                                        onBlur={handleBlur}
                                        dateFormat="dd-MM-yyyy"
                                        placeholderText="DD-MM-YYYY"
                                        className="form-control"
                                      />
                                    </Box>
                                  )}

                                  {values?.appForInterimRelief_orderByArbitratorUnderSection17Filed ==
                                    "Yes" && (
                                    <Box
                                      sx={{
                                        display: "grid",
                                        alignItems: "end",
                                        px: 2,
                                        gridTemplateColumns: {
                                          xs: "1fr",
                                          sm: "1fr",
                                          md: "1fr",
                                        }, // Responsive layout
                                        width: {
                                          xs: "100%",
                                          sm: "50%",
                                          md: "50%",
                                        }, // Adjust width based on screen size
                                      }}
                                      xs={12}
                                      sm={6}
                                      md={6}
                                    >
                                      <Typography
                                        variant="body1"
                                        component="label"
                                        sx={{ textWrap: "balance" }}
                                      >
                                        Interim Relief/Order by Arbitrator under
                                        Section 17 Notes
                                      </Typography>

                                      <OutlinedInput
                                        id="interimRelief_OrderByArbitratorUnderSection17Notes"
                                        name="interimRelief_OrderByArbitratorUnderSection17Notes"
                                        sx={{
                                          gridTemplateColumns: {
                                            xs: "1fr",
                                            sm: "1fr",
                                          },
                                        }}
                                        value={
                                          values?.interimRelief_OrderByArbitratorUnderSection17Notes
                                        }
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                      />
                                    </Box>
                                  )}

                                  <Box
                                    sx={{
                                      display: "grid",
                                      alignItems: "end",
                                      px: 2,
                                      gridTemplateColumns: {
                                        xs: "1fr",
                                        sm: "1fr",
                                        md: "1fr",
                                      },
                                      width: {
                                        xs: "100%",
                                        sm: "50%",
                                        md: "50%",
                                      },
                                    }}
                                    xs={12}
                                    sm={6}
                                    md={6}
                                  >
                                    <InputLabel
                                      htmlFor="caseStatus"
                                      sx={{ textWrap: "balance" }}
                                    >
                                      Application for Interim Relief/Order
                                      before High Court under Section 9 Filed
                                    </InputLabel>

                                    <Select
                                      labelId="appForInterimRelief_OrderBeforeHighCourtUnderSection9Filed"
                                      id="appForInterimRelief_OrderBeforeHighCourtUnderSection9Filed"
                                      name="appForInterimRelief_OrderBeforeHighCourtUnderSection9Filed"
                                      value={
                                        values?.appForInterimRelief_OrderBeforeHighCourtUnderSection9Filed
                                      }
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                      fullWidth
                                    >
                                      <MenuItem value={"Yes"} defaultValue>
                                        Yes
                                      </MenuItem>
                                      <MenuItem value={"No"} defaultValue>
                                        No
                                      </MenuItem>
                                    </Select>
                                  </Box>

                                  {values?.appForInterimRelief_OrderBeforeHighCourtUnderSection9Filed ==
                                    "Yes" && (
                                    <Box
                                      sx={{
                                        display: "grid",
                                        alignItems: "end",
                                        px: 2,
                                        gridTemplateColumns: {
                                          xs: "1fr",
                                          sm: "1fr",
                                          md: "1fr",
                                        }, // Responsive layout
                                        width: {
                                          xs: "100%",
                                          sm: "50%",
                                          md: "50%",
                                        }, // Adjust width based on screen size
                                      }}
                                      xs={12}
                                      sm={6}
                                      md={6}
                                    >
                                      <Typography
                                        variant="body1"
                                        component="label"
                                        sx={{ textWrap: "balance" }}
                                      >
                                        Application for Interim Relief/Order
                                        before High Court under Section 9 Filed
                                        On
                                      </Typography>

                                      <OutlinedInput
                                        id="appForInterimRelief_OrderBeforeHighCourtUnderSection9FiledOn"
                                        name="appForInterimRelief_OrderBeforeHighCourtUnderSection9FiledOn"
                                        sx={{
                                          gridTemplateColumns: {
                                            xs: "1fr",
                                            sm: "1fr",
                                          },
                                        }}
                                        value={
                                          values?.appForInterimRelief_OrderBeforeHighCourtUnderSection9FiledOn
                                        }
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                      />
                                    </Box>
                                  )}

                                  {values?.appForInterimRelief_OrderBeforeHighCourtUnderSection9Filed ==
                                    "Yes" && (
                                    <Box
                                      sx={{
                                        display: "grid",
                                        alignItems: "end",
                                        px: 2,
                                        gridTemplateColumns: {
                                          xs: "1fr",
                                          sm: "1fr",
                                          md: "1fr",
                                        }, // Responsive layout
                                        width: {
                                          xs: "100%",
                                          sm: "50%",
                                          md: "50%",
                                        }, // Adjust width based on screen size
                                      }}
                                      xs={12}
                                      sm={6}
                                      md={6}
                                    >
                                      <InputLabel
                                        htmlFor="statusOfAppForInterimRelief_OrderBeforeHighCourtUnderSection9"
                                        sx={{ textWrap: "balance" }}
                                      >
                                        Status of Application for Interim
                                        Relief/Order before High Court under
                                        Section 9
                                      </InputLabel>

                                      <Select
                                        labelId="statusOfAppForInterimRelief_OrderBeforeHighCourtUnderSection9"
                                        id="statusOfAppForInterimRelief_OrderBeforeHighCourtUnderSection9"
                                        name="statusOfAppForInterimRelief_OrderBeforeHighCourtUnderSection9"
                                        value={
                                          values?.statusOfAppForInterimRelief_OrderBeforeHighCourtUnderSection9
                                        }
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        fullWidth // Ensures the Select is 100% width
                                      >
                                        {settings
                                          ?.find(
                                            (item) =>
                                              item?.categoryName ==
                                              "Status of Application"
                                          )
                                          ?.Children?.map((data, index) => {
                                            return (
                                              <MenuItem
                                                key={index}
                                                value={data?.generalSettingsID}
                                              >
                                                {data?.categoryName}
                                              </MenuItem>
                                            );
                                          })}
                                      </Select>
                                    </Box>
                                  )}
                                  {values?.appForInterimRelief_OrderBeforeHighCourtUnderSection9Filed ==
                                    "Yes" && (
                                    <Box
                                      sx={{
                                        display: "grid",
                                        alignItems: "end",
                                        px: 2,
                                        gridTemplateColumns: {
                                          xs: "1fr",
                                          sm: "1fr",
                                          md: "1fr",
                                        }, // Responsive layout
                                        width: {
                                          xs: "100%",
                                          sm: "50%",
                                          md: "50%",
                                        }, // Adjust width based on screen size
                                      }}
                                      xs={12}
                                      sm={6}
                                      md={6}
                                    >
                                      <Typography
                                        variant="body1"
                                        component="label"
                                        sx={{ textWrap: "balance" }}
                                      >
                                        Interim Relief/Order by High Court under
                                        Section 9 Passed On
                                      </Typography>

                                      <OutlinedInput
                                        id="interimRelief_OrderByHighCourtUnderSection9PassedOn"
                                        name="interimRelief_OrderByHighCourtUnderSection9PassedOn"
                                        sx={{
                                          gridTemplateColumns: {
                                            xs: "1fr",
                                            sm: "1fr",
                                          },
                                        }}
                                        value={
                                          values?.interimRelief_OrderByHighCourtUnderSection9PassedOn
                                        }
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                      />
                                    </Box>
                                  )}
                                  {values?.appForInterimRelief_OrderBeforeHighCourtUnderSection9Filed ==
                                    "Yes" && (
                                    <Box
                                      sx={{
                                        display: "grid",
                                        alignItems: "end",
                                        px: 2,
                                        gridTemplateColumns: {
                                          xs: "1fr",
                                          sm: "1fr",
                                          md: "1fr",
                                        }, // Responsive layout
                                        width: {
                                          xs: "100%",
                                          sm: "50%",
                                          md: "50%",
                                        }, // Adjust width based on screen size
                                      }}
                                      xs={12}
                                      sm={6}
                                      md={6}
                                    >
                                      <Typography
                                        variant="body1"
                                        component="label"
                                        sx={{ textWrap: "balance" }}
                                      >
                                        Interim Relief/Order by High Court under
                                        Section 9 Notes
                                      </Typography>

                                      <OutlinedInput
                                        id="interimRelief_OrderByHighCourtUnderSection9Notes"
                                        name="interimRelief_OrderByHighCourtUnderSection9Notes"
                                        sx={{
                                          gridTemplateColumns: {
                                            xs: "1fr",
                                            sm: "1fr",
                                          },
                                        }}
                                        value={
                                          values?.interimRelief_OrderByHighCourtUnderSection9Notes
                                        }
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                      />
                                    </Box>
                                  )}

                                  <Box
                                    sx={{
                                      display: "grid",
                                      alignItems: "end",
                                      px: 2,
                                      gridTemplateColumns: {
                                        xs: "1fr",
                                        sm: "1fr",
                                        md: "1fr",
                                      }, // Responsive layout
                                      width: {
                                        xs: "100%",
                                        sm: "50%",
                                        md: "50%",
                                      }, // Adjust width based on screen size
                                    }}
                                    xs={12}
                                    sm={6}
                                    md={6}
                                  >
                                    <InputLabel
                                      htmlFor="appForInterimAwardFiled"
                                      sx={{ textWrap: "balance" }}
                                    >
                                      Application for Interim Award Filed
                                    </InputLabel>

                                    <Select
                                      labelId="appForInterimAwardFiled"
                                      id="appForInterimAwardFiled"
                                      name="appForInterimAwardFiled"
                                      value={values?.appForInterimAwardFiled}
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                      fullWidth // Ensures the Select is 100% width
                                    >
                                      <MenuItem value={"Yes"} defaultValue>
                                        Yes
                                      </MenuItem>
                                      <MenuItem value={"No"} defaultValue>
                                        No
                                      </MenuItem>
                                    </Select>
                                  </Box>
                                  {values?.appForInterimAwardFiled == "Yes" && (
                                    <Box
                                      sx={{
                                        display: "grid",
                                        alignItems: "end",
                                        px: 2,
                                        gridTemplateColumns: {
                                          xs: "1fr",
                                          sm: "1fr",
                                          md: "1fr",
                                        },
                                        width: {
                                          xs: "100%",
                                          sm: "50%",
                                          md: "50%",
                                        },
                                      }}
                                      xs={12}
                                      sm={6}
                                      md={6}
                                    >
                                      <Typography
                                        variant="body1"
                                        component="label"
                                        sx={{
                                          marginRight: 2,
                                          textWrap: "balance",
                                        }}
                                      >
                                        Application for Interim Award Field On
                                      </Typography>

                                      <DatePicker
                                        id="appForInterimAwardFieldOn"
                                        selected={
                                          values?.appForInterimAwardFieldOn
                                        }
                                        onChange={(date) => {
                                          setFieldValue(
                                            "appForInterimAwardFieldOn",
                                            date
                                          );
                                        }}
                                        sx={{
                                          width: "100%",
                                          gridTemplateColumns: {
                                            xs: "1fr",
                                            sm: "1fr",
                                          },
                                        }}
                                        onBlur={handleBlur}
                                        dateFormat="dd-MM-yyyy"
                                        placeholderText="DD-MM-YYYY"
                                        className="form-control"
                                      />
                                    </Box>
                                  )}
                                  {values?.appForInterimAwardFiled == "Yes" && (
                                    <Box
                                      sx={{
                                        display: "grid",
                                        alignItems: "end",
                                        px: 2,
                                        gridTemplateColumns: {
                                          xs: "1fr",
                                          sm: "1fr",
                                          md: "1fr",
                                        }, // Responsive layout
                                        width: {
                                          xs: "100%",
                                          sm: "50%",
                                          md: "50%",
                                        }, // Adjust width based on screen size
                                      }}
                                      xs={12}
                                      sm={6}
                                      md={6}
                                    >
                                      <InputLabel
                                        htmlFor="statusOfAppForInterimAwardField"
                                        sx={{ textWrap: "balance" }}
                                      >
                                        Status of Application for Interim Award
                                        Field:
                                      </InputLabel>

                                      <Select
                                        labelId="statusOfAppForInterimAwardField"
                                        id="statusOfAppForInterimAwardField"
                                        name="statusOfAppForInterimAwardField"
                                        value={
                                          values?.statusOfAppForInterimAwardField
                                        }
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        fullWidth // Ensures the Select is 100% width
                                      >
                                        {settings
                                          ?.find(
                                            (item) =>
                                              item?.categoryName ==
                                              "Status of Application"
                                          )
                                          ?.Children?.map((data, index) => {
                                            return (
                                              <MenuItem
                                                key={index}
                                                value={data?.generalSettingsID}
                                              >
                                                {data?.categoryName}
                                              </MenuItem>
                                            );
                                          })}
                                      </Select>
                                    </Box>
                                  )}
                                  {values?.appForInterimAwardFiled == "Yes" && (
                                    <Box
                                      sx={{
                                        display: "grid",
                                        alignItems: "end",
                                        px: 2,
                                        gridTemplateColumns: {
                                          xs: "1fr",
                                          sm: "1fr",
                                          md: "1fr",
                                        }, // Responsive layout
                                        width: {
                                          xs: "100%",
                                          sm: "50%",
                                          md: "50%",
                                        }, // Adjust width based on screen size
                                      }}
                                      xs={12}
                                      sm={6}
                                      md={6}
                                    >
                                      <InputLabel
                                        htmlFor="interimAwardPassed"
                                        sx={{ textWrap: "balance" }}
                                      >
                                        Interim Award Passed
                                      </InputLabel>

                                      <Select
                                        labelId="interimAwardPassed"
                                        id="interimAwardPassed"
                                        name="interimAwardPassed"
                                        value={values?.interimAwardPassed}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        fullWidth // Ensures the Select is 100% width
                                      >
                                        <MenuItem value={"Yes"} defaultValue>
                                          Yes
                                        </MenuItem>
                                        <MenuItem value={"No"} defaultValue>
                                          No
                                        </MenuItem>
                                      </Select>
                                    </Box>
                                  )}

                                  {values?.appForInterimAwardFiled == "Yes" && (
                                    <Box
                                      sx={{
                                        display: "grid",
                                        alignItems: "end",
                                        px: 2,
                                        gridTemplateColumns: {
                                          xs: "1fr",
                                          sm: "1fr",
                                          md: "1fr",
                                        },
                                        width: {
                                          xs: "100%",
                                          sm: "50%",
                                          md: "50%",
                                        },
                                      }}
                                      xs={12}
                                      sm={6}
                                      md={6}
                                    >
                                      <Typography
                                        variant="body1"
                                        component="label"
                                        sx={{
                                          marginRight: 2,
                                          textWrap: "balance",
                                        }}
                                      >
                                        Interim Award Passed On
                                      </Typography>

                                      <DatePicker
                                        id="interimAwardPassedOn"
                                        selected={values?.interimAwardPassedOn}
                                        onChange={(date) => {
                                          setFieldValue(
                                            "interimAwardPassedOn",
                                            date
                                          );
                                        }}
                                        sx={{
                                          width: "100%",
                                          gridTemplateColumns: {
                                            xs: "1fr",
                                            sm: "1fr",
                                          },
                                        }}
                                        onBlur={handleBlur}
                                        dateFormat="dd-MM-yyyy"
                                        placeholderText="DD-MM-YYYY"
                                        className="form-control"
                                      />
                                    </Box>
                                  )}
                                  {values?.appForInterimAwardFiled == "Yes" && (
                                    <Box
                                      sx={{
                                        display: "grid",
                                        alignItems: "end",
                                        px: 2,
                                        gridTemplateColumns: {
                                          xs: "1fr",
                                          sm: "1fr",
                                          md: "1fr",
                                        }, // Responsive layout
                                        width: {
                                          xs: "100%",
                                          sm: "50%",
                                          md: "50%",
                                        }, // Adjust width based on screen size
                                      }}
                                      xs={12}
                                      sm={6}
                                      md={6}
                                    >
                                      <Typography
                                        variant="body1"
                                        component="label"
                                        sx={{ textWrap: "balance" }}
                                      >
                                        Interim Award Notes
                                      </Typography>

                                      <OutlinedInput
                                        id="interimAwardNotes"
                                        name="interimAwardNotes"
                                        sx={{
                                          gridTemplateColumns: {
                                            xs: "1fr",
                                            sm: "1fr",
                                          },
                                        }}
                                        value={values?.interimAwardNotes}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                      />
                                    </Box>
                                  )}
                                </LocalizationProvider>
                              </Box>
                            </>
                          </AccordionDetails>
                        </Accordion>
                      </form>
                    </div>
                  );
                }}
              </Formik>
            ) : (
              <>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "end",
                        width: "100%",
                        backgroundColor: "white",
                      }}
                    >
                      <Typography
                        gutterBottom
                        variant="h5"
                        component="div"
                        className="m-0 "
                      >
                        Arbitration Interim Orders/Reliefs/Awards
                      </Typography>

                      <div>
                        <Button
                          type="button"
                          color="primary"
                          onClick={(event) => {
                            event.stopPropagation();
                            if (window.edit) {
                              event.stopPropagation();
                              setEditArbitrationInterimOrdersReliefsAwards(
                                true
                              );
                            } else {
                              animateCss(
                                "info",
                                "You are not authorized to update"
                              );
                            }
                          }}
                        >
                          Edit
                        </Button>
                      </div>
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={0}>
                      <Grid item xs={12} sm={6} md={6} px={2}>
                        <List sx={{ bgcolor: "background.paper" }}>
                          <ListItemText
                            primary="Application for Interim Relief/Order by Arbitrator under Section 17 Filed"
                            secondary={
                              rowData?.AppForInterimRelief_orderByArbitratorUnderSection17Filed
                            }
                          />
                        </List>
                      </Grid>
                      {rowData?.AppForInterimRelief_orderByArbitratorUnderSection17Filed ==
                        "Yes" && (
                        <Grid item xs={12} sm={6} md={6} px={2}>
                          <List sx={{ bgcolor: "background.paper" }}>
                            <ListItemText
                              primary="Application for Interim Relief/Order by Arbitrator under Section 17 Filed On"
                              secondary={
                                rowData?.iaApplicationDate
                                  ? trackerFormatDate(
                                      new Date(rowData?.iaApplicationDate)
                                    )
                                  : "DD-MM-YYYY"
                              }
                            />
                          </List>
                        </Grid>
                      )}
                      {rowData?.AppForInterimRelief_orderByArbitratorUnderSection17Filed ==
                        "Yes" && (
                        <Grid item xs={12} sm={6} md={6} px={2}>
                          <List sx={{ bgcolor: "background.paper" }}>
                            <ListItemText
                              primary="Status of Application for Interim Relief/Order by Arbitrator Section 17"
                              secondary={rowData?.iaOrderIssued}
                            />
                          </List>
                        </Grid>
                      )}
                      {rowData?.AppForInterimRelief_orderByArbitratorUnderSection17Filed ==
                        "Yes" && (
                        <Grid item xs={12} sm={6} md={6} px={2}>
                          <List sx={{ bgcolor: "background.paper" }}>
                            <ListItemText
                              primary="Interim Relief/Order by Arbitrator under Section 17 Passed On"
                              secondary={
                                rowData?.iaOrderDate
                                  ? trackerFormatDate(
                                      new Date(rowData?.iaOrderDate)
                                    )
                                  : "DD-MM-YYYY"
                              }
                            />
                          </List>
                        </Grid>
                      )}
                      {rowData?.AppForInterimRelief_orderByArbitratorUnderSection17Filed ==
                        "Yes" && (
                        <Grid item xs={12} sm={6} md={6} px={2}>
                          <List sx={{ bgcolor: "background.paper" }}>
                            <ListItemText
                              primary="Interim Relief/Order by Arbitrator under Section 17 Notes"
                              secondary={
                                rowData?.InterimRelief_OrderByArbitratorUnderSection17Notes
                              }
                            />
                          </List>
                        </Grid>
                      )}

                      <Grid item xs={12} sm={6} md={6} px={2}>
                        <List sx={{ bgcolor: "background.paper" }}>
                          <ListItemText
                            primary="Application for Interim Relief/Order before High Court under Section 9 Filed"
                            secondary={
                              rowData?.AppForInterimRelief_OrderBeforeHighCourtUnderSection9Filed
                            }
                          />
                        </List>
                      </Grid>

                      {rowData?.AppForInterimRelief_OrderBeforeHighCourtUnderSection9Filed ==
                        "Yes" && (
                        <Grid item xs={12} sm={6} md={6} px={2}>
                          <List sx={{ bgcolor: "background.paper" }}>
                            <ListItemText
                              primary="Application for Interim Relief/Order before High Court under Section 9 Filed On"
                              secondary={
                                rowData?.AppForInterimRelief_OrderBeforeHighCourtUnderSection9FiledOn
                              }
                            />
                          </List>
                        </Grid>
                      )}
                      {rowData?.AppForInterimRelief_OrderBeforeHighCourtUnderSection9Filed ==
                        "Yes" && (
                        <Grid item xs={12} sm={6} md={6} px={2}>
                          <List sx={{ bgcolor: "background.paper" }}>
                            <ListItemText
                              primary="Status of Application for Interim Relief/Order before High Court under Section 9"
                              secondary={
                                rowData?.StatusOfAppForInterimRelief_OrderBeforeHighCourtUnderSection9
                              }
                            />
                          </List>
                        </Grid>
                      )}
                      {rowData?.AppForInterimRelief_OrderBeforeHighCourtUnderSection9Filed ==
                        "Yes" && (
                        <Grid item xs={12} sm={6} md={6} px={2}>
                          <List sx={{ bgcolor: "background.paper" }}>
                            <ListItemText
                              primary=" Interim Relief/Order by High Court under Section 9 Passed On"
                              secondary={
                                rowData?.InterimRelief_OrderByHighCourtUnderSection9PassedOn
                              }
                            />
                          </List>
                        </Grid>
                      )}
                      {rowData?.AppForInterimRelief_OrderBeforeHighCourtUnderSection9Filed ==
                        "Yes" && (
                        <Grid item xs={12} sm={6} md={6} px={2}>
                          <List sx={{ bgcolor: "background.paper" }}>
                            <ListItemText
                              primary="Interim Relief/Order by High Court under Section 9 Notes"
                              secondary={
                                rowData?.InterimRelief_OrderByHighCourtUnderSection9Notes
                              }
                            />
                          </List>
                        </Grid>
                      )}

                      <Grid item xs={12} sm={6} md={6} px={2}>
                        <List sx={{ bgcolor: "background.paper" }}>
                          <ListItemText
                            primary="Application for Interim Award Filed"
                            secondary={rowData?.AppForInterimAwardFiled}
                          />
                        </List>
                      </Grid>
                      {rowData?.AppForInterimAwardFiled == "Yes" && (
                        <Grid item xs={12} sm={6} md={6} px={2}>
                          <List sx={{ bgcolor: "background.paper" }}>
                            <ListItemText
                              primary=" Application for Interim Award Field On"
                              secondary={
                                rowData?.AppForInterimAwardFieldOn
                                  ? trackerFormatDate(
                                      new Date(
                                        rowData?.AppForInterimAwardFieldOn
                                      )
                                    )
                                  : "DD-MM-YYYY"
                              }
                            />
                          </List>
                        </Grid>
                      )}
                      {rowData?.AppForInterimAwardFiled == "Yes" && (
                        <Grid item xs={12} sm={6} md={6} px={2}>
                          <List sx={{ bgcolor: "background.paper" }}>
                            <ListItemText
                              primary="Status of Application for Interim Award
                                    Field"
                              secondary={
                                rowData?.statusOfAppForInterimAwardField
                                  ?.categoryName
                              }
                            />
                          </List>
                        </Grid>
                      )}
                      {rowData?.AppForInterimAwardFiled == "Yes" && (
                        <Grid item xs={12} sm={6} md={6} px={2}>
                          <List sx={{ bgcolor: "background.paper" }}>
                            <ListItemText
                              primary="Interim Award Passed"
                              secondary={rowData?.InterimAwardPassed}
                            />
                          </List>
                        </Grid>
                      )}
                      {rowData?.AppForInterimAwardFiled == "Yes" && (
                        <Grid item xs={12} sm={6} md={6} px={2}>
                          <List sx={{ bgcolor: "background.paper" }}>
                            <ListItemText
                              primary="Interim Award Passed On"
                              secondary={
                                rowData?.InterimAwardPassedOn
                                  ? trackerFormatDate(
                                      new Date(rowData?.InterimAwardPassedOn)
                                    )
                                  : "DD-MM-YYYY"
                              }
                            />
                          </List>
                        </Grid>
                      )}
                      {rowData?.AppForInterimAwardFiled == "Yes" && (
                        <Grid item xs={12} sm={6} md={6} px={2}>
                          <List sx={{ bgcolor: "background.paper" }}>
                            <ListItemText
                              primary="Interim Award Notes"
                              secondary={rowData?.InterimAwardNotes}
                            />
                          </List>
                        </Grid>
                      )}
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </>
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            {editArbitrationWitnessEvidenceAndExpertReport ? (
              <Formik
                initialValues={{
                  claimantsListOfEvidence: !rowData?.caseID
                    ? null
                    : rowData?.ClaimantsListOfEvidence,
                  respondentsListOfEvidence: !rowData?.caseID
                    ? null
                    : rowData?.RespondentsListOfEvidence,
                  claimantWitness: !rowData?.caseID
                    ? null
                    : rowData?.ClaimantWitness,
                  respondentWitness: !rowData?.caseID
                    ? null
                    : rowData?.RespondentWitness,
                  expertReportFiledOn: !rowData?.caseID
                    ? null
                    : rowData?.ExpertReportFiledOn,
                  params,
                  user,
                }}
                onSubmit={async (values, actions) => {
                  try {
                    dispatch(
                      updateArbitrationWitnessEvidenceAndExpertReportAction(
                        values
                      )
                    );
                    setEditArbitrationWitnessEvidenceAndExpertReport(false);
                    setTimeout(() => {
                      dispatch(singleCaseDetailsAction(params));
                    }, 1000);
                  } catch (error) {}
                }}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  touched,
                  values,
                  setFieldValue,
                  resetForm,
                }) => {
                  return (
                    <div className="d-flex  col-12">
                      <form onSubmit={handleSubmit} xs={12} sm={12} md={12}>
                        <Accordion defaultExpanded>
                          <AccordionSummary
                            onClick={(event) => {
                              event.stopPropagation();
                            }}
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                          >
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "end",
                                width: "100%",
                                backgroundColor: "white",
                              }}
                              xs={12}
                              sm={12}
                              md={12}
                            >
                              <Typography
                                gutterBottom
                                variant="h5"
                                component="div"
                                className="m-0 "
                              >
                                Arbitration Witness, Evidence and Expert Report
                              </Typography>

                              <div>
                                <Button
                                  type="button"
                                  color="primary"
                                  onClick={(event) => {
                                    event.stopPropagation();
                                    setEditArbitrationWitnessEvidenceAndExpertReport(
                                      false
                                    );
                                  }}
                                >
                                  Cancel
                                </Button>
                                <Button
                                  color="primary"
                                  onClick={async () => {}}
                                  type="submit"
                                >
                                  Submit
                                </Button>
                              </div>
                            </Box>
                          </AccordionSummary>
                          <AccordionDetails>
                            <>
                              <Box
                                xs={12}
                                sm={12}
                                md={12}
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "end",
                                  backgroundColor: "white",
                                  flexWrap: "wrap",
                                  alignItems: "stretch",
                                }}
                              >
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <Box
                                    sx={{
                                      display: "grid",
                                      alignItems: "end",
                                      px: 2,
                                      gridTemplateColumns: {
                                        xs: "1fr",
                                        sm: "1fr",
                                        md: "1fr",
                                      },
                                      width: {
                                        xs: "100%",
                                        sm: "50%",
                                        md: "50%",
                                      },
                                    }}
                                    xs={12}
                                    sm={6}
                                    md={6}
                                  >
                                    <Typography
                                      variant="body1"
                                      component="label"
                                      sx={{ textWrap: "balance" }}
                                    >
                                      Claimant's List of Evidence
                                    </Typography>

                                    <OutlinedInput
                                      id="claimantsListOfEvidence"
                                      name="claimantsListOfEvidence"
                                      sx={{
                                        gridTemplateColumns: {
                                          xs: "1fr",
                                          sm: "1fr",
                                        },
                                      }}
                                      value={values?.claimantsListOfEvidence}
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                    />
                                  </Box>

                                  <Box
                                    sx={{
                                      display: "grid",
                                      alignItems: "end",
                                      px: 2,
                                      gridTemplateColumns: {
                                        xs: "1fr",
                                        sm: "1fr",
                                        md: "1fr",
                                      },
                                      width: {
                                        xs: "100%",
                                        sm: "50%",
                                        md: "50%",
                                      },
                                    }}
                                    xs={12}
                                    sm={6}
                                    md={6}
                                  >
                                    <Typography
                                      variant="body1"
                                      component="label"
                                      sx={{ textWrap: "balance" }}
                                    >
                                      Respondent's List of Evidence
                                    </Typography>

                                    <OutlinedInput
                                      id="respondentsListOfEvidence"
                                      name="respondentsListOfEvidence"
                                      sx={{
                                        gridTemplateColumns: {
                                          xs: "1fr",
                                          sm: "1fr",
                                        },
                                      }}
                                      value={values?.respondentsListOfEvidence}
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                    />
                                  </Box>

                                  <Box
                                    sx={{
                                      display: "grid",
                                      alignItems: "end",
                                      px: 2,
                                      gridTemplateColumns: {
                                        xs: "1fr",
                                        sm: "1fr",
                                        md: "1fr",
                                      },
                                      width: {
                                        xs: "100%",
                                        sm: "50%",
                                        md: "50%",
                                      },
                                    }}
                                    xs={12}
                                    sm={6}
                                    md={6}
                                  >
                                    <Typography
                                      variant="body1"
                                      component="label"
                                      sx={{ textWrap: "balance" }}
                                    >
                                      Claimant Witness
                                    </Typography>

                                    <OutlinedInput
                                      id="claimantWitness"
                                      name="claimantWitness"
                                      sx={{
                                        gridTemplateColumns: {
                                          xs: "1fr",
                                          sm: "1fr",
                                        },
                                      }}
                                      value={values?.claimantWitness}
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                    />
                                  </Box>

                                  <Box
                                    sx={{
                                      display: "grid",
                                      alignItems: "end",
                                      px: 2,
                                      gridTemplateColumns: {
                                        xs: "1fr",
                                        sm: "1fr",
                                        md: "1fr",
                                      },
                                      width: {
                                        xs: "100%",
                                        sm: "50%",
                                        md: "50%",
                                      },
                                    }}
                                    xs={12}
                                    sm={6}
                                    md={6}
                                  >
                                    <Typography
                                      variant="body1"
                                      component="label"
                                      sx={{ textWrap: "balance" }}
                                    >
                                      Respondent Witness
                                    </Typography>

                                    <OutlinedInput
                                      id="respondentWitness"
                                      name="respondentWitness"
                                      sx={{
                                        gridTemplateColumns: {
                                          xs: "1fr",
                                          sm: "1fr",
                                        },
                                      }}
                                      value={values?.respondentWitness}
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                    />
                                  </Box>

                                  <Box
                                    sx={{
                                      display: "grid",
                                      alignItems: "end",
                                      px: 2,
                                      gridTemplateColumns: {
                                        xs: "1fr",
                                        sm: "1fr",
                                        md: "1fr",
                                      },
                                      width: {
                                        xs: "100%",
                                        sm: "50%",
                                        md: "50%",
                                      },
                                    }}
                                    xs={12}
                                    sm={6}
                                    md={6}
                                  >
                                    <Typography
                                      variant="body1"
                                      component="label"
                                      sx={{ textWrap: "balance" }}
                                    >
                                      Expert Report Filed On
                                    </Typography>

                                    <OutlinedInput
                                      id="expertReportFiledOn"
                                      name="expertReportFiledOn"
                                      sx={{
                                        gridTemplateColumns: {
                                          xs: "1fr",
                                          sm: "1fr",
                                        },
                                      }}
                                      value={values?.expertReportFiledOn}
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                    />
                                  </Box>
                                </LocalizationProvider>
                              </Box>
                            </>
                          </AccordionDetails>
                        </Accordion>
                      </form>
                    </div>
                  );
                }}
              </Formik>
            ) : (
              <>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "end",
                        width: "100%",
                        backgroundColor: "white",
                      }}
                    >
                      <Typography
                        gutterBottom
                        variant="h5"
                        component="div"
                        className="m-0 "
                      >
                        Arbitration Witness, Evidence and Expert Report
                      </Typography>

                      <div>
                        <Button
                          type="button"
                          color="primary"
                          onClick={(event) => {
                            event.stopPropagation();
                            if (window.edit) {
                              event.stopPropagation();
                              // resetForm();
                              setEditArbitrationWitnessEvidenceAndExpertReport(
                                true
                              );
                            } else {
                              animateCss(
                                "info",
                                "You are not authorized to update"
                              );
                            }
                          }}
                        >
                          Edit
                        </Button>
                      </div>
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={0}>
                      <Grid item xs={12} sm={6} md={6}>
                        <List sx={{ bgcolor: "background.paper" }}>
                          <ListItemText
                            primary="Claimant's List of Evidence"
                            secondary={rowData?.ClaimantsListOfEvidence}
                          />
                        </List>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <List sx={{ bgcolor: "background.paper" }}>
                          <ListItemText
                            primary="Respondent's List of Evidence"
                            secondary={rowData?.RespondentsListOfEvidence}
                          />
                        </List>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <List sx={{ bgcolor: "background.paper" }}>
                          <ListItemText
                            primary="Claimant Witness"
                            secondary={rowData?.ClaimantWitness}
                          />
                        </List>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <List sx={{ bgcolor: "background.paper" }}>
                          <ListItemText
                            primary="Respondent Witness"
                            secondary={rowData?.RespondentWitness}
                          />
                        </List>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <List sx={{ bgcolor: "background.paper" }}>
                          <ListItemText
                            primary="Expert Report Filed On"
                            secondary={rowData?.ExpertReportFiledOn}
                          />
                        </List>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </>
            )}

            {editArbitrationEnforcementAndAppeals ? (
              <Formik
                initialValues={{
                  noticeToSuspendArbitrationProceedingsReceivedOn:
                    !rowData?.caseID
                      ? null
                      : rowData?.NoticeToSuspendArbitrationProceedingsReceivedOn,
                  noticeToResumeArbitrationProceedingsFromHoldReceivedOn:
                    !rowData?.caseID
                      ? null
                      : rowData?.NoticeToResumeArbitrationProceedingsFromHoldReceivedOn,
                  noticeOfArbitrationWithdrawalReceivedOn: !rowData?.caseID
                    ? null
                    : rowData?.NoticeOfArbitrationWithdrawalReceivedOn,
                  noticeOfDisputeSettlementReceivedOn: !rowData?.caseID
                    ? null
                    : rowData?.NoticeOfDisputeSettlementReceivedOn,
                  settlementAgreementFiledOn: !rowData?.caseID
                    ? null
                    : rowData?.SettlementAgreementFiledOn,
                  params,
                  user,
                }}
                onSubmit={async (values, actions) => {
                  try {
                    dispatch(
                      updateArbitrationHoldWithdrawalAndSettlementAction(values)
                    );
                    setEditArbitrationEnforcementAndAppeals(false);
                    setTimeout(() => {
                      dispatch(singleCaseDetailsAction(params));
                    }, 1000);
                  } catch (error) {}
                }}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  touched,
                  values,
                  setFieldValue,
                  resetForm,
                }) => {
                  return (
                    <div className="d-flex  col-12">
                      <form onSubmit={handleSubmit} xs={12} sm={12} md={12}>
                        <Accordion defaultExpanded>
                          <AccordionSummary
                            onClick={(event) => {
                              event.stopPropagation();
                            }}
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                          >
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "end",
                                width: "100%",
                                backgroundColor: "white",
                              }}
                              xs={12}
                              sm={12}
                              md={12}
                            >
                              <Typography
                                gutterBottom
                                variant="h5"
                                component="div"
                                className="m-0 "
                              >
                                Arbitration Hold, Withdrawal, and Settlement
                              </Typography>

                              <div>
                                <Button
                                  type="button"
                                  color="primary"
                                  onClick={(event) => {
                                    event.stopPropagation();
                                    setEditArbitrationEnforcementAndAppeals(
                                      false
                                    );
                                  }}
                                >
                                  Cancel
                                </Button>
                                <Button
                                  color="primary"
                                  onClick={async () => {}}
                                  type="submit"
                                >
                                  Submit
                                </Button>
                              </div>
                            </Box>
                          </AccordionSummary>
                          <AccordionDetails>
                            <>
                              <Box
                                xs={12}
                                sm={12}
                                md={12}
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "end",
                                  backgroundColor: "white",
                                  flexWrap: "wrap",
                                  alignItems: "stretch",
                                }}
                              >
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <Box
                                    sx={{
                                      display: "grid",
                                      alignItems: "end",
                                      px: 2,
                                      gridTemplateColumns: {
                                        xs: "1fr",
                                        sm: "1fr",
                                        md: "1fr",
                                      }, // Responsive layout
                                      width: {
                                        xs: "100%",
                                        sm: "50%",
                                        md: "50%",
                                      }, // Adjust width based on screen size
                                    }}
                                    xs={12}
                                    sm={6}
                                    md={6}
                                  >
                                    <Typography
                                      variant="body1"
                                      component="label"
                                      sx={{ textWrap: "balance" }}
                                    >
                                      Notice to Suspend Arbitration Proceedings
                                      Received On
                                    </Typography>

                                    <OutlinedInput
                                      id="noticeToSuspendArbitrationProceedingsReceivedOn"
                                      name="noticeToSuspendArbitrationProceedingsReceivedOn"
                                      sx={{
                                        gridTemplateColumns: {
                                          xs: "1fr",
                                          sm: "1fr",
                                        },
                                      }}
                                      value={
                                        values?.noticeToSuspendArbitrationProceedingsReceivedOn
                                      }
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                    />
                                  </Box>

                                  <Box
                                    sx={{
                                      display: "grid",
                                      alignItems: "end",
                                      px: 2,
                                      gridTemplateColumns: {
                                        xs: "1fr",
                                        sm: "1fr",
                                        md: "1fr",
                                      }, // Responsive layout
                                      width: {
                                        xs: "100%",
                                        sm: "50%",
                                        md: "50%",
                                      }, // Adjust width based on screen size
                                    }}
                                    xs={12}
                                    sm={6}
                                    md={6}
                                  >
                                    <Typography
                                      variant="body1"
                                      component="label"
                                      sx={{ textWrap: "balance" }}
                                    >
                                      Notice to Resume Arbitration Proceedings
                                      from Hold Received on
                                    </Typography>

                                    <OutlinedInput
                                      id="noticeToResumeArbitrationProceedingsFromHoldReceivedOn"
                                      name="noticeToResumeArbitrationProceedingsFromHoldReceivedOn"
                                      sx={{
                                        gridTemplateColumns: {
                                          xs: "1fr",
                                          sm: "1fr",
                                        },
                                      }}
                                      value={
                                        values?.noticeToResumeArbitrationProceedingsFromHoldReceivedOn
                                      }
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                    />
                                  </Box>

                                  <Box
                                    sx={{
                                      display: "grid",
                                      alignItems: "end",
                                      px: 2,
                                      gridTemplateColumns: {
                                        xs: "1fr",
                                        sm: "1fr",
                                        md: "1fr",
                                      }, // Responsive layout
                                      width: {
                                        xs: "100%",
                                        sm: "50%",
                                        md: "50%",
                                      }, // Adjust width based on screen size
                                    }}
                                    xs={12}
                                    sm={6}
                                    md={6}
                                  >
                                    <Typography
                                      variant="body1"
                                      component="label"
                                      sx={{ textWrap: "balance" }}
                                    >
                                      Notice of Arbitration Withdrawal Received
                                      On
                                    </Typography>

                                    <OutlinedInput
                                      id="noticeOfArbitrationWithdrawalReceivedOn"
                                      name="noticeOfArbitrationWithdrawalReceivedOn"
                                      sx={{
                                        gridTemplateColumns: {
                                          xs: "1fr",
                                          sm: "1fr",
                                        },
                                      }}
                                      value={
                                        values?.noticeOfArbitrationWithdrawalReceivedOn
                                      }
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                    />
                                  </Box>
                                  <Box
                                    sx={{
                                      display: "grid",
                                      alignItems: "end",
                                      px: 2,
                                      gridTemplateColumns: {
                                        xs: "1fr",
                                        sm: "1fr",
                                        md: "1fr",
                                      }, // Responsive layout
                                      width: {
                                        xs: "100%",
                                        sm: "50%",
                                        md: "50%",
                                      }, // Adjust width based on screen size
                                    }}
                                    xs={12}
                                    sm={6}
                                    md={6}
                                  >
                                    <Typography
                                      variant="body1"
                                      component="label"
                                      sx={{ textWrap: "balance" }}
                                    >
                                      Notice of Dispute Settlement Received On
                                    </Typography>

                                    <OutlinedInput
                                      id="noticeOfDisputeSettlementReceivedOn"
                                      name="noticeOfDisputeSettlementReceivedOn"
                                      sx={{
                                        gridTemplateColumns: {
                                          xs: "1fr",
                                          sm: "1fr",
                                        },
                                      }}
                                      value={
                                        values?.noticeOfDisputeSettlementReceivedOn
                                      }
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                    />
                                  </Box>
                                  <Box
                                    sx={{
                                      display: "grid",
                                      alignItems: "end",
                                      px: 2,
                                      gridTemplateColumns: {
                                        xs: "1fr",
                                        sm: "1fr",
                                        md: "1fr",
                                      }, // Responsive layout
                                      width: {
                                        xs: "100%",
                                        sm: "50%",
                                        md: "50%",
                                      }, // Adjust width based on screen size
                                    }}
                                    xs={12}
                                    sm={6}
                                    md={6}
                                  >
                                    <Typography
                                      variant="body1"
                                      component="label"
                                      sx={{ textWrap: "balance" }}
                                    >
                                      Settlement Agreement Filed On
                                    </Typography>

                                    <OutlinedInput
                                      id="settlementAgreementFiledOn"
                                      name="settlementAgreementFiledOn"
                                      sx={{
                                        gridTemplateColumns: {
                                          xs: "1fr",
                                          sm: "1fr",
                                        },
                                      }}
                                      value={values?.settlementAgreementFiledOn}
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                    />
                                  </Box>
                                </LocalizationProvider>
                              </Box>
                            </>
                          </AccordionDetails>
                        </Accordion>
                      </form>
                    </div>
                  );
                }}
              </Formik>
            ) : (
              <>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "end",
                        width: "100%",
                        backgroundColor: "white",
                      }}
                    >
                      <Typography
                        gutterBottom
                        variant="h5"
                        component="div"
                        className="m-0 "
                      >
                        Arbitration Hold, Withdrawal, and Settlement
                      </Typography>

                      <div>
                        <Button
                          onClick={(event) => {
                            event.stopPropagation();
                            if (window.edit) {
                              setEditArbitrationEnforcementAndAppeals(true);
                            } else {
                              animateCss(
                                "info",
                                "You are not authorized to update"
                              );
                            }
                          }}
                          type="button"
                          color="primary"
                        >
                          Edit
                        </Button>
                      </div>
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={0}>
                      <Grid item xs={12} sm={6} md={6}>
                        <List sx={{ bgcolor: "background.paper" }}>
                          <ListItemText
                            primary=" Notice to Suspend Arbitration Proceedings Received On"
                            secondary={
                              rowData?.NoticeToSuspendArbitrationProceedingsReceivedOn
                            }
                          />
                        </List>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <List sx={{ bgcolor: "background.paper" }}>
                          <ListItemText
                            primary=" Notice to Resume Arbitration Proceedings from Hold Received on"
                            secondary={
                              rowData?.NoticeToResumeArbitrationProceedingsFromHoldReceivedOn
                            }
                          />
                        </List>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <List sx={{ bgcolor: "background.paper" }}>
                          <ListItemText
                            primary="Notice of Arbitration Withdrawal Received On"
                            secondary={
                              rowData?.NoticeOfArbitrationWithdrawalReceivedOn
                            }
                          />
                        </List>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <List sx={{ bgcolor: "background.paper" }}>
                          <ListItemText
                            primary="Notice of Dispute Settlement Received On"
                            secondary={
                              rowData?.NoticeOfDisputeSettlementReceivedOn
                            }
                          />
                        </List>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <List sx={{ bgcolor: "background.paper" }}>
                          <ListItemText
                            primary="Settlement Agreement Filed On"
                            secondary={rowData?.SettlementAgreementFiledOn}
                          />
                        </List>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </>
            )}

            {editArbitrationHoldWithdrawalAndSettlement ? (
              <Formik
                initialValues={{
                  enforcementAppFiledOn: !rowData?.caseID
                    ? null
                    : rowData?.EnforcementAppFiledOn,
                  enforcementStatus: !rowData?.caseID
                    ? null
                    : rowData?.EnforcementStatus,
                  appealFiledOn: !rowData?.caseID
                    ? null
                    : rowData?.AppealFiledOn,
                  appealStatus: !rowData?.caseID ? null : rowData?.AppealStatus,
                  params,
                  user,
                }}
                onSubmit={async (values, actions) => {
                  try {
                    dispatch(
                      updateArbitrationEnforcementAndAppealsAction(values)
                    );

                    setEditArbitrationHoldWithdrawalAndSettlement(false);
                    setTimeout(() => {
                      dispatch(singleCaseDetailsAction(params));
                    }, 1000);
                  } catch (error) {}
                }}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  touched,
                  values,
                  setFieldValue,
                  resetForm,
                }) => {
                  return (
                    <div className="d-flex  col-12">
                      <form onSubmit={handleSubmit} xs={12} sm={12} md={12}>
                        <Accordion defaultExpanded>
                          <AccordionSummary
                            onClick={(event) => {
                              event.stopPropagation();
                            }}
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                          >
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "end",
                                width: "100%",
                                backgroundColor: "white",
                              }}
                              xs={12}
                              sm={12}
                              md={12}
                            >
                              <Typography
                                gutterBottom
                                variant="h5"
                                component="div"
                                className="m-0 "
                              >
                                Arbitration Enforcement and Appeals
                              </Typography>

                              <div>
                                <Button
                                  type="button"
                                  color="primary"
                                  onClick={(event) => {
                                    event.stopPropagation();
                                    setEditArbitrationHoldWithdrawalAndSettlement(
                                      false
                                    );
                                  }}
                                >
                                  Cancel
                                </Button>
                                <Button
                                  color="primary"
                                  onClick={async () => {}}
                                  type="submit"
                                >
                                  Submit
                                </Button>
                              </div>
                            </Box>
                          </AccordionSummary>
                          <AccordionDetails>
                            <>
                              <Box
                                xs={12}
                                sm={12}
                                md={12}
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "end",
                                  backgroundColor: "white",
                                  flexWrap: "wrap",
                                  alignItems: "stretch",
                                }}
                              >
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <Box
                                    sx={{
                                      display: "grid",
                                      alignItems: "end",
                                      px: 2,
                                      gridTemplateColumns: {
                                        xs: "1fr",
                                        sm: "1fr",
                                        md: "1fr",
                                      }, // Responsive layout
                                      width: {
                                        xs: "100%",
                                        sm: "50%",
                                        md: "50%",
                                      }, // Adjust width based on screen size
                                    }}
                                    xs={12}
                                    sm={6}
                                    md={6}
                                  >
                                    <Typography
                                      variant="body1"
                                      component="label"
                                      sx={{ textWrap: "balance" }}
                                    >
                                      Enforcement Application Filed On:
                                    </Typography>

                                    <OutlinedInput
                                      id="enforcementAppFiledOn"
                                      name="enforcementAppFiledOn"
                                      sx={{
                                        gridTemplateColumns: {
                                          xs: "1fr",
                                          sm: "1fr",
                                        },
                                      }}
                                      value={values?.enforcementAppFiledOn}
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                    />
                                  </Box>

                                  <Box
                                    sx={{
                                      display: "grid",
                                      alignItems: "end",
                                      px: 2,
                                      gridTemplateColumns: {
                                        xs: "1fr",
                                        sm: "1fr",
                                        md: "1fr",
                                      }, // Responsive layout
                                      width: {
                                        xs: "100%",
                                        sm: "50%",
                                        md: "50%",
                                      }, // Adjust width based on screen size
                                    }}
                                    xs={12}
                                    sm={6}
                                    md={6}
                                  >
                                    <InputLabel
                                      htmlFor="enforcementStatus"
                                      sx={{ textWrap: "balance" }}
                                    >
                                      Enforcement Status
                                    </InputLabel>

                                    <Select
                                      labelId="enforcementStatus"
                                      id="enforcementStatus"
                                      name="enforcementStatus"
                                      value={values?.enforcementStatus}
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                      fullWidth // Ensures the Select is 100% width
                                    >
                                      {settings
                                        ?.find(
                                          (item) =>
                                            item?.categoryName ==
                                            "Status of Application"
                                        )
                                        ?.Children?.map((data, index) => {
                                          return (
                                            <MenuItem
                                              key={index}
                                              value={data?.generalSettingsID}
                                            >
                                              {data?.categoryName}
                                            </MenuItem>
                                          );
                                        })}
                                    </Select>
                                  </Box>

                                  <Box
                                    sx={{
                                      display: "grid",
                                      alignItems: "end",
                                      px: 2,
                                      gridTemplateColumns: {
                                        xs: "1fr",
                                        sm: "1fr",
                                        md: "1fr",
                                      }, // Responsive layout
                                      width: {
                                        xs: "100%",
                                        sm: "50%",
                                        md: "50%",
                                      }, // Adjust width based on screen size
                                    }}
                                    xs={12}
                                    sm={6}
                                    md={6}
                                  >
                                    <Typography
                                      variant="body1"
                                      component="label"
                                      sx={{ textWrap: "balance" }}
                                    >
                                      Appeal Filed On
                                    </Typography>

                                    <OutlinedInput
                                      id="appealFiledOn"
                                      name="appealFiledOn"
                                      sx={{
                                        gridTemplateColumns: {
                                          xs: "1fr",
                                          sm: "1fr",
                                        },
                                      }}
                                      value={values?.appealFiledOn}
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                    />
                                  </Box>

                                  <Box
                                    sx={{
                                      display: "grid",
                                      alignItems: "end",
                                      px: 2,
                                      gridTemplateColumns: {
                                        xs: "1fr",
                                        sm: "1fr",
                                        md: "1fr",
                                      }, // Responsive layout
                                      width: {
                                        xs: "100%",
                                        sm: "50%",
                                        md: "50%",
                                      }, // Adjust width based on screen size
                                    }}
                                    xs={12}
                                    sm={6}
                                    md={6}
                                  >
                                    <InputLabel
                                      htmlFor="appealStatus"
                                      sx={{ textWrap: "balance" }}
                                    >
                                      Appeal Status
                                    </InputLabel>

                                    <Select
                                      labelId="appealStatus"
                                      id="appealStatus"
                                      name="appealStatus"
                                      value={values?.appealStatus}
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                      fullWidth // Ensures the Select is 100% width
                                    >
                                      {settings
                                        ?.find(
                                          (item) =>
                                            item?.categoryName ==
                                            "Status of Application"
                                        )
                                        ?.Children?.map((data, index) => {
                                          return (
                                            <MenuItem
                                              key={index}
                                              value={data?.generalSettingsID}
                                            >
                                              {data?.categoryName}
                                            </MenuItem>
                                          );
                                        })}
                                    </Select>
                                  </Box>
                                </LocalizationProvider>
                              </Box>
                            </>
                          </AccordionDetails>
                        </Accordion>
                      </form>
                    </div>
                  );
                }}
              </Formik>
            ) : (
              <>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "end",
                        width: "100%",
                        backgroundColor: "white",
                      }}
                    >
                      <Typography
                        gutterBottom
                        variant="h5"
                        component="div"
                        className="m-0 "
                      >
                        Arbitration Enforcement and Appeals
                      </Typography>

                      <div>
                        <Button
                          onClick={(event) => {
                            event.stopPropagation();
                            if (window.edit) {
                              setEditArbitrationHoldWithdrawalAndSettlement(
                                true
                              );
                            } else {
                              animateCss(
                                "info",
                                "You are not authorized to update"
                              );
                            }
                          }}
                          type="button"
                          color="primary"
                        >
                          Edit
                        </Button>
                      </div>
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={0}>
                      <Grid item xs={12} sm={6} md={6}>
                        <List sx={{ bgcolor: "background.paper" }}>
                          <ListItemText
                            primary="Enforcement Application Filed On"
                            secondary={rowData?.EnforcementAppFiledOn}
                          />
                        </List>
                      </Grid>

                      <Grid item xs={12} sm={6} md={6}>
                        <List sx={{ bgcolor: "background.paper" }}>
                          <ListItemText
                            primary="Enforcement Status"
                            secondary={rowData?.enforcementStatus?.categoryName}
                          />
                        </List>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <List sx={{ bgcolor: "background.paper" }}>
                          <ListItemText
                            primary="Appeal Filed On"
                            secondary={rowData?.AppealFiledOn}
                          />
                        </List>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <List sx={{ bgcolor: "background.paper" }}>
                          <ListItemText
                            primary="Appeal Status"
                            secondary={rowData?.appealStatus?.categoryName}
                          />
                        </List>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </>
            )}

            {editArbitrationFeesAndExpenses ? (
              <Formik
                initialValues={{
                  arbitrationFeePaymentStatus: !rowData?.caseID
                    ? null
                    : rowData?.ArbitrationFeePaymentStatus,
                  params,
                  user,
                }}
                onSubmit={async (values, actions) => {
                  try {
                    dispatch(updateArbitrationFeePaymentStatusAction(values));
                    setEditArbitrationFeesAndExpenses(false);
                    setTimeout(() => {
                      dispatch(singleCaseDetailsAction(params));
                    }, 1000);
                  } catch (error) {}
                }}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  touched,
                  values,
                  setFieldValue,
                  resetForm,
                }) => {
                  return (
                    <div className="d-flex  col-12">
                      <form
                        onSubmit={handleSubmit}
                        xs={12}
                        sm={12}
                        md={12}
                        style={{ width: "100%" }}
                      >
                        <Accordion defaultExpanded>
                          <AccordionSummary
                            onClick={(event) => {
                              event.stopPropagation();
                            }}
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                          >
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "end",
                                width: "100%",
                                backgroundColor: "white",
                              }}
                              xs={12}
                              sm={12}
                              md={12}
                            >
                              <Typography
                                gutterBottom
                                variant="h5"
                                component="div"
                                className="m-0 "
                              >
                                Arbitration Fees and Expenses
                              </Typography>

                              <div>
                                <Button
                                  type="button"
                                  color="primary"
                                  onClick={(event) => {
                                    event.stopPropagation();
                                    setEditArbitrationFeesAndExpenses(false);
                                  }}
                                >
                                  Cancel
                                </Button>
                                <Button
                                  color="primary"
                                  onClick={async () => {}}
                                  type="submit"
                                >
                                  Submit
                                </Button>
                              </div>
                            </Box>
                          </AccordionSummary>
                          <AccordionDetails>
                            <>
                              <Box
                                xs={12}
                                sm={12}
                                md={12}
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "end",
                                  backgroundColor: "white",
                                  flexWrap: "wrap",
                                }}
                              >
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <Box
                                    sx={{
                                      display: "grid",
                                      alignItems: "end",
                                      px: 2,
                                      gridTemplateColumns: {
                                        xs: "1fr",
                                        sm: "1fr",
                                        md: "1fr",
                                      },
                                      width: {
                                        xs: "100%",
                                        sm: "100%",
                                        md: "100%",
                                      },
                                    }}
                                    xs={12}
                                    sm={12}
                                    md={12}
                                  >
                                    <InputLabel
                                      htmlFor="caseStatus"
                                      sx={{ textWrap: "balance" }}
                                    >
                                      Arbitration Fee Payment Status
                                    </InputLabel>
                                  </Box>
                                  <Box
                                    sx={{
                                      display: "grid",
                                      alignItems: "end",
                                      px: 2,
                                      gridTemplateColumns: {
                                        xs: "1fr",
                                        sm: "1fr",
                                        md: "1fr",
                                      },
                                      width: {
                                        xs: "100%",
                                        sm: "100%",
                                        md: "100%",
                                      },
                                    }}
                                    xs={12}
                                    sm={12}
                                    md={12}
                                  >
                                    <Select
                                      labelId="demo-multiple-name-label"
                                      id="arbitrationFeePaymentStatus"
                                      name="arbitrationFeePaymentStatus"
                                      value={
                                        values?.arbitrationFeePaymentStatus
                                      }
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                      fullWidth
                                    >
                                      {settings
                                        ?.find(
                                          (item) =>
                                            item?.categoryName ==
                                            "Payment Status"
                                        )
                                        ?.Children?.map((data, index) => {
                                          return (
                                            <MenuItem
                                              key={index}
                                              value={data?.generalSettingsID}
                                            >
                                              {data?.categoryName}
                                            </MenuItem>
                                          );
                                        })}
                                    </Select>
                                  </Box>
                                </LocalizationProvider>
                              </Box>
                            </>
                          </AccordionDetails>
                        </Accordion>
                      </form>
                    </div>
                  );
                }}
              </Formik>
            ) : (
              <>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "end",
                        width: "100%",
                        backgroundColor: "white",
                      }}
                    >
                      <Typography
                        gutterBottom
                        variant="h5"
                        component="div"
                        className="m-0 "
                      >
                        Arbitration Fees and Expenses
                      </Typography>

                      <div>
                        <Button
                          onClick={(event) => {
                            event.stopPropagation();
                            if (window.edit) {
                              setEditArbitrationFeesAndExpenses(true);
                            } else {
                              animateCss(
                                "info",
                                "You are not authorized to update"
                              );
                            }
                          }}
                          type="button"
                          color="primary"
                        >
                          Edit
                        </Button>
                      </div>
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={0}>
                      <Grid item xs={12} sm={6} md={6}>
                        <List sx={{ bgcolor: "background.paper" }}>
                          <ListItemText
                            primary="Arbitration Fee Payment Status"
                            secondary={
                              rowData?.arbitrationFeePaymentStatus?.categoryName
                            }
                          />
                        </List>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </>
            )}

            {arbitrationClauseAgreement ? (
              <Formik
                initialValues={{
                  writtenArbitrationAgreement: !rowData?.caseID
                    ? null
                    : rowData?.WrittenArbitrationAgreement,
                  nameOfAgreementContract: !rowData?.caseID
                    ? null
                    : rowData?.NameOfAgreementContract,
                  arbitrationAgreementContractExecutedOn: !rowData?.caseID
                    ? null
                    : rowData?.ArbitrationAgreementContractExecutedOn,
                  effectiveDateOfArbitrationAgreementContract: !rowData?.caseID
                    ? null
                    : rowData?.EffectiveDateOfArbitrationAgreementContract,
                  executedByAllPartiesToAgreement: !rowData?.caseID
                    ? null
                    : rowData?.ExecutedByAllPartiesToAgreement,
                  modeOfExecutionOfArbitrationAgreement: !rowData?.caseID
                    ? null
                    : rowData?.modeOfExecutionOfArbitrationAgreement
                        ?.generalSettingsID,
                  otherModeOfExecutionOfArbitrationAgreement: !rowData?.caseID
                    ? null
                    : rowData?.OtherModeOfExecutionOfArbitrationAgreement,
                  scopeOfTheArbitrationClauseAgreement: !rowData?.caseID
                    ? null
                    : rowData?.ScopeOfTheArbitrationClauseAgreement,
                  arbitrationRulesAndInstitutionPerAgreement: !rowData?.caseID
                    ? null
                    : rowData?.ArbitrationRulesAndInstitutionPerAgreement,
                  seatOfArbitrationPerAgreement: !rowData?.caseID
                    ? null
                    : rowData?.SeatOfArbitrationPerAgreement,
                  durationOfArbitrationProceedingsPerAgreement: !rowData?.caseID
                    ? null
                    : rowData?.DurationOfArbitrationProceedingsPerAgreement,
                  languageOfArbitrationPerAgreement: !rowData?.caseID
                    ? null
                    : rowData?.LanguageOfArbitrationPerAgreement,
                  timelineForAppointingArbitratorsPerAgreement: !rowData?.caseID
                    ? null
                    : rowData?.TimelineForAppointingArbitratorsPerAgreement,
                  numberOfArbitratorsPerAgreement: !rowData?.caseID
                    ? null
                    : rowData?.NumberOfArbitratorsPerAgreement,
                  arbitratorAppointmentProcedurePerAgreement: !rowData?.caseID
                    ? null
                    : rowData?.ArbitratorAppointmentProcedurePerAgreement,
                  governingLawOfTheContractAndArbitrationPerAgreement:
                    !rowData?.caseID
                      ? null
                      : rowData?.GoverningLawOfTheContractAndArbitrationPerAgreement,
                  modeOfHearingPerAgreement: !rowData?.caseID
                    ? null
                    : rowData?.modeOfHearingPerAgreement?.generalSettingsID,
                  methodOfCommunicationPerAgreement: !rowData?.caseID
                    ? null
                    : rowData?.MethodOfCommunicationPerAgreement,
                  preArbitrationRequirements: !rowData?.caseID
                    ? null
                    : rowData?.PreArbitrationRequirements,
                  confidentialityProvisions: !rowData?.caseID
                    ? null
                    : rowData?.ConfidentialityProvisions,
                  timeLimitsForInitiatingArbitration: !rowData?.caseID
                    ? null
                    : rowData?.TimeLimitsForInitiatingArbitration,
                  costsAndFeesAllocation: !rowData?.caseID
                    ? null
                    : rowData?.CostsAndFeesAllocation,
                  interimReliefOrEmergencyArbitrationProvision: !rowData?.caseID
                    ? null
                    : rowData?.InterimReliefOrEmergencyArbitrationProvision,
                  consolidationAndJoinderProvisions: !rowData?.caseID
                    ? null
                    : rowData?.ConsolidationAndJoinderProvisions,
                  enforcementConsiderations: !rowData?.caseID
                    ? null
                    : rowData?.EnforcementConsiderations,
                  limitationOnRemedies: !rowData?.caseID
                    ? null
                    : rowData?.LimitationOnRemedies,
                  arbitrationClause: !rowData?.caseID
                    ? null
                    : rowData?.ArbitrationClause,
                  arbitrationAgreement: !rowData?.caseID
                    ? null
                    : rowData?.ArbitrationAgreement,
                  documentDelete: false,
                  formValueDelete: false,
                  params,
                  caseRowId: rowData.caseDetailsID,
                }}
                onSubmit={async (values, actions) => {
                  try {
                    const value = { values, uploadedFiles };

                    dispatch(updateArbitrationClausAndAgreementAction(value));

                    setTimeout(() => {
                      dispatch(singleCaseDetailsAction(params));
                      setArbitrationClauseAgreement(false);
                    }, 1000);
                  } catch (error) {}
                }}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  touched,
                  values,
                  setFieldValue,
                  resetForm,
                }) => {
                  return (
                    <div className="d-flex  col-12">
                      <form
                        onSubmit={handleSubmit}
                        xs={12}
                        sm={12}
                        md={12}
                        style={{ width: "100%" }}
                      >
                        <Accordion defaultExpanded>
                          <AccordionSummary
                            onClick={(event) => {
                              event.stopPropagation();
                            }}
                            expandIcon={
                              <>
                                <ExpandMoreIcon />
                              </>
                            }
                            aria-controls="panel1-content"
                            id="panel1-header"
                          >
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "end",
                                width: "100%",
                                backgroundColor: "white",
                              }}
                              xs={12}
                              sm={12}
                              md={12}
                            >
                              <Typography
                                gutterBottom
                                variant="h5"
                                component="div"
                                className="m-0 "
                              >
                                Arbitration Clause/Agreement &nbsp; &nbsp;
                                <IoMdAttach
                                  className="fs-6 mx-2 text-primary"
                                  onClick={(event) => {
                                    event.stopPropagation();
                                    setOpen(true);
                                    dispatch(
                                      allAgreementDocumentListAction({
                                        value: "Arbitration Agreement",
                                        caseID: id,
                                      })
                                    );
                                  }}
                                />
                              </Typography>

                              <div>
                                <Button
                                  type="button"
                                  color="primary"
                                  onClick={(event) => {
                                    event.stopPropagation();
                                    setArbitrationClauseAgreement(false);
                                  }}
                                >
                                  Cancel
                                </Button>
                                <Button
                                  color="primary"
                                  onClick={async () => {}}
                                  type="submit"
                                >
                                  Submit
                                </Button>
                              </div>
                            </Box>
                          </AccordionSummary>
                          <AccordionDetails>
                            <>
                              <Box
                                xs={12}
                                sm={12}
                                md={12}
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "end",
                                  backgroundColor: "white",
                                  flexWrap: "wrap",
                                }}
                              >
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <Box
                                    sx={{
                                      display: "grid",
                                      alignItems: "end",
                                      px: 2,
                                      gridTemplateColumns: {
                                        xs: "1fr",
                                        sm: "1fr",
                                        md: "1fr",
                                      },
                                      width: {
                                        xs: "100%",
                                        sm: "100%",
                                        md: "100%",
                                      },
                                    }}
                                    xs={12}
                                    sm={12}
                                    md={12}
                                  >
                                    <InputLabel
                                      htmlFor="writtenArbitrationAgreement"
                                      sx={{ textWrap: "balance" }}
                                    >
                                      Is there a Written Arbitration Agreement?
                                    </InputLabel>
                                  </Box>

                                  <Box
                                    sx={{
                                      display: "grid",
                                      alignItems: "end",
                                      px: 2,
                                      gridTemplateColumns: {
                                        xs: "1fr",
                                        sm: "1fr",
                                        md: "1fr",
                                      },
                                      width: {
                                        xs: "100%",
                                        sm: "100%",
                                        md: "100%",
                                      },
                                    }}
                                    xs={12}
                                    sm={12}
                                    md={12}
                                  >
                                    <Select
                                      labelId="demo-multiple-name-label"
                                      id="writtenArbitrationAgreement"
                                      name="writtenArbitrationAgreement"
                                      value={
                                        values?.writtenArbitrationAgreement ||
                                        ""
                                      }
                                      onBlur={handleBlur}
                                      onChange={async (e) => {
                                        if (e.target.value == "0") {
                                          enqueueSnackbar(
                                            <>
                                              <div>
                                                Written Arbitration Agreement
                                                'No'. This action will
                                                permanently delete all
                                                associated documents. You may
                                                view and download any necessary
                                                documents before proceeding. If
                                                you are sure that no agreement
                                                exists, please confirm to delete
                                                all documents. Be advised: This
                                                action is irreversible. Would
                                                you like to continue?
                                              </div>
                                            </>,
                                            {
                                              variant: "warning",
                                              onClose: () => {},
                                              anchorOrigin: {
                                                vertical: "top",
                                                horizontal: "right",
                                              },
                                              action: (key) => (
                                                <>
                                                  <StyledButton
                                                    onClick={async () => {
                                                      try {
                                                        closeSnackbar(key);
                                                        setFieldValue(
                                                          "documentDelete",
                                                          true
                                                        );
                                                      } catch (err) {}
                                                    }}
                                                  >
                                                    Confirm
                                                  </StyledButton>
                                                  <StyledButton
                                                    onClick={() => {
                                                      closeSnackbar(key);
                                                      setFieldValue(
                                                        "documentDelete",
                                                        false
                                                      );
                                                    }}
                                                  >
                                                    Cancel
                                                  </StyledButton>
                                                </>
                                              ),
                                              autoHideDuration: null,
                                              style: {
                                                backgroundColor: "#f44336",
                                                width: "600px",
                                                color: "#fff",
                                              },
                                            }
                                          );

                                          enqueueSnackbar(
                                            <>
                                              <div>
                                                Written Arbitration Agreement
                                                marked as 'No'. This will delete
                                                the Arbitration Clause/Agreement
                                                and permanently remove all
                                                related data. If you are certain
                                                that no agreement exists, please
                                                confirm to delete all associated
                                                data. Be advised: This action is
                                                irreversible. Would you like to
                                                continue?
                                              </div>
                                            </>,
                                            {
                                              variant: "warning",
                                              onClose: () => {},
                                              anchorOrigin: {
                                                vertical: "top",
                                                horizontal: "right",
                                              },
                                              action: (key) => (
                                                <>
                                                  <StyledButton
                                                    onClick={async () => {
                                                      try {
                                                        closeSnackbar(key);
                                                        setFieldValue(
                                                          "formValueDelete",
                                                          true
                                                        );
                                                      } catch (err) {}
                                                    }}
                                                  >
                                                    Confirm
                                                  </StyledButton>
                                                  <StyledButton
                                                    onClick={() => {
                                                      closeSnackbar(key);
                                                      setFieldValue(
                                                        "formValueDelete",
                                                        false
                                                      );
                                                    }}
                                                  >
                                                    Cancel
                                                  </StyledButton>
                                                </>
                                              ),
                                              autoHideDuration: null,
                                              style: {
                                                backgroundColor:
                                                  " rgb(245, 124, 0)",
                                                width: "600px",
                                                color: "#fff",
                                              },
                                            }
                                          );
                                        }
                                        handleChange(e);
                                      }}
                                      fullWidth
                                      selected={
                                        values?.writtenArbitrationAgreement
                                      }
                                    >
                                      <MenuItem value="1">Yes</MenuItem>
                                      <MenuItem value="0">No</MenuItem>
                                    </Select>
                                  </Box>
                                  {values.writtenArbitrationAgreement == 1 && (
                                    <>
                                      <Box
                                        sx={{
                                          display: "grid",
                                          alignItems: "end",
                                          px: 2,
                                          gridTemplateColumns: {
                                            xs: "1fr",
                                            sm: "1fr",
                                            md: "1fr",
                                          },
                                          width: {
                                            xs: "100%",
                                            sm: "50%",
                                            md: "50%",
                                          },
                                        }}
                                        xs={12}
                                        sm={6}
                                        md={6}
                                      >
                                        <Typography
                                          variant="body1"
                                          component="label"
                                          sx={{ textWrap: "balance" }}
                                        >
                                          Name of Agreement/Contract
                                        </Typography>

                                        <OutlinedInput
                                          id="nameOfAgreementContract"
                                          name="nameOfAgreementContract"
                                          sx={{
                                            gridTemplateColumns: {
                                              xs: "1fr",
                                              sm: "1fr",
                                            },
                                          }}
                                          value={
                                            values?.nameOfAgreementContract
                                          }
                                          onBlur={handleBlur}
                                          onChange={handleChange}
                                        />
                                      </Box>

                                      <Box
                                        sx={{
                                          display: "grid",
                                          alignItems: "end",
                                          px: 2,
                                          gridTemplateColumns: {
                                            xs: "1fr",
                                            sm: "1fr",
                                            md: "1fr",
                                          },
                                          width: {
                                            xs: "100%",
                                            sm: "50%",
                                            md: "50%",
                                          },
                                        }}
                                        xs={12}
                                        sm={6}
                                        md={6}
                                      >
                                        <InputLabel
                                          htmlFor="arbitrationAgreementContractExecutedOn"
                                          sx={{ textWrap: "balance" }}
                                        >
                                          Arbitration Agreement/Contract
                                          Executed On
                                        </InputLabel>

                                        <DatePicker
                                          id="arbitrationAgreementContractExecutedOn"
                                          selected={
                                            values?.arbitrationAgreementContractExecutedOn
                                          }
                                          onChange={(date) => {
                                            setFieldValue(
                                              "arbitrationAgreementContractExecutedOn",
                                              date
                                            );
                                          }}
                                          sx={{
                                            width: "100%",
                                            gridTemplateColumns: {
                                              xs: "1fr",
                                              sm: "1fr",
                                            },
                                          }}
                                          onBlur={handleBlur}
                                          dateFormat="dd-MM-yyyy"
                                          placeholderText="DD-MM-YYYY"
                                          className="form-control"
                                        />
                                      </Box>

                                      <Box
                                        sx={{
                                          display: "grid",
                                          alignItems: "end",
                                          px: 2,
                                          gridTemplateColumns: {
                                            xs: "1fr",
                                            sm: "1fr",
                                            md: "1fr",
                                          },
                                          width: {
                                            xs: "100%",
                                            sm: "50%",
                                            md: "50%",
                                          },
                                        }}
                                        xs={12}
                                        sm={6}
                                        md={6}
                                      >
                                        <Typography
                                          variant="body1"
                                          component="label"
                                          sx={{ textWrap: "balance" }}
                                        >
                                          Effective Date of Arbitration
                                          Agreement/Contract
                                        </Typography>

                                        <DatePicker
                                          id="effectiveDateOfArbitrationAgreementContract"
                                          selected={
                                            values?.effectiveDateOfArbitrationAgreementContract
                                          }
                                          onChange={(date) => {
                                            setFieldValue(
                                              "effectiveDateOfArbitrationAgreementContract",
                                              date
                                            );
                                          }}
                                          sx={{
                                            width: "100%",
                                            gridTemplateColumns: {
                                              xs: "1fr",
                                              sm: "1fr",
                                            },
                                          }}
                                          onBlur={handleBlur}
                                          dateFormat="dd-MM-yyyy"
                                          placeholderText="DD-MM-YYYY"
                                          className="form-control"
                                        />
                                      </Box>

                                      <Box
                                        sx={{
                                          display: "grid",
                                          alignItems: "end",
                                          px: 2,
                                          gridTemplateColumns: {
                                            xs: "1fr",
                                            sm: "1fr",
                                            md: "1fr",
                                          },
                                          width: {
                                            xs: "100%",
                                            sm: "50%",
                                            md: "50%",
                                          },
                                        }}
                                        xs={12}
                                        sm={6}
                                        md={6}
                                      >
                                        <Typography
                                          variant="body1"
                                          component="label"
                                          sx={{ textWrap: "balance" }}
                                        >
                                          Executed by All Parties to Agreement?
                                        </Typography>

                                        <Select
                                          labelId="demo-multiple-name-label"
                                          id="executedByAllPartiesToAgreement"
                                          name="executedByAllPartiesToAgreement"
                                          value={
                                            values?.executedByAllPartiesToAgreement ||
                                            ""
                                          }
                                          onBlur={handleBlur}
                                          onChange={handleChange}
                                          fullWidth
                                          selected={
                                            values?.executedByAllPartiesToAgreement
                                          }
                                        >
                                          <MenuItem value="1">Yes</MenuItem>
                                          <MenuItem value="0">No</MenuItem>
                                        </Select>
                                      </Box>

                                      <Box
                                        sx={{
                                          display: "grid",
                                          alignItems: "end",
                                          px: 2,
                                          gridTemplateColumns: {
                                            xs: "1fr",
                                            sm: "1fr",
                                            md: "1fr",
                                          },
                                          width: {
                                            xs: "100%",
                                            sm: "50%",
                                            md: "50%",
                                          },
                                        }}
                                        xs={12}
                                        sm={6}
                                        md={6}
                                      >
                                        <InputLabel
                                          htmlFor="modeOfExecutionOfArbitrationAgreement"
                                          sx={{ textWrap: "balance" }}
                                        >
                                          Mode of Execution of Arbitration
                                          Agreement
                                        </InputLabel>

                                        <Select
                                          labelId="modeOfExecutionOfArbitrationAgreement"
                                          id="modeOfExecutionOfArbitrationAgreement"
                                          name="modeOfExecutionOfArbitrationAgreement"
                                          value={
                                            values?.modeOfExecutionOfArbitrationAgreement
                                          }
                                          onBlur={handleBlur}
                                          onChange={handleChange}
                                          fullWidth // Ensures the Select is 100% width
                                        >
                                          {settings
                                            ?.find(
                                              (item) =>
                                                item?.categoryName ==
                                                "Arbitration Agreement"
                                            )
                                            ?.Children?.map((data, index) => {
                                              return (
                                                <MenuItem
                                                  key={index}
                                                  value={
                                                    data?.generalSettingsID
                                                  }
                                                >
                                                  {data?.categoryName}
                                                </MenuItem>
                                              );
                                            })}
                                        </Select>
                                      </Box>

                                      {(values?.modeOfExecutionOfArbitrationAgreement ===
                                        129 ||
                                        values
                                          ?.modeOfExecutionOfArbitrationAgreement
                                          ?.categoryName == "Other") && (
                                        <Box
                                          sx={{
                                            display: "grid",
                                            alignItems: "end",
                                            px: 2,
                                            gridTemplateColumns: {
                                              xs: "1fr",
                                              sm: "1fr",
                                              md: "1fr",
                                            },
                                            width: {
                                              xs: "100%",
                                              sm: "50%",
                                              md: "50%",
                                            },
                                          }}
                                          xs={12}
                                          sm={6}
                                          md={6}
                                        >
                                          <Typography
                                            variant="body1"
                                            component="label"
                                            sx={{ textWrap: "balance" }}
                                          >
                                            Other Mode of Execution of
                                            Arbitration Agreement
                                          </Typography>

                                          <OutlinedInput
                                            id="otherModeOfExecutionOfArbitrationAgreement"
                                            name="otherModeOfExecutionOfArbitrationAgreement"
                                            sx={{
                                              gridTemplateColumns: {
                                                xs: "1fr",
                                                sm: "1fr",
                                              },
                                            }}
                                            value={
                                              values?.otherModeOfExecutionOfArbitrationAgreement
                                            }
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                          />
                                        </Box>
                                      )}

                                      <Box
                                        sx={{
                                          display: "grid",
                                          alignItems: "end",
                                          px: 2,
                                          gridTemplateColumns: {
                                            xs: "1fr",
                                            sm: "1fr",
                                            md: "1fr",
                                          },
                                          width: {
                                            xs: "100%",
                                            sm: "50%",
                                            md: "50%",
                                          },
                                        }}
                                        xs={12}
                                        sm={6}
                                        md={6}
                                      >
                                        <Typography
                                          variant="body1"
                                          component="label"
                                          sx={{ textWrap: "balance" }}
                                        >
                                          Scope of the Arbitration
                                          Clause/Agreement
                                        </Typography>

                                        <OutlinedInput
                                          id="scopeOfTheArbitrationClauseAgreement"
                                          name="scopeOfTheArbitrationClauseAgreement"
                                          sx={{
                                            gridTemplateColumns: {
                                              xs: "1fr",
                                              sm: "1fr",
                                            },
                                          }}
                                          value={
                                            values?.scopeOfTheArbitrationClauseAgreement
                                          }
                                          onBlur={handleBlur}
                                          onChange={handleChange}
                                        />
                                      </Box>

                                      <Box
                                        sx={{
                                          display: "grid",
                                          alignItems: "end",
                                          px: 2,
                                          gridTemplateColumns: {
                                            xs: "1fr",
                                            sm: "1fr",
                                            md: "1fr",
                                          },
                                          width: {
                                            xs: "100%",
                                            sm: "50%",
                                            md: "50%",
                                          },
                                        }}
                                        xs={12}
                                        sm={6}
                                        md={6}
                                      >
                                        <InputLabel
                                          htmlFor="arbitrationRulesAndInstitutionPerAgreement"
                                          sx={{ textWrap: "balance" }}
                                        >
                                          Arbitration Rules and Institution per
                                          Agreement
                                        </InputLabel>

                                        <OutlinedInput
                                          id="arbitrationRulesAndInstitutionPerAgreement"
                                          name="arbitrationRulesAndInstitutionPerAgreement"
                                          sx={{
                                            gridTemplateColumns: {
                                              xs: "1fr",
                                              sm: "1fr",
                                            },
                                          }}
                                          value={
                                            values?.arbitrationRulesAndInstitutionPerAgreement
                                          }
                                          onBlur={handleBlur}
                                          onChange={handleChange}
                                        />
                                      </Box>

                                      <Box
                                        sx={{
                                          display: "grid",
                                          alignItems: "end",
                                          px: 2,
                                          gridTemplateColumns: {
                                            xs: "1fr",
                                            sm: "1fr",
                                            md: "1fr",
                                          },
                                          width: {
                                            xs: "100%",
                                            sm: "50%",
                                            md: "50%",
                                          },
                                        }}
                                        xs={12}
                                        sm={6}
                                        md={6}
                                      >
                                        <Typography
                                          variant="body1"
                                          component="label"
                                          sx={{ textWrap: "balance" }}
                                        >
                                          Seat (Place) of Arbitration per
                                          Agreement
                                        </Typography>

                                        <OutlinedInput
                                          id="seatOfArbitrationPerAgreement"
                                          name="seatOfArbitrationPerAgreement"
                                          sx={{
                                            gridTemplateColumns: {
                                              xs: "1fr",
                                              sm: "1fr",
                                            },
                                          }}
                                          value={
                                            values?.seatOfArbitrationPerAgreement
                                          }
                                          onBlur={handleBlur}
                                          onChange={handleChange}
                                        />
                                      </Box>

                                      <Box
                                        sx={{
                                          display: "grid",
                                          alignItems: "end",
                                          px: 2,
                                          gridTemplateColumns: {
                                            xs: "1fr",
                                            sm: "1fr",
                                            md: "1fr",
                                          },
                                          width: {
                                            xs: "100%",
                                            sm: "50%",
                                            md: "50%",
                                          },
                                        }}
                                        xs={12}
                                        sm={6}
                                        md={6}
                                      >
                                        <InputLabel
                                          htmlFor="durationOfArbitrationProceedingsPerAgreement"
                                          sx={{ textWrap: "balance" }}
                                        >
                                          Duration of Arbitration Proceedings
                                          per Agreement
                                        </InputLabel>

                                        <OutlinedInput
                                          id="durationOfArbitrationProceedingsPerAgreement"
                                          name="durationOfArbitrationProceedingsPerAgreement"
                                          sx={{
                                            gridTemplateColumns: {
                                              xs: "1fr",
                                              sm: "1fr",
                                            },
                                          }}
                                          value={
                                            values?.durationOfArbitrationProceedingsPerAgreement
                                          }
                                          onBlur={handleBlur}
                                          onChange={handleChange}
                                        />
                                      </Box>

                                      <Box
                                        sx={{
                                          display: "grid",
                                          alignItems: "end",
                                          px: 2,
                                          gridTemplateColumns: {
                                            xs: "1fr",
                                            sm: "1fr",
                                            md: "1fr",
                                          },
                                          width: {
                                            xs: "100%",
                                            sm: "50%",
                                            md: "50%",
                                          },
                                        }}
                                        xs={12}
                                        sm={6}
                                        md={6}
                                      >
                                        <Typography
                                          variant="body1"
                                          component="label"
                                          sx={{ textWrap: "balance" }}
                                        >
                                          Language of Arbitration per Agreement
                                        </Typography>

                                        <OutlinedInput
                                          id="languageOfArbitrationPerAgreement"
                                          name="languageOfArbitrationPerAgreement"
                                          sx={{
                                            gridTemplateColumns: {
                                              xs: "1fr",
                                              sm: "1fr",
                                            },
                                          }}
                                          value={
                                            values?.languageOfArbitrationPerAgreement
                                          }
                                          onBlur={handleBlur}
                                          onChange={handleChange}
                                        />
                                      </Box>

                                      <Box
                                        sx={{
                                          display: "grid",
                                          alignItems: "end",
                                          px: 2,
                                          gridTemplateColumns: {
                                            xs: "1fr",
                                            sm: "1fr",
                                            md: "1fr",
                                          },
                                          width: {
                                            xs: "100%",
                                            sm: "50%",
                                            md: "50%",
                                          },
                                        }}
                                        xs={12}
                                        sm={6}
                                        md={6}
                                      >
                                        <InputLabel
                                          htmlFor="timelineForAppointingArbitratorsPerAgreement"
                                          sx={{ textWrap: "balance" }}
                                        >
                                          Timeline for Appointing Arbitrators
                                          per Agreement
                                        </InputLabel>

                                        <OutlinedInput
                                          id="timelineForAppointingArbitratorsPerAgreement"
                                          name="timelineForAppointingArbitratorsPerAgreement"
                                          sx={{
                                            gridTemplateColumns: {
                                              xs: "1fr",
                                              sm: "1fr",
                                            },
                                          }}
                                          value={
                                            values?.timelineForAppointingArbitratorsPerAgreement
                                          }
                                          onBlur={handleBlur}
                                          onChange={handleChange}
                                        />
                                      </Box>

                                      <Box
                                        sx={{
                                          display: "grid",
                                          alignItems: "end",
                                          px: 2,
                                          gridTemplateColumns: {
                                            xs: "1fr",
                                            sm: "1fr",
                                            md: "1fr",
                                          },
                                          width: {
                                            xs: "100%",
                                            sm: "50%",
                                            md: "50%",
                                          },
                                        }}
                                        xs={12}
                                        sm={6}
                                        md={6}
                                      >
                                        <Typography
                                          variant="body1"
                                          component="label"
                                          sx={{ textWrap: "balance" }}
                                        >
                                          Number of Arbitrators per Agreement
                                        </Typography>

                                        <OutlinedInput
                                          id="numberOfArbitratorsPerAgreement"
                                          name="numberOfArbitratorsPerAgreement"
                                          sx={{
                                            gridTemplateColumns: {
                                              xs: "1fr",
                                              sm: "1fr",
                                            },
                                          }}
                                          value={
                                            values?.numberOfArbitratorsPerAgreement
                                          }
                                          onBlur={handleBlur}
                                          onChange={handleChange}
                                        />
                                      </Box>

                                      <Box
                                        sx={{
                                          display: "grid",
                                          alignItems: "end",
                                          px: 2,
                                          gridTemplateColumns: {
                                            xs: "1fr",
                                            sm: "1fr",
                                            md: "1fr",
                                          },
                                          width: {
                                            xs: "100%",
                                            sm: "50%",
                                            md: "50%",
                                          },
                                        }}
                                        xs={12}
                                        sm={6}
                                        md={6}
                                      >
                                        <InputLabel
                                          htmlFor="arbitratorAppointmentProcedurePerAgreement"
                                          sx={{ textWrap: "balance" }}
                                        >
                                          Arbitrator Appointment Procedure per
                                          Agreement
                                        </InputLabel>

                                        <OutlinedInput
                                          id="arbitratorAppointmentProcedurePerAgreement"
                                          name="arbitratorAppointmentProcedurePerAgreement"
                                          sx={{
                                            gridTemplateColumns: {
                                              xs: "1fr",
                                              sm: "1fr",
                                            },
                                          }}
                                          value={
                                            values?.arbitratorAppointmentProcedurePerAgreement
                                          }
                                          onBlur={handleBlur}
                                          onChange={handleChange}
                                        />
                                      </Box>

                                      <Box
                                        sx={{
                                          display: "grid",
                                          alignItems: "end",
                                          px: 2,
                                          gridTemplateColumns: {
                                            xs: "1fr",
                                            sm: "1fr",
                                            md: "1fr",
                                          },
                                          width: {
                                            xs: "100%",
                                            sm: "50%",
                                            md: "50%",
                                          },
                                        }}
                                        xs={12}
                                        sm={6}
                                        md={6}
                                      >
                                        <Typography
                                          variant="body1"
                                          component="label"
                                          sx={{ textWrap: "balance" }}
                                        >
                                          Governing Law of the Contract and
                                          Arbitration per Agreement
                                        </Typography>

                                        <OutlinedInput
                                          id="governingLawOfTheContractAndArbitrationPerAgreement"
                                          name="governingLawOfTheContractAndArbitrationPerAgreement"
                                          sx={{
                                            gridTemplateColumns: {
                                              xs: "1fr",
                                              sm: "1fr",
                                            },
                                          }}
                                          value={
                                            values?.governingLawOfTheContractAndArbitrationPerAgreement
                                          }
                                          onBlur={handleBlur}
                                          onChange={handleChange}
                                        />
                                      </Box>

                                      <Box
                                        sx={{
                                          display: "grid",
                                          alignItems: "end",
                                          px: 2,
                                          gridTemplateColumns: {
                                            xs: "1fr",
                                            sm: "1fr",
                                            md: "1fr",
                                          },
                                          width: {
                                            xs: "100%",
                                            sm: "50%",
                                            md: "50%",
                                          },
                                        }}
                                        xs={12}
                                        sm={6}
                                        md={6}
                                      >
                                        <InputLabel
                                          htmlFor="modeOfHearingPerAgreement"
                                          sx={{ textWrap: "balance" }}
                                        >
                                          Mode of Hearing per Agreement
                                        </InputLabel>

                                        <Select
                                          labelId="modeOfHearingPerAgreement"
                                          id="modeOfHearingPerAgreement"
                                          name="modeOfHearingPerAgreement"
                                          value={
                                            values?.modeOfHearingPerAgreement
                                          }
                                          onBlur={handleBlur}
                                          onChange={handleChange}
                                          fullWidth
                                        >
                                          {settings
                                            ?.find(
                                              (item) =>
                                                item?.categoryName ==
                                                "Mode Of Hearing Per Agreement"
                                            )
                                            ?.Children?.map((data, index) => {
                                              return (
                                                <MenuItem
                                                  key={index}
                                                  value={
                                                    data?.generalSettingsID
                                                  }
                                                >
                                                  {data?.categoryName}
                                                </MenuItem>
                                              );
                                            })}
                                        </Select>
                                      </Box>

                                      <Box
                                        sx={{
                                          display: "grid",
                                          alignItems: "end",
                                          px: 2,
                                          gridTemplateColumns: {
                                            xs: "1fr",
                                            sm: "1fr",
                                            md: "1fr",
                                          },
                                          width: {
                                            xs: "100%",
                                            sm: "50%",
                                            md: "50%",
                                          },
                                        }}
                                        xs={12}
                                        sm={6}
                                        md={6}
                                      >
                                        <Typography
                                          variant="body1"
                                          component="label"
                                          sx={{ textWrap: "balance" }}
                                        >
                                          Method of Communication per Agreement
                                        </Typography>

                                        <OutlinedInput
                                          id="methodOfCommunicationPerAgreement"
                                          name="methodOfCommunicationPerAgreement"
                                          sx={{
                                            gridTemplateColumns: {
                                              xs: "1fr",
                                              sm: "1fr",
                                            },
                                          }}
                                          value={
                                            values?.methodOfCommunicationPerAgreement
                                          }
                                          onBlur={handleBlur}
                                          onChange={handleChange}
                                        />
                                      </Box>

                                      <Box
                                        sx={{
                                          display: "grid",
                                          alignItems: "end",
                                          px: 2,
                                          gridTemplateColumns: {
                                            xs: "1fr",
                                            sm: "1fr",
                                            md: "1fr",
                                          },
                                          width: {
                                            xs: "100%",
                                            sm: "50%",
                                            md: "50%",
                                          },
                                        }}
                                        xs={12}
                                        sm={6}
                                        md={6}
                                      >
                                        <InputLabel
                                          htmlFor="preArbitrationRequirements"
                                          sx={{ textWrap: "balance" }}
                                        >
                                          Pre-Arbitration Requirements
                                        </InputLabel>

                                        <OutlinedInput
                                          id="preArbitrationRequirements"
                                          name="preArbitrationRequirements"
                                          sx={{
                                            gridTemplateColumns: {
                                              xs: "1fr",
                                              sm: "1fr",
                                            },
                                          }}
                                          value={
                                            values?.preArbitrationRequirements
                                          }
                                          onBlur={handleBlur}
                                          onChange={handleChange}
                                        />
                                      </Box>

                                      <Box
                                        sx={{
                                          display: "grid",
                                          alignItems: "end",
                                          px: 2,
                                          gridTemplateColumns: {
                                            xs: "1fr",
                                            sm: "1fr",
                                            md: "1fr",
                                          },
                                          width: {
                                            xs: "100%",
                                            sm: "50%",
                                            md: "50%",
                                          },
                                        }}
                                        xs={12}
                                        sm={6}
                                        md={6}
                                      >
                                        <Typography
                                          variant="body1"
                                          component="label"
                                          sx={{ textWrap: "balance" }}
                                        >
                                          Confidentiality Provisions
                                        </Typography>

                                        <OutlinedInput
                                          id="confidentialityProvisions"
                                          name="confidentialityProvisions"
                                          sx={{
                                            gridTemplateColumns: {
                                              xs: "1fr",
                                              sm: "1fr",
                                            },
                                          }}
                                          value={
                                            values?.confidentialityProvisions
                                          }
                                          onBlur={handleBlur}
                                          onChange={handleChange}
                                        />
                                      </Box>

                                      <Box
                                        sx={{
                                          display: "grid",
                                          alignItems: "end",
                                          px: 2,
                                          gridTemplateColumns: {
                                            xs: "1fr",
                                            sm: "1fr",
                                            md: "1fr",
                                          },
                                          width: {
                                            xs: "100%",
                                            sm: "50%",
                                            md: "50%",
                                          },
                                        }}
                                        xs={12}
                                        sm={6}
                                        md={6}
                                      >
                                        <InputLabel
                                          htmlFor="enforcementStatus"
                                          sx={{ textWrap: "balance" }}
                                        >
                                          Time Limits for Initiating Arbitration
                                        </InputLabel>

                                        <OutlinedInput
                                          id="timeLimitsForInitiatingArbitration"
                                          name="timeLimitsForInitiatingArbitration"
                                          sx={{
                                            gridTemplateColumns: {
                                              xs: "1fr",
                                              sm: "1fr",
                                            },
                                          }}
                                          value={
                                            values?.timeLimitsForInitiatingArbitration
                                          }
                                          onBlur={handleBlur}
                                          onChange={handleChange}
                                        />
                                      </Box>

                                      <Box
                                        sx={{
                                          display: "grid",
                                          alignItems: "end",
                                          px: 2,
                                          gridTemplateColumns: {
                                            xs: "1fr",
                                            sm: "1fr",
                                            md: "1fr",
                                          },
                                          width: {
                                            xs: "100%",
                                            sm: "50%",
                                            md: "50%",
                                          },
                                        }}
                                        xs={12}
                                        sm={6}
                                        md={6}
                                      >
                                        <Typography
                                          variant="body1"
                                          component="label"
                                          sx={{ textWrap: "balance" }}
                                        >
                                          Costs and Fees Allocation
                                        </Typography>

                                        <OutlinedInput
                                          id="costsAndFeesAllocation"
                                          name="costsAndFeesAllocation"
                                          sx={{
                                            gridTemplateColumns: {
                                              xs: "1fr",
                                              sm: "1fr",
                                            },
                                          }}
                                          value={values?.costsAndFeesAllocation}
                                          onBlur={handleBlur}
                                          onChange={handleChange}
                                        />
                                      </Box>

                                      <Box
                                        sx={{
                                          display: "grid",
                                          alignItems: "end",
                                          px: 2,
                                          gridTemplateColumns: {
                                            xs: "1fr",
                                            sm: "1fr",
                                            md: "1fr",
                                          },
                                          width: {
                                            xs: "100%",
                                            sm: "50%",
                                            md: "50%",
                                          },
                                        }}
                                        xs={12}
                                        sm={6}
                                        md={6}
                                      >
                                        <InputLabel
                                          htmlFor="interimReliefOrEmergencyArbitrationProvision"
                                          sx={{ textWrap: "balance" }}
                                        >
                                          Interim Relief or Emergency
                                          Arbitration provision
                                        </InputLabel>

                                        <OutlinedInput
                                          id="interimReliefOrEmergencyArbitrationProvision"
                                          name="interimReliefOrEmergencyArbitrationProvision"
                                          sx={{
                                            gridTemplateColumns: {
                                              xs: "1fr",
                                              sm: "1fr",
                                            },
                                          }}
                                          value={
                                            values?.interimReliefOrEmergencyArbitrationProvision
                                          }
                                          onBlur={handleBlur}
                                          onChange={handleChange}
                                        />
                                      </Box>

                                      <Box
                                        sx={{
                                          display: "grid",
                                          alignItems: "end",
                                          px: 2,
                                          gridTemplateColumns: {
                                            xs: "1fr",
                                            sm: "1fr",
                                            md: "1fr",
                                          },
                                          width: {
                                            xs: "100%",
                                            sm: "50%",
                                            md: "50%",
                                          },
                                        }}
                                        xs={12}
                                        sm={6}
                                        md={6}
                                      >
                                        <Typography
                                          variant="body1"
                                          component="label"
                                          sx={{ textWrap: "balance" }}
                                        >
                                          Consolidation and Joinder Provisions
                                        </Typography>

                                        <OutlinedInput
                                          id="consolidationAndJoinderProvisions"
                                          name="consolidationAndJoinderProvisions"
                                          sx={{
                                            gridTemplateColumns: {
                                              xs: "1fr",
                                              sm: "1fr",
                                            },
                                          }}
                                          value={
                                            values?.consolidationAndJoinderProvisions
                                          }
                                          onBlur={handleBlur}
                                          onChange={handleChange}
                                        />
                                      </Box>

                                      <Box
                                        sx={{
                                          display: "grid",
                                          alignItems: "end",
                                          px: 2,
                                          gridTemplateColumns: {
                                            xs: "1fr",
                                            sm: "1fr",
                                            md: "1fr",
                                          },
                                          width: {
                                            xs: "100%",
                                            sm: "50%",
                                            md: "50%",
                                          },
                                        }}
                                        xs={12}
                                        sm={6}
                                        md={6}
                                      >
                                        <InputLabel
                                          htmlFor="enforcementConsiderations"
                                          sx={{ textWrap: "balance" }}
                                        >
                                          Enforcement Considerations
                                        </InputLabel>

                                        <OutlinedInput
                                          id="enforcementConsiderations"
                                          name="enforcementConsiderations"
                                          sx={{
                                            gridTemplateColumns: {
                                              xs: "1fr",
                                              sm: "1fr",
                                            },
                                          }}
                                          value={
                                            values?.enforcementConsiderations
                                          }
                                          onBlur={handleBlur}
                                          onChange={handleChange}
                                        />
                                      </Box>

                                      <Box
                                        sx={{
                                          display: "grid",
                                          alignItems: "end",
                                          px: 2,
                                          gridTemplateColumns: {
                                            xs: "1fr",
                                            sm: "1fr",
                                            md: "1fr",
                                          },
                                          width: {
                                            xs: "100%",
                                            sm: "50%",
                                            md: "50%",
                                          },
                                        }}
                                        xs={12}
                                        sm={6}
                                        md={6}
                                      >
                                        <Typography
                                          variant="body1"
                                          component="label"
                                          sx={{ textWrap: "balance" }}
                                        >
                                          Limitation on Remedies
                                        </Typography>

                                        <OutlinedInput
                                          id="limitationOnRemedies"
                                          name="limitationOnRemedies"
                                          sx={{
                                            gridTemplateColumns: {
                                              xs: "1fr",
                                              sm: "1fr",
                                            },
                                          }}
                                          value={values?.limitationOnRemedies}
                                          onBlur={handleBlur}
                                          onChange={handleChange}
                                        />
                                      </Box>

                                      <Box
                                        sx={{
                                          display: "grid",
                                          alignItems: "end",
                                          px: 2,
                                          gridTemplateColumns: {
                                            xs: "1fr",
                                            sm: "1fr",
                                            md: "1fr",
                                          },
                                          width: {
                                            xs: "100%",
                                            sm: "50%",
                                            md: "50%",
                                          },
                                        }}
                                        xs={12}
                                        sm={6}
                                        md={6}
                                      >
                                        <InputLabel
                                          htmlFor="arbitrationClause"
                                          sx={{ textWrap: "balance" }}
                                        >
                                          Arbitration Clause
                                        </InputLabel>

                                        <OutlinedInput
                                          id="arbitrationClause"
                                          name="arbitrationClause"
                                          sx={{
                                            gridTemplateColumns: {
                                              xs: "1fr",
                                              sm: "1fr",
                                            },
                                          }}
                                          value={values?.arbitrationClause}
                                          onBlur={handleBlur}
                                          onChange={handleChange}
                                        />
                                      </Box>

                                      <Box
                                        sx={{
                                          display: "grid",
                                          alignItems: "end",
                                          px: 2,
                                          gridTemplateColumns: {
                                            xs: "1fr",
                                            sm: "1fr",
                                            md: "1fr",
                                          },
                                          width: {
                                            xs: "100%",
                                            sm: "50%",
                                            md: "50%",
                                          },
                                        }}
                                        xs={12}
                                        sm={6}
                                        md={6}
                                      >
                                        <InputLabel
                                          htmlFor="arbitrationAgreement"
                                          sx={{ textWrap: "balance" }}
                                        >
                                          Arbitration Agreement
                                        </InputLabel>

                                        <Stack
                                          className="d-grid align-items-center col-12 justify-content-start"
                                          spacing={2}
                                        >
                                          <Button
                                            component="label"
                                            variant="outlined"
                                            color="primary"
                                            multiple
                                            type="file"
                                            accept="application/pdf"
                                            onChange={handleFileEvent}
                                            startIcon={
                                              <SvgIcon>
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  fill="none"
                                                  viewBox="0 0 24 24"
                                                  strokeWidth={1.5}
                                                  stroke="currentColor"
                                                >
                                                  <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    d="M12 16.5V9.75m0 0l3 3m-3-3l-3 3M6.75 19.5a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z"
                                                  />
                                                </svg>
                                              </SvgIcon>
                                            }
                                          >
                                            Upload PDF
                                            <VisuallyHiddenInput
                                              type="file"
                                              multiple
                                              accept="application/pdf"
                                            />
                                          </Button>
                                        </Stack>
                                      </Box>
                                    </>
                                  )}
                                </LocalizationProvider>
                                {uploadedFiles[0] && (
                                  <>
                                    <Paper component="ul">
                                      {uploadedFiles.map((data, index) => {
                                        let icon;

                                        icon = (
                                          <PictureAsPdfIcon
                                            sx={{
                                              bgcolor: "white",
                                              color: deepOrange[500],
                                            }}
                                          />
                                        );

                                        return (
                                          <ListItem key={index}>
                                            <Chip
                                              variant="outlined"
                                              color="secondary"
                                              avatar={
                                                <Avatar variant="square">
                                                  {icon}
                                                </Avatar>
                                              }
                                              label={data.name}
                                              onDelete={handleDelete(data)}
                                            />
                                          </ListItem>
                                        );
                                      })}
                                    </Paper>
                                  </>
                                )}
                              </Box>
                            </>
                          </AccordionDetails>
                        </Accordion>
                      </form>
                    </div>
                  );
                }}
              </Formik>
            ) : (
              <>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "end",
                        width: "100%",
                        backgroundColor: "white",
                      }}
                    >
                      <Typography
                        gutterBottom
                        variant="h5"
                        component="div"
                        className="m-0 "
                      >
                        Arbitration Clause/Agreement &nbsp; &nbsp;
                        <IoMdAttach
                          className="fs-6 mx-2 text-primary"
                          onClick={(event) => {
                            event.stopPropagation();
                            setOpen(true);
                            dispatch(
                              allAgreementDocumentListAction({
                                value: "Arbitration Agreement",
                                caseID: id,
                              })
                            );
                          }}
                        />
                      </Typography>

                      <div>
                        <Button
                          onClick={(event) => {
                            event.stopPropagation();
                            if (window.edit) {
                              setArbitrationClauseAgreement(true);
                            } else {
                              animateCss(
                                "info",
                                "You are not authorized to update"
                              );
                            }
                          }}
                          type="button"
                          color="primary"
                        >
                          Edit
                        </Button>
                      </div>
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={0}>
                      <Grid item xs={12} sm={6} md={6}>
                        <List sx={{ bgcolor: "background.paper" }}>
                          <ListItemText
                            primary="Is there a Written Arbitration Agreement?"
                            secondary={
                              rowData?.WrittenArbitrationAgreement == 1
                                ? "Yes"
                                : "No"
                            }
                          />
                        </List>
                      </Grid>
                      {rowData?.WrittenArbitrationAgreement == 1 && (
                        <>
                          <Grid item xs={12} sm={6} md={6}>
                            <List sx={{ bgcolor: "background.paper" }}>
                              <ListItemText
                                primary="Name of Agreement/Contract"
                                secondary={rowData?.NameOfAgreementContract}
                              />
                            </List>
                          </Grid>
                          <Grid item xs={12} sm={6} md={6}>
                            <List sx={{ bgcolor: "background.paper" }}>
                              <ListItemText
                                primary="Arbitration Agreement/Contract Executed On"
                                secondary={
                                  rowData?.ArbitrationAgreementContractExecutedOn
                                    ? trackerFormatDate(
                                        new Date(
                                          rowData?.ArbitrationAgreementContractExecutedOn
                                        )
                                      )
                                    : "DD-MM-YYYY"
                                }
                              />
                            </List>
                          </Grid>
                          <Grid item xs={12} sm={6} md={6}>
                            <List sx={{ bgcolor: "background.paper" }}>
                              <ListItemText
                                primary="Effective Date of Arbitration Agreement/Contract"
                                secondary={
                                  rowData?.EffectiveDateOfArbitrationAgreementContract
                                    ? trackerFormatDate(
                                        new Date(
                                          rowData?.EffectiveDateOfArbitrationAgreementContract
                                        )
                                      )
                                    : "DD-MM-YYYY"
                                }
                              />
                            </List>
                          </Grid>

                          <Grid item xs={12} sm={6} md={6}>
                            <List sx={{ bgcolor: "background.paper" }}>
                              <ListItemText
                                primary="Executed by All Parties to Agreement?"
                                secondary={
                                  rowData?.executedByAllPartiesToAgreement == 1
                                    ? "Yes"
                                    : "No"
                                }
                              />
                            </List>
                          </Grid>

                          <Grid item xs={12} sm={6} md={6}>
                            <List sx={{ bgcolor: "background.paper" }}>
                              <ListItemText
                                primary="Mode of Execution of Arbitration Agreement"
                                secondary={
                                  rowData?.modeOfExecutionOfArbitrationAgreement
                                    ?.categoryName
                                }
                              />
                            </List>
                          </Grid>

                          {rowData?.modeOfExecutionOfArbitrationAgreement
                            ?.categoryName == "Other" && (
                            <Grid item xs={12} sm={6} md={6}>
                              <List sx={{ bgcolor: "background.paper" }}>
                                <ListItemText
                                  primary="Other Mode of Execution of Arbitration Agreement"
                                  secondary={
                                    rowData?.OtherModeOfExecutionOfArbitrationAgreement
                                  }
                                />
                              </List>
                            </Grid>
                          )}

                          <Grid item xs={12} sm={6} md={6}>
                            <List sx={{ bgcolor: "background.paper" }}>
                              <ListItemText
                                primary="Scope of the Arbitration Clause/Agreement"
                                secondary={
                                  rowData?.ScopeOfTheArbitrationClauseAgreement
                                }
                              />
                            </List>
                          </Grid>

                          <Grid item xs={12} sm={6} md={6}>
                            <List sx={{ bgcolor: "background.paper" }}>
                              <ListItemText
                                primary="Arbitration Rules and Institution per Agreement"
                                secondary={
                                  rowData?.ArbitrationRulesAndInstitutionPerAgreement
                                }
                              />
                            </List>
                          </Grid>

                          <Grid item xs={12} sm={6} md={6}>
                            <List sx={{ bgcolor: "background.paper" }}>
                              <ListItemText
                                primary="Seat (Place) of Arbitration per Agreement"
                                secondary={
                                  rowData?.SeatOfArbitrationPerAgreement
                                }
                              />
                            </List>
                          </Grid>

                          <Grid item xs={12} sm={6} md={6}>
                            <List sx={{ bgcolor: "background.paper" }}>
                              <ListItemText
                                primary="Duration of Arbitration Proceedings per Agreement"
                                secondary={
                                  rowData?.DurationOfArbitrationProceedingsPerAgreement
                                }
                              />
                            </List>
                          </Grid>

                          <Grid item xs={12} sm={6} md={6}>
                            <List sx={{ bgcolor: "background.paper" }}>
                              <ListItemText
                                primary="Language of Arbitration per Agreement"
                                secondary={
                                  rowData?.LanguageOfArbitrationPerAgreement
                                }
                              />
                            </List>
                          </Grid>
                          <Grid item xs={12} sm={6} md={6}>
                            <List sx={{ bgcolor: "background.paper" }}>
                              <ListItemText
                                primary="Timeline for Appointing Arbitrators per Agreement"
                                secondary={
                                  rowData?.TimelineForAppointingArbitratorsPerAgreement
                                }
                              />
                            </List>
                          </Grid>
                          <Grid item xs={12} sm={6} md={6}>
                            <List sx={{ bgcolor: "background.paper" }}>
                              <ListItemText
                                primary="Number of Arbitrators per Agreement"
                                secondary={
                                  rowData?.NumberOfArbitratorsPerAgreement
                                }
                              />
                            </List>
                          </Grid>
                          <Grid item xs={12} sm={6} md={6}>
                            <List sx={{ bgcolor: "background.paper" }}>
                              <ListItemText
                                primary="Arbitrator Appointment Procedure per Agreement"
                                secondary={
                                  rowData?.ArbitratorAppointmentProcedurePerAgreement
                                }
                              />
                            </List>
                          </Grid>
                          <Grid item xs={12} sm={6} md={6}>
                            <List sx={{ bgcolor: "background.paper" }}>
                              <ListItemText
                                primary="Governing Law of the Contract and Arbitration per Agreement"
                                secondary={
                                  rowData?.GoverningLawOfTheContractAndArbitrationPerAgreement
                                }
                              />
                            </List>
                          </Grid>
                          <Grid item xs={12} sm={6} md={6}>
                            <List sx={{ bgcolor: "background.paper" }}>
                              <ListItemText
                                primary="Mode of Hearing per Agreement"
                                secondary={
                                  rowData?.modeOfHearingPerAgreement
                                    ?.categoryName
                                }
                              />
                            </List>
                          </Grid>
                          <Grid item xs={12} sm={6} md={6}>
                            <List sx={{ bgcolor: "background.paper" }}>
                              <ListItemText
                                primary="Method of Communication per Agreement"
                                secondary={
                                  rowData?.MethodOfCommunicationPerAgreement
                                }
                              />
                            </List>
                          </Grid>
                          <Grid item xs={12} sm={6} md={6}>
                            <List sx={{ bgcolor: "background.paper" }}>
                              <ListItemText
                                primary="Pre-Arbitration Requirements"
                                secondary={rowData?.PreArbitrationRequirements}
                              />
                            </List>
                          </Grid>
                          <Grid item xs={12} sm={6} md={6}>
                            <List sx={{ bgcolor: "background.paper" }}>
                              <ListItemText
                                primary="Confidentiality Provisions"
                                secondary={rowData?.ConfidentialityProvisions}
                              />
                            </List>
                          </Grid>

                          <Grid item xs={12} sm={6} md={6}>
                            <List sx={{ bgcolor: "background.paper" }}>
                              <ListItemText
                                primary="Time Limits for Initiating Arbitration"
                                secondary={
                                  rowData?.TimeLimitsForInitiatingArbitration
                                }
                              />
                            </List>
                          </Grid>

                          <Grid item xs={12} sm={6} md={6}>
                            <List sx={{ bgcolor: "background.paper" }}>
                              <ListItemText
                                primary="Costs and Fees Allocation"
                                secondary={rowData?.CostsAndFeesAllocation}
                              />
                            </List>
                          </Grid>
                          <Grid item xs={12} sm={6} md={6}>
                            <List sx={{ bgcolor: "background.paper" }}>
                              <ListItemText
                                primary="Interim Relief or Emergency Arbitration provision"
                                secondary={
                                  rowData?.InterimReliefOrEmergencyArbitrationProvision
                                }
                              />
                            </List>
                          </Grid>
                          <Grid item xs={12} sm={6} md={6}>
                            <List sx={{ bgcolor: "background.paper" }}>
                              <ListItemText
                                primary="Consolidation and Joinder Provisions"
                                secondary={
                                  rowData?.ConsolidationAndJoinderProvisions
                                }
                              />
                            </List>
                          </Grid>
                          <Grid item xs={12} sm={6} md={6}>
                            <List sx={{ bgcolor: "background.paper" }}>
                              <ListItemText
                                primary="Enforcement Considerations"
                                secondary={rowData?.EnforcementConsiderations}
                              />
                            </List>
                          </Grid>
                          <Grid item xs={12} sm={6} md={6}>
                            <List sx={{ bgcolor: "background.paper" }}>
                              <ListItemText
                                primary="Limitation on Remedies"
                                secondary={rowData?.LimitationOnRemedies}
                              />
                            </List>
                          </Grid>
                          <Grid item xs={12} sm={6} md={6}>
                            <List sx={{ bgcolor: "background.paper" }}>
                              <ListItemText
                                primary="Arbitration Clause"
                                secondary={rowData?.ArbitrationClause}
                              />
                            </List>
                          </Grid>

                          {/* <Grid item xs={12} sm={6} md={6}>
                            <List sx={{ bgcolor: "background.paper" }}>
                              <ListItemText
                                primary="Arbitration Agreement Document"
                                //secondary={rowData?.ArbitrationAgreement}
                              />
                              <IconButton
                                color="error"
                                onClick={() => {
                                  setOpen(true);
                                  dispatch(
                                    allAgreementDocumentListAction({
                                      value: "Arbitration Agreement",
                                      caseID: id,
                                    })
                                  );
                                }}
                              >
                                <IoDocumentAttach />
                              </IconButton>
                            </List>
                          </Grid> */}
                        </>
                      )}
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </>
            )}
          </Grid>
        </Grid>
      </Card>
    </>
  );
};

export default ArbitrationProceedings;
