import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  Grid,
  InputLabel,
  List,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Formik } from "formik";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import DatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import {
  singleCaseDetailsAction,
  updateArbitrationNoticesKeyDatesAction,
} from "../../../redux/actions/Case/caseAction";
import { useParams } from "react-router-dom";
import { useState } from "react";
import { animateCss } from "../../TosterAlert";

const ArbitrationNoticesKeyDates = () => {
  const [editArbitrationNoticesKeyDates, setEditArbitrationNoticesKeyDates] =
    useState(false);
  const rowData = useSelector((state) => state?.case?.editCaseDetails);
  const editCaseTracker = false;

  const useStyles = makeStyles((theme) => ({
    textarea: {
      width: "100%",
      padding: theme.spacing(2),
      marginTop: "5%",
      fontSize: "1rem",
      borderRadius: "4px",
      border: "1px solid #ccc",
      resize: "vertical",
      minHeight: "100px",
    },
  }));

  const classes = useStyles();
  const dispatch = useDispatch();

  function trackerFormatDate(date) {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return date.toLocaleDateString("en-US", options);
  }
  const { id } = useParams();
  const params = { caseID: id };
  const user = useSelector((state) => state.login.data);
  const settings = useSelector((state) => state?.ChecklistDocument?.settings);
  return (
    <Card variant="outlined" className=" mt-3" sx={{ maxWidth: "100%" }}>
      <Box sx={{ p: 2 }}>
        {editArbitrationNoticesKeyDates ? (
          <Formik
            initialValues={{
              DaysUnderSection11Complied: !rowData?.caseID
                ? null
                : rowData?.["30DaysUnderSection11Complied"],
              contested: !rowData?.caseID ? null : rowData?.contested,
              noticeForFirstHearingSentOn: !rowData?.caseID
                ? null
                : rowData?.NoticeForFirstHearingSentOn,

              firstHearing: !rowData?.caseID ? null : rowData?.firstHearing,

              firstHearingNotes: !rowData?.caseID
                ? null
                : rowData?.FirstHearingNotes,

              stateOfClaimFiled: !rowData?.caseID
                ? null
                : rowData?.StateOfClaimFiled,

              claimFillingDate: !rowData?.caseID
                ? null
                : rowData?.claimFillingDate,

              respondentsStatementOfDefenceFiled: !rowData?.caseID
                ? null
                : rowData?.RespondentsStatementOfDefenceFiled,

              respondentsStatementOfDefenceFiledOn: !rowData?.caseID
                ? null
                : rowData?.RespondentsStatementOfDefenceFiledOn,

              counterclaimFiled: !rowData?.caseID
                ? null
                : rowData?.CounterclaimFiled,

              counterclaimFiledOn: !rowData?.caseID
                ? null
                : rowData?.CounterclaimFiledOn,
              amendedClaimsFiled: !rowData?.caseID
                ? null
                : rowData?.AmendedClaimsFiled,
              amendedClaimsFiledOn: !rowData?.caseID
                ? null
                : rowData?.AmendedClaimsFiledOn,
              noticeForSecondHearingSentOn: !rowData?.caseID
                ? null
                : rowData?.NoticeForSecondHearingSentOn,
              secondHearing: !rowData?.caseID ? null : rowData?.secondHearing,
              secondHearingNotes: !rowData?.caseID
                ? null
                : rowData?.SecondHearingNotes,
              noticeForThirdHearingSentOn: !rowData?.caseID
                ? null
                : rowData?.NoticeForThirdHearingSentOn,
              thirdHearing: !rowData?.caseID ? null : rowData?.thirdHearing,
              thirdHearingNotes: !rowData?.caseID
                ? null
                : rowData?.ThirdHearingNotes,
              additionalHearings: !rowData?.caseID
                ? null
                : rowData?.AdditionalHearings,
              noticeForFinalHearingSentOn: !rowData?.caseID
                ? null
                : rowData?.NoticeForFinalHearingSentOn,
              finalHearing: !rowData?.caseID ? null : rowData?.finalHearing,
              finalHearingNotes: !rowData?.caseID
                ? null
                : rowData?.FinalHearingNotes,
              appForExtensionOfArbitrationTimelineFiled: !rowData?.caseID
                ? null
                : rowData?.AppForExtensionOfArbitrationTimelineFiled,
              appForExtensionOfArbitrationTimelineFiledOn: !rowData?.caseID
                ? null
                : rowData?.AppForExtensionOfArbitrationTimelineFiledOn,
              statusOfAppOfExtensionOfArbitrationTimelineFiledOn:
                !rowData?.caseID
                  ? null
                  : rowData?.StatusOfAppOfExtensionOfArbitrationTimelineFiledOn,
              awardDate: !rowData?.caseID ? null : rowData?.awardDate,
              caseGeneralNotes: !rowData?.caseID
                ? null
                : rowData?.CaseGeneralNotes,
              params,
              user,
            }}
            // validationSchema={validationSchema}
            onSubmit={async (values, actions) => {
              try {
                dispatch(updateArbitrationNoticesKeyDatesAction(values));

                setEditArbitrationNoticesKeyDates(false);
                setTimeout(() => {
                  dispatch(singleCaseDetailsAction(params));
                }, 1000);
              } catch (error) {
                // Handle submission error if needed
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              touched,
              values,
              setFieldValue,
              resetForm,
            }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <Accordion defaultExpanded>
                    <AccordionSummary
                      onClick={(event) => {
                        event.stopPropagation();
                      }}
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          width: "100%",
                          backgroundColor: "white",
                        }}
                      >
                        <Typography
                          gutterBottom
                          variant="h5"
                          component="div"
                          className="m-0 "
                        >
                          Arbitration Notices Key / Dates
                        </Typography>

                        <div>
                          <Button
                            type="button"
                            color="primary"
                            onClick={(event) => {
                              event.stopPropagation();
                              setEditArbitrationNoticesKeyDates(false);
                              // resetForm();
                              //   setEditCaseTracker(false);
                            }}
                          >
                            Cancel
                          </Button>
                          <Button
                            color="primary"
                            onClick={async () => {
                              //   try {
                              //     dispatch(caseTrackingUpdateAction(values));
                              //     setEditCaseTracker(false);
                              //     setTimeout(() => {
                              //       // dispatch(resetSingleCaseDetailsReducer());
                              //       // dispatch(allCaseDetailsAction());
                              //       // newData(null);
                              //     }, 1000);
                              //   } catch (error) {}
                            }}
                            type="submit"
                          >
                            Submit
                          </Button>
                        </div>
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                      <>
                        <Box
                          xs={12}
                          sm={12}
                          md={12}
                          sx={{
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            backgroundColor: "white",
                            flexWrap: "wrap",
                            alignItems: "stretch",
                          }}
                        >
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Box
                              sx={{
                                display: "grid",
                                alignItems: "center",
                                px: 2,
                                gridTemplateColumns: {
                                  xs: "1fr",
                                  sm: "1fr",
                                  md: "1fr",
                                },
                                width: { xs: "100%", sm: "50%", md: "25%" },
                              }}
                              xs={12}
                              sm={6}
                              md={4}
                            >
                              <InputLabel
                                htmlFor="DaysUnderSection11Complied"
                                sx={{ textWrap: "balance" }}
                              >
                                30 Days under Section 11 Complied?
                              </InputLabel>

                              <Select
                                labelId="DaysUnderSection11Complied"
                                id="DaysUnderSection11Complied"
                                name="DaysUnderSection11Complied"
                                value={values?.DaysUnderSection11Complied}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                fullWidth
                              >
                                <MenuItem value={"Yes"} defaultValue>
                                  Yes
                                </MenuItem>
                                <MenuItem value={"No"} defaultValue>
                                  No
                                </MenuItem>
                              </Select>
                            </Box>

                            <Box
                              sx={{
                                display: "grid",
                                alignItems: "center",
                                px: 2,
                                gridTemplateColumns: {
                                  xs: "1fr",
                                  sm: "1fr",
                                  md: "1fr",
                                },
                                width: { xs: "100%", sm: "50%", md: "25%" },
                              }}
                              xs={12}
                              sm={6}
                              md={4}
                            >
                              <InputLabel
                                htmlFor="contested"
                                sx={{ textWrap: "balance" }}
                              >
                                Case Contested
                              </InputLabel>

                              <Select
                                labelId="contested"
                                id="contested"
                                name="contested"
                                value={values?.contested}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                fullWidth
                              >
                                <MenuItem value={"Yes"} defaultValue>
                                  Yes
                                </MenuItem>
                                <MenuItem value={"No"} defaultValue>
                                  No
                                </MenuItem>
                              </Select>
                            </Box>

                            <Box
                              sx={{
                                display: "grid",
                                alignItems: "center",
                                px: 2,
                                gridTemplateColumns: {
                                  xs: "1fr",
                                  sm: "1fr",
                                  md: "1fr",
                                },
                                width: { xs: "100%", sm: "50%", md: "25%" },
                              }}
                              xs={12}
                              sm={6}
                              md={4}
                            >
                              <Typography
                                variant="body1"
                                component="label"
                                sx={{ marginRight: 2, textWrap: "balance" }}
                              >
                                Notice for First Hearing Sent On
                              </Typography>

                              <DatePicker
                                id="noticeForFirstHearingSentOn"
                                selected={values?.noticeForFirstHearingSentOn}
                                onChange={(date) => {
                                  setFieldValue(
                                    "noticeForFirstHearingSentOn",
                                    date
                                  );
                                }}
                                sx={{
                                  width: "100%",
                                  gridTemplateColumns: { xs: "1fr", sm: "1fr" },
                                }}
                                onBlur={handleBlur}
                                dateFormat="dd-MM-yyyy"
                                placeholderText="DD-MM-YYYY"
                                className="form-control"
                              />
                            </Box>

                            <Box
                              sx={{
                                display: "grid",
                                alignItems: "center",
                                px: 2,
                                gridTemplateColumns: {
                                  xs: "1fr",
                                  sm: "1fr",
                                  md: "1fr",
                                },
                                width: { xs: "100%", sm: "50%", md: "25%" },
                              }}
                              xs={12}
                              sm={6}
                              md={4}
                            >
                              <Typography
                                variant="body1"
                                component="label"
                                sx={{ marginRight: 2, textWrap: "balance" }}
                              >
                                First Hearing Date
                              </Typography>

                              <DatePicker
                                id="firstHearing"
                                selected={values?.firstHearing}
                                onChange={(date) => {
                                  setFieldValue("firstHearing", date);
                                }}
                                sx={{
                                  width: "100%",
                                  gridTemplateColumns: { xs: "1fr", sm: "1fr" },
                                }}
                                onBlur={handleBlur}
                                dateFormat="dd-MM-yyyy"
                                placeholderText="DD-MM-YYYY"
                                className="form-control"
                              />
                            </Box>

                            <Box
                              sx={{
                                display: "grid",
                                alignItems: "center",
                                px: 2,
                                gridTemplateColumns: {
                                  xs: "1fr",
                                  sm: "1fr",
                                  md: "1fr",
                                },
                                width: { xs: "100%", sm: "50%", md: "25%" },
                              }}
                              xs={12}
                              sm={6}
                              md={4}
                            >
                              <Typography
                                variant="body1"
                                component="label"
                                sx={{ textWrap: "balance" }}
                              >
                                First Hearing Notes
                              </Typography>

                              <OutlinedInput
                                id="firstHearingNotes"
                                name="firstHearingNotes"
                                sx={{
                                  gridTemplateColumns: { xs: "1fr", sm: "1fr" },
                                }}
                                value={values?.firstHearingNotes}
                                onBlur={handleBlur}
                                onChange={handleChange}
                              />
                            </Box>

                            <Box
                              sx={{
                                display: "grid",
                                alignItems: "center",
                                px: 2,
                                gridTemplateColumns: {
                                  xs: "1fr",
                                  sm: "1fr",
                                  md: "1fr",
                                },
                                width: { xs: "100%", sm: "50%", md: "25%" },
                              }}
                              xs={12}
                              sm={6}
                              md={4}
                            >
                              <InputLabel
                                htmlFor="stateOfClaimFiled"
                                sx={{ textWrap: "balance" }}
                              >
                                State of Claim Flied
                              </InputLabel>

                              <Select
                                labelId="stateOfClaimFiled"
                                id="stateOfClaimFiled"
                                name="stateOfClaimFiled"
                                value={values?.stateOfClaimFiled}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                fullWidth
                              >
                                <MenuItem value={"Yes"} defaultValue>
                                  Yes
                                </MenuItem>
                                <MenuItem value={"No"} defaultValue>
                                  No
                                </MenuItem>
                              </Select>
                            </Box>

                            {values?.stateOfClaimFiled == "Yes" && (
                              <Box
                                sx={{
                                  display: "grid",
                                  alignItems: "center",
                                  px: 2,
                                  gridTemplateColumns: {
                                    xs: "1fr",
                                    sm: "1fr",
                                    md: "1fr",
                                  },
                                  width: { xs: "100%", sm: "50%", md: "25%" },
                                }}
                                xs={12}
                                sm={6}
                                md={4}
                              >
                                <Typography
                                  variant="body1"
                                  component="label"
                                  sx={{ marginRight: 2, textWrap: "balance" }}
                                >
                                  Statement of Claim Filed On
                                </Typography>

                                <DatePicker
                                  id="claimFillingDate"
                                  selected={values?.claimFillingDate}
                                  onChange={(date) => {
                                    setFieldValue("claimFillingDate", date);
                                  }}
                                  sx={{
                                    width: "100%",
                                    gridTemplateColumns: {
                                      xs: "1fr",
                                      sm: "1fr",
                                    },
                                  }}
                                  onBlur={handleBlur}
                                  dateFormat="dd-MM-yyyy"
                                  placeholderText="DD-MM-YYYY"
                                  className="form-control"
                                />
                              </Box>
                            )}

                            <Box
                              sx={{
                                display: "grid",
                                alignItems: "center",
                                px: 2,
                                gridTemplateColumns: {
                                  xs: "1fr",
                                  sm: "1fr",
                                  md: "1fr",
                                },
                                width: { xs: "100%", sm: "50%", md: "25%" },
                              }}
                              xs={12}
                              sm={6}
                              md={4}
                            >
                              <InputLabel
                                htmlFor="respondentsStatementOfDefenceFiled"
                                sx={{ textWrap: "balance" }}
                              >
                                Respondent's Statement of Defence Filed?
                              </InputLabel>

                              <Select
                                labelId="respondentsStatementOfDefenceFiled"
                                id="respondentsStatementOfDefenceFiled"
                                name="respondentsStatementOfDefenceFiled"
                                value={
                                  values?.respondentsStatementOfDefenceFiled
                                }
                                onBlur={handleBlur}
                                onChange={handleChange}
                                fullWidth
                              >
                                <MenuItem value={"Yes"} defaultValue>
                                  Yes
                                </MenuItem>
                                <MenuItem value={"No"} defaultValue>
                                  No
                                </MenuItem>
                              </Select>
                            </Box>

                            {values?.respondentsStatementOfDefenceFiled ==
                              "Yes" && (
                              <Box
                                sx={{
                                  display: "grid",
                                  alignItems: "center",
                                  px: 2,
                                  gridTemplateColumns: {
                                    xs: "1fr",
                                    sm: "1fr",
                                    md: "1fr",
                                  },
                                  width: { xs: "100%", sm: "50%", md: "25%" },
                                }}
                                xs={12}
                                sm={6}
                                md={4}
                              >
                                <Typography
                                  variant="body1"
                                  component="label"
                                  sx={{ marginRight: 2, textWrap: "balance" }}
                                >
                                  Respondent's Statement of Defence Filed On
                                </Typography>

                                <DatePicker
                                  id="respondentsStatementOfDefenceFiledOn"
                                  selected={
                                    values?.respondentsStatementOfDefenceFiledOn
                                  }
                                  onChange={(date) => {
                                    setFieldValue(
                                      "respondentsStatementOfDefenceFiledOn",
                                      date
                                    );
                                  }}
                                  sx={{
                                    width: "100%",
                                    gridTemplateColumns: {
                                      xs: "1fr",
                                      sm: "1fr",
                                    },
                                  }}
                                  onBlur={handleBlur}
                                  dateFormat="dd-MM-yyyy"
                                  placeholderText="DD-MM-YYYY"
                                  className="form-control"
                                />
                              </Box>
                            )}

                            <Box
                              sx={{
                                display: "grid",
                                alignItems: "center",
                                px: 2,
                                gridTemplateColumns: {
                                  xs: "1fr",
                                  sm: "1fr",
                                  md: "1fr",
                                },
                                width: { xs: "100%", sm: "50%", md: "25%" },
                              }}
                              xs={12}
                              sm={6}
                              md={4}
                            >
                              <InputLabel
                                htmlFor="counterclaimFiled"
                                sx={{ textWrap: "balance" }}
                              >
                                Counterclaim Filed
                              </InputLabel>

                              <Select
                                labelId="counterclaimFiled"
                                id="counterclaimFiled"
                                name="counterclaimFiled"
                                value={values?.counterclaimFiled}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                fullWidth
                              >
                                <MenuItem value={"Yes"} defaultValue>
                                  Yes
                                </MenuItem>
                                <MenuItem value={"No"} defaultValue>
                                  No
                                </MenuItem>
                              </Select>
                            </Box>

                            {values?.counterclaimFiled == "Yes" && (
                              <Box
                                sx={{
                                  display: "grid",
                                  alignItems: "center",
                                  px: 2,
                                  gridTemplateColumns: {
                                    xs: "1fr",
                                    sm: "1fr",
                                    md: "1fr",
                                  },
                                  width: { xs: "100%", sm: "50%", md: "25%" },
                                }}
                                xs={12}
                                sm={6}
                                md={4}
                              >
                                <Typography
                                  variant="body1"
                                  component="label"
                                  sx={{ marginRight: 2, textWrap: "balance" }}
                                >
                                  Counterclaim Filed On
                                </Typography>

                                <DatePicker
                                  id="counterclaimFiledOn"
                                  selected={values?.counterclaimFiledOn}
                                  onChange={(date) => {
                                    setFieldValue("counterclaimFiledOn", date);
                                  }}
                                  sx={{
                                    width: "100%",
                                    gridTemplateColumns: {
                                      xs: "1fr",
                                      sm: "1fr",
                                    },
                                  }}
                                  onBlur={handleBlur}
                                  dateFormat="dd-MM-yyyy"
                                  placeholderText="DD-MM-YYYY"
                                  className="form-control"
                                />
                              </Box>
                            )}

                            <Box
                              sx={{
                                display: "grid",
                                alignItems: "center",
                                px: 2,
                                gridTemplateColumns: {
                                  xs: "1fr",
                                  sm: "1fr",
                                  md: "1fr",
                                },
                                width: { xs: "100%", sm: "50%", md: "25%" },
                              }}
                              xs={12}
                              sm={6}
                              md={4}
                            >
                              <InputLabel
                                htmlFor="amendedClaimsFiled"
                                sx={{ textWrap: "balance" }}
                              >
                                Amended Claims Filed?
                              </InputLabel>

                              <Select
                                labelId="amendedClaimsFiled"
                                id="amendedClaimsFiled"
                                name="amendedClaimsFiled"
                                value={values?.amendedClaimsFiled}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                fullWidth
                              >
                                <MenuItem value={"Yes"} defaultValue>
                                  Yes
                                </MenuItem>
                                <MenuItem value={"No"} defaultValue>
                                  No
                                </MenuItem>
                              </Select>
                            </Box>

                            {values?.amendedClaimsFiled == "Yes" && (
                              <Box
                                sx={{
                                  display: "grid",
                                  alignItems: "center",
                                  px: 2,
                                  gridTemplateColumns: {
                                    xs: "1fr",
                                    sm: "1fr",
                                    md: "1fr",
                                  },
                                  width: { xs: "100%", sm: "50%", md: "25%" },
                                }}
                                xs={12}
                                sm={6}
                                md={4}
                              >
                                <Typography
                                  variant="body1"
                                  component="label"
                                  sx={{ marginRight: 2, textWrap: "balance" }}
                                >
                                  Amended Claims Filed On
                                </Typography>

                                <DatePicker
                                  id="amendedClaimsFiledOn"
                                  selected={values?.amendedClaimsFiledOn}
                                  onChange={(date) => {
                                    setFieldValue("amendedClaimsFiledOn", date);
                                  }}
                                  sx={{
                                    width: "100%",
                                    gridTemplateColumns: {
                                      xs: "1fr",
                                      sm: "1fr",
                                    },
                                  }}
                                  onBlur={handleBlur}
                                  dateFormat="dd-MM-yyyy"
                                  placeholderText="DD-MM-YYYY"
                                  className="form-control"
                                />
                              </Box>
                            )}

                            <Box
                              sx={{
                                display: "grid",
                                alignItems: "center",
                                px: 2,
                                gridTemplateColumns: {
                                  xs: "1fr",
                                  sm: "1fr",
                                  md: "1fr",
                                },
                                width: { xs: "100%", sm: "50%", md: "25%" },
                              }}
                              xs={12}
                              sm={6}
                              md={4}
                            >
                              <Typography
                                variant="body1"
                                component="label"
                                sx={{ marginRight: 2, textWrap: "balance" }}
                              >
                                Notice for Second Hearing Sent On
                              </Typography>

                              <DatePicker
                                id="noticeForSecondHearingSentOn"
                                selected={values?.noticeForSecondHearingSentOn}
                                onChange={(date) => {
                                  setFieldValue(
                                    "noticeForSecondHearingSentOn",
                                    date
                                  );
                                }}
                                sx={{
                                  width: "100%",
                                  gridTemplateColumns: { xs: "1fr", sm: "1fr" },
                                }}
                                onBlur={handleBlur}
                                dateFormat="dd-MM-yyyy"
                                placeholderText="DD-MM-YYYY"
                                className="form-control"
                              />
                            </Box>

                            <Box
                              sx={{
                                display: "grid",
                                alignItems: "center",
                                px: 2,
                                gridTemplateColumns: {
                                  xs: "1fr",
                                  sm: "1fr",
                                  md: "1fr",
                                },
                                width: { xs: "100%", sm: "50%", md: "25%" },
                              }}
                              xs={12}
                              sm={6}
                              md={4}
                            >
                              <Typography
                                variant="body1"
                                component="label"
                                sx={{ marginRight: 2, textWrap: "balance" }}
                              >
                                Second Hearing Date
                              </Typography>

                              <DatePicker
                                id="secondHearing"
                                selected={values?.secondHearing}
                                onChange={(date) => {
                                  setFieldValue("secondHearing", date);
                                }}
                                sx={{
                                  width: "100%",
                                  gridTemplateColumns: { xs: "1fr", sm: "1fr" },
                                }}
                                onBlur={handleBlur}
                                dateFormat="dd-MM-yyyy"
                                placeholderText="DD-MM-YYYY"
                                className="form-control"
                              />
                            </Box>

                            <Box
                              sx={{
                                display: "grid",
                                alignItems: "center",
                                px: 2,
                                gridTemplateColumns: {
                                  xs: "1fr",
                                  sm: "1fr",
                                  md: "1fr",
                                },
                                width: { xs: "100%", sm: "50%", md: "25%" },
                              }}
                              xs={12}
                              sm={6}
                              md={4}
                            >
                              <Typography
                                variant="body1"
                                component="label"
                                sx={{ textWrap: "balance" }}
                              >
                                Second Hearing Notes
                              </Typography>

                              <OutlinedInput
                                id="secondHearingNotes"
                                name="secondHearingNotes"
                                sx={{
                                  gridTemplateColumns: { xs: "1fr", sm: "1fr" },
                                }}
                                value={values?.secondHearingNotes}
                                onBlur={handleBlur}
                                onChange={handleChange}
                              />
                            </Box>

                            <Box
                              sx={{
                                display: "grid",
                                alignItems: "center",
                                px: 2,
                                gridTemplateColumns: {
                                  xs: "1fr",
                                  sm: "1fr",
                                  md: "1fr",
                                },
                                width: { xs: "100%", sm: "50%", md: "25%" },
                              }}
                              xs={12}
                              sm={6}
                              md={4}
                            >
                              <Typography
                                variant="body1"
                                component="label"
                                sx={{ marginRight: 2, textWrap: "balance" }}
                              >
                                Notice for Third Hearing Sent On
                              </Typography>

                              <DatePicker
                                id="noticeForThirdHearingSentOn"
                                selected={values?.noticeForThirdHearingSentOn}
                                onChange={(date) => {
                                  setFieldValue(
                                    "noticeForThirdHearingSentOn",
                                    date
                                  );
                                }}
                                sx={{
                                  width: "100%",
                                  gridTemplateColumns: { xs: "1fr", sm: "1fr" },
                                }}
                                onBlur={handleBlur}
                                dateFormat="dd-MM-yyyy"
                                placeholderText="DD-MM-YYYY"
                                className="form-control"
                              />
                            </Box>

                            <Box
                              sx={{
                                display: "grid",
                                alignItems: "center",
                                px: 2,
                                gridTemplateColumns: {
                                  xs: "1fr",
                                  sm: "1fr",
                                  md: "1fr",
                                },
                                width: { xs: "100%", sm: "50%", md: "25%" },
                              }}
                              xs={12}
                              sm={6}
                              md={4}
                            >
                              <Typography
                                variant="body1"
                                component="label"
                                sx={{ marginRight: 2, textWrap: "balance" }}
                              >
                                Third Hearing Date
                              </Typography>

                              <DatePicker
                                id="thirdHearing"
                                selected={values?.thirdHearing}
                                onChange={(date) => {
                                  setFieldValue("thirdHearing", date);
                                }}
                                sx={{
                                  width: "100%",
                                  gridTemplateColumns: { xs: "1fr", sm: "1fr" },
                                }}
                                onBlur={handleBlur}
                                dateFormat="dd-MM-yyyy"
                                placeholderText="DD-MM-YYYY"
                                className="form-control"
                              />
                            </Box>

                            <Box
                              sx={{
                                display: "grid",
                                alignItems: "center",
                                px: 2,
                                gridTemplateColumns: {
                                  xs: "1fr",
                                  sm: "1fr",
                                  md: "1fr",
                                },
                                width: { xs: "100%", sm: "50%", md: "25%" },
                              }}
                              xs={12}
                              sm={6}
                              md={4}
                            >
                              <Typography
                                variant="body1"
                                component="label"
                                sx={{ textWrap: "balance" }}
                              >
                                Third Hearing Notes
                              </Typography>

                              <OutlinedInput
                                id="thirdHearingNotes"
                                name="thirdHearingNotes"
                                sx={{
                                  gridTemplateColumns: { xs: "1fr", sm: "1fr" },
                                }}
                                value={values?.thirdHearingNotes}
                                onBlur={handleBlur}
                                onChange={handleChange}
                              />
                            </Box>

                            <Box
                              sx={{
                                display: "grid",
                                alignItems: "center",
                                px: 2,
                                gridTemplateColumns: {
                                  xs: "1fr",
                                  sm: "1fr",
                                  md: "1fr",
                                },
                                width: { xs: "100%", sm: "50%", md: "25%" },
                              }}
                              xs={12}
                              sm={6}
                              md={4}
                            >
                              <Typography
                                variant="body1"
                                component="label"
                                sx={{ textWrap: "balance" }}
                              >
                                Additional Hearings
                              </Typography>

                              <OutlinedInput
                                id="additionalHearings"
                                name="additionalHearings"
                                sx={{
                                  gridTemplateColumns: { xs: "1fr", sm: "1fr" },
                                }}
                                value={values?.additionalHearings}
                                onBlur={handleBlur}
                                onChange={handleChange}
                              />
                            </Box>

                            <Box
                              sx={{
                                display: "grid",
                                alignItems: "center",
                                px: 2,
                                gridTemplateColumns: {
                                  xs: "1fr",
                                  sm: "1fr",
                                  md: "1fr",
                                },
                                width: { xs: "100%", sm: "50%", md: "25%" },
                              }}
                              xs={12}
                              sm={6}
                              md={4}
                            >
                              <Typography
                                variant="body1"
                                component="label"
                                sx={{ marginRight: 2, textWrap: "balance" }}
                              >
                                Notice for Final Hearing Sent On
                              </Typography>

                              <DatePicker
                                id="noticeForFinalHearingSentOn"
                                selected={values?.noticeForFinalHearingSentOn}
                                onChange={(date) => {
                                  setFieldValue(
                                    "noticeForFinalHearingSentOn",
                                    date
                                  );
                                }}
                                sx={{
                                  width: "100%",
                                  gridTemplateColumns: { xs: "1fr", sm: "1fr" },
                                }}
                                onBlur={handleBlur}
                                dateFormat="dd-MM-yyyy"
                                placeholderText="DD-MM-YYYY"
                                className="form-control"
                              />
                            </Box>

                            <Box
                              sx={{
                                display: "grid",
                                alignItems: "center",
                                px: 2,
                                gridTemplateColumns: {
                                  xs: "1fr",
                                  sm: "1fr",
                                  md: "1fr",
                                },
                                width: { xs: "100%", sm: "50%", md: "25%" },
                              }}
                              xs={12}
                              sm={6}
                              md={4}
                            >
                              <Typography
                                variant="body1"
                                component="label"
                                sx={{ marginRight: 2, textWrap: "balance" }}
                              >
                                Final Hearing Date
                              </Typography>

                              <DatePicker
                                id="finalHearing"
                                selected={values?.finalHearing}
                                onChange={(date) => {
                                  setFieldValue("finalHearing", date);
                                }}
                                sx={{
                                  width: "100%",
                                  gridTemplateColumns: { xs: "1fr", sm: "1fr" },
                                }}
                                onBlur={handleBlur}
                                dateFormat="dd-MM-yyyy"
                                placeholderText="DD-MM-YYYY"
                                className="form-control"
                              />
                            </Box>

                            <Box
                              sx={{
                                display: "grid",
                                alignItems: "center",
                                px: 2,
                                gridTemplateColumns: {
                                  xs: "1fr",
                                  sm: "1fr",
                                  md: "1fr",
                                },
                                width: { xs: "100%", sm: "50%", md: "25%" },
                              }}
                              xs={12}
                              sm={6}
                              md={4}
                            >
                              <Typography
                                variant="body1"
                                component="label"
                                sx={{ textWrap: "balance" }}
                              >
                                Final Hearing Notes
                              </Typography>

                              <OutlinedInput
                                id="finalHearingNotes"
                                name="finalHearingNotes"
                                sx={{
                                  gridTemplateColumns: { xs: "1fr", sm: "1fr" },
                                }}
                                value={values?.finalHearingNotes}
                                onBlur={handleBlur}
                                onChange={handleChange}
                              />
                            </Box>

                            <Box
                              sx={{
                                display: "grid",
                                alignItems: "center",
                                px: 2,
                                gridTemplateColumns: {
                                  xs: "1fr",
                                  sm: "1fr",
                                  md: "1fr",
                                },
                                width: { xs: "100%", sm: "50%", md: "25%" },
                              }}
                              xs={12}
                              sm={6}
                              md={4}
                            >
                              <InputLabel
                                htmlFor="appForExtensionOfArbitrationTimelineFiled"
                                sx={{ textWrap: "balance" }}
                              >
                                Application for Extension of Arbitration
                                Timeline Filed
                              </InputLabel>

                              <Select
                                labelId="appForExtensionOfArbitrationTimelineFiled"
                                id="appForExtensionOfArbitrationTimelineFiled"
                                name="appForExtensionOfArbitrationTimelineFiled"
                                value={
                                  values?.appForExtensionOfArbitrationTimelineFiled
                                }
                                onBlur={handleBlur}
                                onChange={handleChange}
                                fullWidth
                              >
                                <MenuItem value={"Yes"} defaultValue>
                                  Yes
                                </MenuItem>
                                <MenuItem value={"No"} defaultValue>
                                  No
                                </MenuItem>
                              </Select>
                            </Box>

                            {values?.appForExtensionOfArbitrationTimelineFiled ==
                              "Yes" && (
                              <Box
                                sx={{
                                  display: "grid",
                                  alignItems: "center",
                                  px: 2,
                                  gridTemplateColumns: {
                                    xs: "1fr",
                                    sm: "1fr",
                                    md: "1fr",
                                  },
                                  width: { xs: "100%", sm: "50%", md: "25%" },
                                }}
                                xs={12}
                                sm={6}
                                md={4}
                              >
                                <Typography
                                  variant="body1"
                                  component="label"
                                  sx={{ marginRight: 2, textWrap: "balance" }}
                                >
                                  Application for Extension of Arbitration
                                  Timeline Filed On
                                </Typography>

                                <DatePicker
                                  id="appForExtensionOfArbitrationTimelineFiledOn"
                                  selected={
                                    values?.appForExtensionOfArbitrationTimelineFiledOn
                                  }
                                  onChange={(date) => {
                                    setFieldValue(
                                      "appForExtensionOfArbitrationTimelineFiledOn",
                                      date
                                    );
                                  }}
                                  sx={{
                                    width: "100%",
                                    gridTemplateColumns: {
                                      xs: "1fr",
                                      sm: "1fr",
                                    },
                                  }}
                                  onBlur={handleBlur}
                                  dateFormat="dd-MM-yyyy"
                                  placeholderText="DD-MM-YYYY"
                                  className="form-control"
                                />
                              </Box>
                            )}

                            {values?.appForExtensionOfArbitrationTimelineFiled ==
                              "Yes" && (
                              <Box
                                sx={{
                                  display: "grid",
                                  alignItems: "center",
                                  px: 2,
                                  gridTemplateColumns: {
                                    xs: "1fr",
                                    sm: "1fr",
                                    md: "1fr",
                                  },
                                  width: { xs: "100%", sm: "50%", md: "25%" },
                                }}
                                xs={12}
                                sm={6}
                                md={4}
                              >
                                <InputLabel
                                  htmlFor="statusOfAppOfExtensionOfArbitrationTimelineFiledOn"
                                  sx={{ textWrap: "balance" }}
                                >
                                  Status of Application of Extension of
                                  Arbitration Timeline Filed On
                                </InputLabel>

                                <Select
                                  labelId="statusOfAppOfExtensionOfArbitrationTimelineFiledOn"
                                  id="statusOfAppOfExtensionOfArbitrationTimelineFiledOn"
                                  name="statusOfAppOfExtensionOfArbitrationTimelineFiledOn"
                                  value={
                                    values?.statusOfAppOfExtensionOfArbitrationTimelineFiledOn
                                  }
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  fullWidth
                                >
                                  {settings
                                    ?.find(
                                      (item) =>
                                        item.categoryName ==
                                        "Status of Application"
                                    )
                                    ?.Children?.map((data, index) => {
                                      return (
                                        <MenuItem
                                          key={index}
                                          value={data?.generalSettingsID}
                                        >
                                          {data?.categoryName}
                                        </MenuItem>
                                      );
                                    })}
                                </Select>
                              </Box>
                            )}

                            <Box
                              sx={{
                                display: "grid",
                                alignItems: "center",
                                px: 2,
                                gridTemplateColumns: {
                                  xs: "1fr",
                                  sm: "1fr",
                                  md: "1fr",
                                },
                                width: { xs: "100%", sm: "50%", md: "25%" },
                              }}
                              xs={12}
                              sm={6}
                              md={4}
                            >
                              <Typography
                                variant="body1"
                                component="label"
                                sx={{ marginRight: 2, textWrap: "balance" }}
                              >
                                Final Award Passed On
                              </Typography>

                              <DatePicker
                                id="awardDate"
                                selected={values?.awardDate}
                                onChange={(date) => {
                                  setFieldValue("awardDate", date);
                                }}
                                sx={{
                                  width: "100%",
                                  gridTemplateColumns: { xs: "1fr", sm: "1fr" },
                                }}
                                onBlur={handleBlur}
                                dateFormat="dd-MM-yyyy"
                                placeholderText="DD-MM-YYYY"
                                className="form-control"
                              />
                            </Box>

                            <Box width="100%">
                              <Box
                                sx={{
                                  display: "grid",
                                  alignItems: "center",
                                  px: 2,
                                  gridTemplateColumns: {
                                    xs: "1fr",
                                    sm: "1fr",
                                    md: "1fr",
                                  },
                                  width: { xs: "100%", sm: "100%", md: "100%" },
                                }}
                                xs={12}
                                sm={12}
                                md={12}
                              >
                                <Box>
                                  <span>Case General Notes</span>
                                </Box>
                                <Box>
                                  <TextareaAutosize
                                    className={classes.textarea}
                                    // rowsMin={5}
                                    name="caseGeneralNotes"
                                    aria-label="maximum height"
                                    value={values?.caseGeneralNotes}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                  />
                                </Box>
                              </Box>
                            </Box>
                          </LocalizationProvider>
                        </Box>
                      </>
                    </AccordionDetails>
                  </Accordion>
                </form>
              );
            }}
          </Formik>
        ) : (
          //   <CaseTracking
          //     classes={classes}
          //     rowData={caseDetailsData}
          //     settings={settings}
          //     handleFormSubmit={handleFormSubmit}
          //     dispatch={dispatch}
          //     setEditCaseTracker={setEditCaseTracker}
          //     newData={newData}
          //     initialValues={initialValues}
          //     validationSchema={validationSchema}
          //   />
          <>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                    backgroundColor: "white",
                  }}
                >
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    className="m-0 "
                  >
                    Arbitration Notices Key / Dates
                  </Typography>

                  <div>
                    <Button
                      type="button"
                      color="primary"
                      onClick={(event) => {
                        event.stopPropagation();
                        if (window.edit) {
                          event.stopPropagation();
                          setEditArbitrationNoticesKeyDates(true);
                        } else {
                          animateCss(
                            "info",
                            "You are not authorized to update"
                          );
                        }
                      }}
                    >
                      Edit
                    </Button>
                  </div>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={0}>
                  <Grid item xs={12} sm={6} md={4}>
                    <List sx={{ bgcolor: "background.paper" }}>
                      <ListItemText
                        primary=" 30 Days under Section 11 Complied?"
                        secondary={rowData?.["30DaysUnderSection11Complied"]}
                      />
                    </List>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <List sx={{ bgcolor: "background.paper" }}>
                      <ListItemText
                        primary="Case Contested"
                        secondary={rowData?.contested}
                      />
                    </List>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <List sx={{ bgcolor: "background.paper" }}>
                      <ListItemText
                        primary=" Notice for First Hearing Sent On"
                        secondary={
                          rowData?.NoticeForFirstHearingSentOn
                            ? trackerFormatDate(
                                new Date(rowData?.NoticeForFirstHearingSentOn)
                              )
                            : "DD-MM-YYYY"
                        }
                      />
                    </List>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <List sx={{ bgcolor: "background.paper" }}>
                      <ListItemText
                        primary="First Hearing Date"
                        secondary={
                          rowData?.firstHearing
                            ? trackerFormatDate(new Date(rowData?.firstHearing))
                            : "DD-MM-YYYY"
                        }
                      />
                    </List>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <List sx={{ bgcolor: "background.paper" }}>
                      <ListItemText
                        primary="First Hearing Notes"
                        secondary={rowData?.FirstHearingNotes}
                      />
                    </List>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <List sx={{ bgcolor: "background.paper" }}>
                      <ListItemText
                        primary="Statement of Claim Filed"
                        secondary={rowData?.StateOfClaimFiled}
                      />
                    </List>
                  </Grid>

                  {rowData?.stateOfClaimFiled == "Yes" && (
                    <Grid item xs={12} sm={6} md={4}>
                      <List sx={{ bgcolor: "background.paper" }}>
                        <ListItemText
                          primary="Statement of Claim Filed On"
                          secondary={
                            rowData?.claimFillingDate
                              ? trackerFormatDate(
                                  new Date(rowData?.claimFillingDate)
                                )
                              : "DD-MM-YYYY"
                          }
                        />
                      </List>
                    </Grid>
                  )}

                  <Grid item xs={12} sm={6} md={4}>
                    <List sx={{ bgcolor: "background.paper" }}>
                      <ListItemText
                        primary="Respondent's Statement of Defence Filed?"
                        secondary={rowData?.RespondentsStatementOfDefenceFiled}
                      />
                    </List>
                  </Grid>

                  {rowData?.RespondentsStatementOfDefenceFiled == "Yes" && (
                    <Grid item xs={12} sm={6} md={4}>
                      <List sx={{ bgcolor: "background.paper" }}>
                        <ListItemText
                          primary="Respondent's Statement of Defence Filed On"
                          secondary={
                            rowData?.RespondentsStatementOfDefenceFiledOn
                              ? trackerFormatDate(
                                  new Date(
                                    rowData?.RespondentsStatementOfDefenceFiledOn
                                  )
                                )
                              : "DD-MM-YYYY"
                          }
                        />
                      </List>
                    </Grid>
                  )}

                  <Grid item xs={12} sm={6} md={4}>
                    <List sx={{ bgcolor: "background.paper" }}>
                      <ListItemText
                        primary="Counterclaim Filed"
                        secondary={rowData?.CounterclaimFiled}
                      />
                    </List>
                  </Grid>

                  {rowData?.CounterclaimFiled == "Yes" && (
                    <Grid item xs={12} sm={6} md={4}>
                      <List sx={{ bgcolor: "background.paper" }}>
                        <ListItemText
                          primary="Counterclaim Filed On"
                          secondary={
                            rowData?.CounterclaimFiledOn
                              ? trackerFormatDate(
                                  new Date(rowData?.CounterclaimFiledOn)
                                )
                              : "DD-MM-YYYY"
                          }
                        />
                      </List>
                    </Grid>
                  )}

                  <Grid item xs={12} sm={6} md={4}>
                    <List sx={{ bgcolor: "background.paper" }}>
                      <ListItemText
                        primary="Amended Claims Filed?"
                        secondary={rowData?.AmendedClaimsFiled}
                      />
                    </List>
                  </Grid>

                  {rowData?.AmendedClaimsFiled == "Yes" && (
                    <Grid item xs={12} sm={6} md={4}>
                      <List sx={{ bgcolor: "background.paper" }}>
                        <ListItemText
                          primary="Amended Claims Filed On"
                          secondary={
                            rowData?.AmendedClaimsFiledOn
                              ? trackerFormatDate(
                                  new Date(rowData?.AmendedClaimsFiledOn)
                                )
                              : "DD-MM-YYYY"
                          }
                        />
                      </List>
                    </Grid>
                  )}

                  <Grid item xs={12} sm={6} md={4}>
                    <List sx={{ bgcolor: "background.paper" }}>
                      <ListItemText
                        primary="Notice for Second Hearing Sent On"
                        secondary={
                          rowData?.NoticeForSecondHearingSentOn
                            ? trackerFormatDate(
                                new Date(rowData?.NoticeForSecondHearingSentOn)
                              )
                            : "DD-MM-YYYY"
                        }
                      />
                    </List>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <List sx={{ bgcolor: "background.paper" }}>
                      <ListItemText
                        primary="Second Hearing Date"
                        secondary={
                          rowData?.secondHearing
                            ? trackerFormatDate(
                                new Date(rowData?.secondHearing)
                              )
                            : "DD-MM-YYYY"
                        }
                      />
                    </List>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <List sx={{ bgcolor: "background.paper" }}>
                      <ListItemText
                        primary="Second Hearing Notes"
                        secondary={rowData?.SecondHearingNotes}
                      />
                    </List>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <List sx={{ bgcolor: "background.paper" }}>
                      <ListItemText
                        primary="Notice for Third Hearing Sent On"
                        secondary={
                          rowData?.NoticeForThirdHearingSentOn
                            ? trackerFormatDate(
                                new Date(rowData?.NoticeForThirdHearingSentOn)
                              )
                            : "DD-MM-YYYY"
                        }
                      />
                    </List>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <List sx={{ bgcolor: "background.paper" }}>
                      <ListItemText
                        primary="Third Hearing Date"
                        secondary={
                          rowData?.thirdHearing
                            ? trackerFormatDate(new Date(rowData?.thirdHearing))
                            : "DD-MM-YYYY"
                        }
                      />
                    </List>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <List sx={{ bgcolor: "background.paper" }}>
                      <ListItemText
                        primary="Third Hearing Notes"
                        secondary={rowData?.ThirdHearingNotes}
                      />
                    </List>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <List sx={{ bgcolor: "background.paper" }}>
                      <ListItemText
                        primary="Additional Hearings"
                        secondary={rowData?.AdditionalHearings}
                      />
                    </List>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <List sx={{ bgcolor: "background.paper" }}>
                      <ListItemText
                        primary="Notice for Final Hearing Sent On"
                        secondary={
                          rowData?.NoticeForFinalHearingSentOn
                            ? trackerFormatDate(
                                new Date(rowData?.NoticeForFinalHearingSentOn)
                              )
                            : "DD-MM-YYYY"
                        }
                      />
                    </List>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <List sx={{ bgcolor: "background.paper" }}>
                      <ListItemText
                        primary="Final Hearing Date"
                        secondary={
                          rowData?.finalHearing
                            ? trackerFormatDate(new Date(rowData?.finalHearing))
                            : "DD-MM-YYYY"
                        }
                      />
                    </List>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <List sx={{ bgcolor: "background.paper" }}>
                      <ListItemText
                        primary="Final Hearing Notes"
                        secondary={rowData?.FinalHearingNotes}
                      />
                    </List>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <List sx={{ bgcolor: "background.paper" }}>
                      <ListItemText
                        primary="Application for Extension of Arbitration Timeline Filed"
                        secondary={
                          rowData?.AppForExtensionOfArbitrationTimelineFiled
                        }
                      />
                    </List>
                  </Grid>

                  {rowData?.AppForExtensionOfArbitrationTimelineFiled ==
                    "Yes" && (
                    <Grid item xs={12} sm={6} md={4}>
                      <List sx={{ bgcolor: "background.paper" }}>
                        <ListItemText
                          primary="Application for Extension of Arbitration Timeline Filed On"
                          secondary={
                            rowData?.AppForExtensionOfArbitrationTimelineFiledOn
                              ? trackerFormatDate(
                                  new Date(
                                    rowData?.AppForExtensionOfArbitrationTimelineFiledOn
                                  )
                                )
                              : "DD-MM-YYYY"
                          }
                        />
                      </List>
                    </Grid>
                  )}

                  {rowData?.AppForExtensionOfArbitrationTimelineFiled ==
                    "Yes" && (
                    <Grid item xs={12} sm={6} md={4}>
                      <List sx={{ bgcolor: "background.paper" }}>
                        <ListItemText
                          primary="Status of Application of Extension of Arbitration Timeline Filed On"
                          secondary={
                            rowData
                              ?.statusOfAppOfExtensionOfArbitrationTimelineFiledOn
                              ?.categoryName
                          }
                        />
                      </List>
                    </Grid>
                  )}

                  <Grid item xs={12} sm={6} md={4}>
                    <List sx={{ bgcolor: "background.paper" }}>
                      <ListItemText
                        primary="Final Award Passed On"
                        secondary={
                          rowData?.awardDate
                            ? trackerFormatDate(new Date(rowData?.awardDate))
                            : "DD-MM-YYYY"
                        }
                      />
                    </List>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <List sx={{ bgcolor: "background.paper" }}>
                      <ListItemText
                        primary="Case General Notes"
                        secondary={rowData?.CaseGeneralNotes}
                      />
                    </List>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </>
        )}
      </Box>
    </Card>
  );
};

export default ArbitrationNoticesKeyDates;
